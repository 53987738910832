.morecontent span {
  display: none;
}
.morelink {
  display: block;
  text-decoration: none;
  color: #9d640e;
  font-weight: 500;
  margin-top: 20px;
}
.morelink:hover{
  color: #9d640e;
  text-decoration:underline;
}
