
.abt-ban85 {
  position: relative;
  min-height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
}
.abt-ban85 .abt-ban86 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cone-sec127 .cone-sec127-2 .cone-sec127-3 img{
  width:32px;
}
.cone-sec127 .cone-sec127-10{
  height: 100%;
}
.cone-sec127 .cone-sec127-10 .cone-sec127-2{
  border-left: 1px #ddd solid;
  border-right: 1px #ddd solid;
  height: 100%;
}
.cone-sec127 .cone-sec127-2{
  display: flex;
  align-items:center;
  height: 100%;
  padding: 25px 50px;
}
.cone-sec127 .cone-sec127-2 .cone-sec127-3{
  margin-right:15px;
}
.cone-sec127 .cone-sec127-2 .cone-sec127-6  .cone-sec127-10 .cone-sec127-8{
  display: inline-block;
}
.cone-sec127 .cone-sec127-10 .cone-sec127-2 .cone-sec127-4 .cone-sec127-6 .cone-sec127-7:hover,
.cone-sec127 .cone-sec127-2 .cone-sec127-6 .cone-sec127-10 .cone-sec127-8:hover,
.cone-sec127 .cone-sec127-2 .cone-sec127-6 .cone-sec127-10 .cone-sec127-9:hover{
  color: #484848;
}


.cone-sec128 .cone-sec128-2 .cone-sec128-3 .cone-sec128-4 input{
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #707070;
  padding: 10px 0 10px 0;
  color: #C06700;
  border-radius: 0;
}
.cone-sec128 .cone-sec128-2 .cone-sec128-3 .cone-sec128-4 input:focus{
  outline:0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #C06700;
  color: #C06700;
  box-shadow: none;
}
.cone-sec128 .cone-sec128-2 .cone-sec128-3 .cone-sec128-4 input::placeholder{
  color: #212121;
}

.cone-sec128 .cone-sec128-2 .cone-sec128-3 .cone-sec128-5 textarea{
  background-color: #f5e7d7;
  border: 2px solid #f5e7d7;
  padding: 10px;
  color: #212121;
  min-height: 80px;
}
.cone-sec128 .cone-sec128-2 .cone-sec128-3 .cone-sec128-5 textarea:focus{
  background-color: #fff;
  border: 2px solid #484848;
  color: #484848;
  font-weight: 600;
  box-shadow: none;
}
.cone-sec128 .cone-sec128-2 .cone-sec128-3 .cone-sec128-5 textarea::placeholder{
  color: #212121;
}
.cone-sec128 .cone-sec128-2 .cone-sec128-3 .cone-sec128-6 button{
  padding: 12px 20px;
  background: #C06700;
  border: 1px #C06700 solid;
  border-radius: 4px;
  color: #fff;
}
.cone-sec128 .cone-sec128-2 .cone-sec128-3 .cone-sec128-6 button:hover{
  border: 1px #df8011 solid;
  background: #df8011;
}


@media screen and (max-width: 991px) {
  .cone-sec127 .cone-sec127-2 {
      padding: 25px 0;
  }
  .cone-sec127 .cone-sec127-10 .cone-sec127-2 {
      border-left: none;
      border-right: none;
  }
}

@media screen and (max-width: 767px) {
  .cone-sec127 .cone-sec127-10 .cone-sec127-2 {
      border-left: none;
      border-right: none;
      padding: 0 0 25px;
  }
  .cone-sec127{
      padding-top: 25px !important;
      padding-bottom: 0 !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
  }
  .cone-sec128 .cone-sec128-2{
      padding-right:0;
  }
  .cone-sec127 .cone-sec127-2{
      padding: 0 0 25px;
  }
  .cone-sec128{
      padding-bottom: 25px !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
  }
}
