
.consult-main {
  display: block;
}
.gradient-custom {
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  background-attachment: fixed;
  padding: 50px 30px 50px;
}
.card-registration .select-input.form-control[readonly]:not([disabled]) {
  font-size: 1rem;
  line-height: 2.15;
  padding-left: 0.75em;
  padding-right: 0.75em;
}
.card-registration .select-arrow {
  top: 13px;
}
.red-star {
  color: red;
}

.conultuj12{
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.conultuj12 .form-outline {
  margin-bottom: 10px;
}
.conultuj12 .form-outline label.form-label,
.conultuj12 .form-outline h6.genfk1{
  color:#7a7a7a;
}
.conultuj12 .form-outline input.form-control{
  border-bottom: 1px #ab9a9a solid;
  border-radius: 0;
  border-left: none;
  border-right: none;
  border-top: none;
  padding: 5px 0 5px;
}
.conultuj12 .form-outline select.select{
  width: 100%;
  border-bottom: 1px #ab9a9a solid;
  border-radius: 0;
  border-left: none;
  border-right: none;
  border-top: none;
  padding: 5px 0 5px;
}
.conultuj12 .const-hfs1{
  color:#C06700;
}

.conultuj12 .form-outline input.form-control:focus,
.conultuj12 .form-outline select.select:focus{
  outline:0;
  border-left: none;
  border-right: none;
  border-top: none;
  color:#C06700;
  border-bottom:1px #e97408 solid;
  box-shadow: none;
}


.conultuj12 .form-check-inline .form-check-label{
  margin: 5px 0 0;
}
.conultuj12 .genfk1{
  margin-bottom: 17px;
}

.conultuj12 .consul-dg-bt12 {
  padding: 15px 30px;
  border-radius: 30px;
  background-color: #62a55e;
  border: 1px solid #62a55e;
  text-decoration: none;
  display: inline-block;
  margin-top: 5px;
  color: #fff;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.conultuj12 .consul-dg-bt12:hover {
  text-decoration: none;
  border: 1px solid #62a55e;
  background-color: transparent;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  color: #000;
}
.conultuj12 .consul-dg-bt12:focus {
  outline: 0;
  border: 1px solid #62a55e;
}
