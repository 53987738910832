.graph-modal__close{
  background-repeat: no-repeat;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  width: auto;
  height: auto;
  right: 20px;
  top: 0px;
  background-image: none;
  color: #fff;
  z-index: 100;
}

.popup{
  max-width: 500px;
  padding-top: 20px;
  background-color: transparent;
}
.modal-img{
  width: 500px !important;
  height: 650px !important;
}

@include small-tablet {

.popup{

  max-width:  400px;
  padding: 20px;
  background-color: transparent;
}
.modal-img{
  width: 400px !important;
  height: 550px !important;
}
}
.modal-content{
  border-radius: 5px;
  width: 100%;
  background-color: #fff;
  padding: 30px;
}
//modal cart

.order-modal{

  &__top{

    margin-bottom: 20px;
  }
  &__title{
    color:  #C06700;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
  }

  &__info{
    padding: 20px;
    width: 100%;
    min-height: 154px;
    border: 1px solid #D9D9D9;
    margin-bottom: 20px;
  }
  &__info-item{
    color: #808080;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
    text-align: left;

    &:not(:last-child){
      margin-bottom: 15px;
    }

    span{
      color: #808080;
      font-size: 14px;
      font-weight: 600;
      line-height: 19.07px;
      text-align: left;

    }
  }
  &__btn{
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px;
    background-color: #C06700;
    color: #fff;
    border-radius: 4px;

  }

  }

  @media (max-width: (576px)) {
   .modal-content{
    padding: 20px 10px;
   }
  }







