.product{
  max-width: 1500px;
  margin: 0 auto;
}
.add-to-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 1px #C06700 solid;
  border-radius:2px;
  --color: #fff;
  margin: 10px 0 0 0;
  position: relative;
  outline: none;
  font-size: 14px;
  padding: 10px 32px;
  font-weight: 500;
  line-height: 20px;
  box-shadow: 0 var(--s-y, 4px) var(--s-b, 12px) var(--shadow);
  color: var(--color);
  background:#C06700;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}
.pro-flt-main {
  display: block;
  margin-top: 10px;
}
.pro-flt-main .pro-spm-txt-m {
  position: relative;
}
.pro-flt-main .pro-spm-txt-m h1 {
  font-size: 28px;
  padding: 20px 0 20px 10px;
  text-align: left;
  text-transform: capitalize;
  font-weight: 500;
  font-family: "Crimson Pro", serif;
}
.pro-flt-main .pro-spm-txt-m a {
  font-size: 15px;
  color: #f15922;
  font-weight: 600;
  padding: 3px 14px 3px;
  border: none;
  background: none;
  position: absolute;
  right: 0;
  top: 21px;
  border-radius: 3px;
}
.pro-flt-main .pro-spm-txt-m a:hover {
  background: #fff;
  color: #203c8b;
  text-decoration: none;
}
.flt-right-main {
  display: block;
  height: 100%;
  background: #fff;
  border-radius: 4px;
  padding: 0 0 50px;
}
.product-nav .filter_data {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 5px;
  flex-wrap: wrap;
}
.flt-pro-box {
  display: grid;
  float: left;
  position: relative;
  border: 1px solid #D9D9D9;
}
.flt-pro-box:hover{
  box-shadow: 0 0 6px #0000001f;
}
.flt-pro-out {
  display: flex;
  margin-bottom: 15px;
}
.flt-pro-img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  overflow: hidden;
  background: #f5f1ef;
}
.flt-pro-img:hover img {
  transform: scale(1.1);
}
.flt-pro-img img {
  max-width: 100%;
  max-height: 100%;
  transition: all 0.2s ease-in-out;
}
.flt-p-out {
  display: flex;
  align-items: center;
}
.flt-cont-out {
  margin-top: auto;
  display: block;
  padding: 18px 10px 10px 10px;
  position: relative;
  background: #fff;
  top: 0;
  z-index: 9;
}
.flt-pro-box:hover .flt-cont-out {
  display: block;
  left: 0;
  right: 0;
  z-index: 999;
  background: #fff;
  -webkit-transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
  -moz-transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
  -o-transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  -moz-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  -o-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}
.flt-cont-price {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
}
.flt-on-love {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
}
.flt-cont-price .flt-p {
  font-size: 18px;
  color: #212121;
  font-weight: 600;
  display: flex;
  margin-right: 10px;
  align-items: center;
}
.flt-cont-price .flt-p-dis-12 {
  font-size: 15px;
  color: #c3bfba;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-decoration: line-through;
}
.flt-cont-price .flt-p i {
  font-size: 15px;
  font-weight: 400;
}
.flt-cont-price .flt-p-n {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #cbcbcb;
}
.flt-cont-price .flt-p-n i {
  font-size: 10px;
  font-weight: 400;
}
.flt-cont-price .flt-p-n span {
  display: flex;
  align-items: center;
  text-decoration: line-through;
}
.flt-cont-price .on-love {
  font-size: 13px;
  color: #3f66d4;
  margin-bottom: 3px;
  align-items: center;
}
.flt-cont-price .on-love:hover {
  text-decoration: none;
}
.flt-cont-price .on-love:hover i,
.flt-cont-price .on-love.active i {
  color: #e8344d;
}
.flt-cont-price .on-love i {
  color: #a6a6a6;
  font-size: 11px;
  margin-right: 1px;
  border: 1px #a6a6a6 solid;
  border-radius: 100%;
  width: 21px;
  height: 20px;
  padding-left: 1px;
  display: inline-block;
  line-height: 20px;
  text-align: center;
  margin-right: 2px;
}
.flt-img-txt {
  font-size: 18px;
  color: #282d3b;
  font-weight: 400;
  line-height: 19px;
}
.flt-img-txt-b {
  font-size: 13px;
  color: #28bc2b;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 0;
}
.flt-img-txt-bb {
  font-size: 16px !important;
}
.flt-img-txt-out {
  display: block;
  margin: 0 0 10px 0;
  text-decoration:none;
}
.flt-img-txt-out:hover .flt-img-txt {
  color: #84868d;
}
.flt-img-txt-out:hover {
  text-decoration: none;
}
.flt-pro-box:hover .flt-pro-ho {
  visibility: visible;
  opacity: 1;
  display: block;
}
.flt-pro-ho {
  display: none;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  width: 100.2%;
  left: 0;
  right: 0;
  padding: 12px 10px 10px;
  background: #fff;
  z-index: 999;
  margin-top: 5px;
  transition: all 0.2s ease-in-out;
  border: 1px #c3c3c3 solid;
  margin-top: 10px;
}
.flt-pro-ho ul {
  padding-left: 18px;
  margin-bottom: 0;
}
.flt-pro-ho ul li {
  font-size: 12px;
  color: #000;
  margin-bottom: 6px;
  line-height: 17px;
}
.product-nav {
  margin: 0 0;
}
.fil-head-txt {
  font-size: 26px;
  margin: 0 0 10px 0;
  font-weight: 400;
  color: #212121;
  font-family: "Crimson Pro", serif;
}
.pro-vh12-3 {
  font-size: 16px;
  color: #484848;
}
.flt-right-main .pro-vh12-1 {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.flt-right-main .pro-vh12-4 {
  display: flex;
  align-items: center;
}
.flt-right-main .pro-vh12-4 .pro-vh12-5 .pro-vh12-6 {
  margin: 0 5px 0 0;
  color: #787878;
  font-size: 14px;
}
.flt-right-main .pro-vh12-4 .pro-vh12-5 .pro-vh12-7 {
  color: #787878;
  height: 35px;
  padding: 0 0px 0 7px;
  font-size: 15px;
  border: 1px solid #ddd;
}
.product-nav ul {
  display: block;
}
.product-nav ul li {
  float: left;
}
.product-nav .nav-tabs .nav-link {
  font-size: 13px;
  color: #000;
  font-weight: 400;
  border: none;
  border-bottom: 1px #ddd solid;
  padding: 0.5rem 10px;
}
.product-nav .nav-tabs .nav-link:hover {
  color: #2954cd;
}
.product-nav .nav-tabs {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  z-index: 9;
}
.product-nav .nav-tabs .nav-link.active {
  border-bottom: 1px #f15922 solid;
  color: #f15922;
}
.product-nav .tab-content {
  display: block;
  width: 100%;
  float: left;
}
.flt-accordion {
  width: 100%;
  max-width: 100%;
}
.flt-table tr td {
  padding-right: 0;
  padding-left: 0;
  padding: 5px 0;
  border-top: none;
}
.flt-table tr td label {
  margin-right: 7px;
}
.flt-accordion li {
  list-style: none;
}
.flt-accordion li ul li {
  border-bottom: none;
}
.flt-accordion .link {
  cursor: pointer;
  display: block;
  padding: 15px 0 15px 0;
  color: #4d4d4d;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.flt-accordion .link a {
  color: #4d4d4d;
}
#myUL li {
  display: flex;
}
#myUL li a {
  width: 70%;
}
#myUL li a label {
  width: 30%;
}
.flt-sear-in-a {
  display: flex;
  width: 100%;
  font-size: 13px;
  margin-bottom: 13px;
  color: #181818;
  border: 1px #dbdbdb solid;
  padding: 7px 10px 7px 31px;
  background: #dbdbdb url(../images/search-icon.png) no-repeat 10px 9px;
}
.flt-sear-in-a:hover,
.flt-sear-in-a:focus {
  transition: all 0.4s linear;
  outline: 0;
  border: 1px #e2213c solid;
  background: #fff url(../images/search-icon.png) no-repeat 10px 9px;
}
.flt-sear-in-a::placeholder {
  color: #000;
}
.flt-accordion li:last-child .link {
  border-bottom: 0;
}
.flt-accordion li i {
  position: absolute;
  top: 16px;
  left: 12px;
  font-size: 18px;
  color: #595959;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.flt-accordion li i {
  right: 3px;
  left: auto;
  font-size: 16px;
}
.flt-accordion li.open .link {
  color: #b63b4d;
}
.flt-accordion li.open .link a {
  font-size: 16px;
  color: #c03500;
}
.flt-accordion li.open i {
  color: #040404;
}
.flt-accordion li.open i.fa-chevron-down {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.no-data12 {
  font-size: 20px;
  color: #c1c1c1;
  font-weight: 400;
  text-align: center;
  padding: 140px 0;
}



.filter-ty12 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
}


.flt-accordion li.default .submenu {
  display: block;
}
.flt-accordion li.default .submenu li a {
  padding-left: 5px;
}
.submenu {
  display: none;
  font-size: 14px;
  padding: 10px 0;
}
.submenu-open {
  display: block;
}
.flt-see-all {
  display: block;
  text-align: right;
}
.flt-see-all a {
  font-size: 13px;
  color: #3f66d4 !important;
}
.flt-see-all a:hover {
  color: #3f66d4;
  text-decoration: underline;
}
.submenu a {
  font-size: 14px;
  font-weight: 500;
  display: block;
  text-decoration: none;
  color: #979797;
  margin: 0px 0 12px;
  padding-left: 27px;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.submenu a:hover {
  color:#212121;
  font-weight: 600;
  text-decoration: none;
}
.flt-price-out {
  padding-left: 0 !important;
}
.flt-con {
  display: block;
  position: relative;
  height: 25px;
  width: 25px;
  margin-bottom: 0;
  margin-right: 7px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.flt-con input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #f5d6b2;
  border: 1px #e39d4b solid;
}
.flt-con input:checked ~ .checkmark {
  background-color: #C06700;
  border: 1px #C06700 solid;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.flt-con input:checked ~ .checkmark:after {
  display: block;
}
.flt-con .checkmark:after {
  left: 10px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.flt-check-out {
  display: flex;
  margin-bottom: 10px;
}
.flt-check-out span {
  align-items: center;
  font-size: 13px;
  display: flex;
}
#flt-toggle {
  display: none;
  width: 28px;
  height: 30px;
  margin-left: auto;
}
#flt-toggle span:after,
#flt-toggle span:before {
  content: "";
  position: absolute;
  left: 0;
  top: -9px;
}
#flt-toggle span:after {
  top: 9px;
}
#flt-toggle span {
  position: relative;
  display: block;
}
#flt-toggle span,
#flt-toggle span:after,
#flt-toggle span:before {
  width: 100%;
  height: 5px;
  background-color: #888;
  transition: all 0.3s;
  backface-visibility: hidden;
  border-radius: 2px;
}
#flt-toggle.on span {
  background-color: transparent;
}
#flt-toggle.on span:before {
  transform: rotate(45deg) translate(5px, 5px);
}
#flt-toggle.on span:after {
  transform: rotate(-45deg) translate(7px, -8px);
}
#flt-toggle.on + #flt-toggle-menu {
  opacity: 1;
  visibility: visible;
  display: block;
}
#flt-toggle-menu {
  position: relative;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.4s;
  display: block;
}
@media screen and (max-width: 1200px) {
  .flt-cont-out {
      padding: 10px 4px;
  }
  .flt-pro-box:hover .flt-cont-out {
      left: 2px;
      right: 2px;
  }
  .product-nav .filter_data {
      grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 1024px) {
  #flt-toggle-menu {
      display: none;
      opacity: 0;
      visibility: hidden;
  }
  #flt-toggle {
      display: flex;
      width: auto;
      height: auto;
      margin-left: 0;
      text-decoration:none;
  }
  #flt-toggle:hover {
      text-decoration: none;
  }
  #flt-toggle span,
  #flt-toggle span:after,
  #flt-toggle span:before {
      width: 35px;
      height: 5px;
  }
  #flt-toggle h1 {
      font-size: 17px;
      margin-bottom: 0;
      display: inline-flex;
      margin-top: -8px;
      margin-left: 9px;
      color: #000;
  }


  .flt-left-main {
      display: block;
      margin-bottom: 17px;
      padding: 30px 0 5px;
      width: 100%;
      float: left;
  }
  .product-nav .nav-tabs {
      position: relative;
      margin-top: 0;
  }
  .flt-right-main {
      margin-top: 2px;
  }
  .flt-accordion li.default .submenu {
      display: none;
  }
  .flt-accordion {
      margin: 20px auto 0;
  }
}
@media screen and (max-width: 992px) {
  .foot-ab {
      padding: 0 0 80px;
  }
  .flt-cont-price .flt-p {
      font-size: 15px;
      display: inline-block;
  }
  .flt-cont-price .flt-p i,
  .flt-cont-price .flt-p-n i {
      font-size: 8px;
  }
  .flt-cont-price .flt-p-n {
      font-size: 13px;
      display: inline-block;
  }
  .flt-img-txt {
      font-size: 16px;
  }
  .flt-img-txt-b {
      font-size: 12px;
  }
  .flt-img-txt {
      margin-bottom: 0;
  }
  .flt-pro-ho ul li {
      font-size: 11px;
      margin-bottom: 3px;
      float: none;
  }

  .flt-cont-price .flt-p-n span {
      display: inline-block;
  }
  .flt-on-love {
      display: block;
      align-items: center;
      width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .pro-flt-main .pro-spm-txt-m h1 {
      font-size: 24px;
  }
  .flt-pro-img {
      height: 250px;
  }
}
@media screen and (max-width: 768px) {
  .product-nav .filter_data {
      grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .lt-wo12 {
      display: block;
  }
  .le-wsh12 .le-wsh14 {
      display: flex;
      height: 320px;
      overflow: hidden;
  }
  .le-wsh12 .le-wsh14 img {
      position: absolute;
      top: 0;
  }
  .hp-fhk14 {
      justify-content: center;
  }
  .pop-dj12 ul.pop-dj13 {
      justify-content: center;
  }
  .pop-dj12 h2 {
      text-align: center;
  }
  .flt-pro-out {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0;
  }
  .flt-pro-box {
  }
  .flt-pro-box:hover .flt-cont-out {
      position: relative;
      top: 0;
  }
  .flt-pro-box:hover .flt-pro-ho {
      display: none;
  }
  .flt-pro-box:nth-child(3) {
      border-left: none;
  }
  .flt-cont-price {
      display: block;
      margin-bottom: 4px;
  }
  .flt-img-txt-out {
      margin: 10px 0 10px;
  }
  .product-nav .nav-tabs .nav-link {
      font-size: 11px;
      padding: 0.5rem 4px;
  }
  .flt-pro-img {
      height: 150px;
  }
}
@media screen and (max-width: 560px) {
  .flt-img-txt {
      font-size: 14px;
  }
  .flt-cont-price .flt-p {
      font-size: 13px;
  }
  .flt-cont-price .flt-p-dis-12 {
      font-size: 11px;
  }
  .pro-vh12-3 {
      font-size: 13px;
  }
  .flt-right-main .pro-vh12-1 {
      display: block;
  }
  .flt-right-main .pro-vh12-1 .pro-vh12-2{
      margin-bottom: 15px;
  }
  .flt-right-main .pro-vh12-4 .pro-vh12-5 .pro-vh12-6 {
      font-size: 12px;
  }
  .flt-right-main .pro-vh12-4 .pro-vh12-5 .pro-vh12-7 {
      font-size: 12px;
  }


}
.my-profile-main {
  padding-bottom: 50px;
}
.my-profile-left {
  height: 100%;
  background: #fdfbe9;
  border: 1px #e1ddb5 solid;
  border-radius: 4px;
  padding: 12px 12px 30px;
}
.my-profile-right {
  display: block;
  background: #fff;
  border: 1px #e0e0e0 solid;
  border-radius: 2px;
  padding: 22px 0 22px;
  letter-spacing: 0;
}
.flt-accordion li.open .link i {
  transform: rotate(90deg);
}
.my-pro-ac {
  padding: 0 30px;
}
.my-pro-ac form .form-group .pro-txt-ad {
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: #4a4a4a;
  margin-bottom: 15px;
}
.my-pro-ac form .form-group {
  margin-bottom: 50px;
}
.my-pro-ac form .form-group .pro-fld-ad {
  width: 50%;
  padding: 10px 10px;
  border: 1px #dcdcdc solid;
  background: #f9f9f9;
}
.my-pro-ac form .form-group .pro-btn-ad {
  background: #ffd669;
  color: #000;
  font-weight: 600;
  border: 1px #c5a95f solid;
  border-radius: 3px;
  padding: 6px 15px 7px 15px;
  width: 100px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.my-pro-ac form .form-group .pro-btn-ad:hover {
  background: #ffffff;
  border: 1px #c5c5c3 solid;
}
.my-pro-ac form .form-group .pro-btn-ad:focus {
  outline: 0;
  border: 1px #c5a95f solid;
}
.my-pro-ac form .form-group .pro-fld-ad:focus {
  outline: 0;
  border: 1px #adadad solid;
}
.my-pro-ac form .form-group .pro-fld-ad::placeholder {
  color: #ccc;
}
.my-name-txt {
  text-align: center;
  padding: 18px 0 22px;
  border-bottom: 1px #999999 solid;
}
.my-name-txt .my-name-pro {
  width: 70px;
  height: 70px;
  background: #ccc;
  max-width: 100%;
  border: 1px #afaeae solid;
  border-radius: 100%;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-name-txt .my-name-pro svg {
  width: 100%;
}
.my-name-txt .my-name-pro img {
  width: 100%;
}
.my-name-txt .my-name {
  font-size: 18px;
  font-weight: 600;
  margin-top: 8px;
  letter-spacing: normal;
}
.file-upload-profile12 {
  position: relative;
  display: inline-block;
}
.file-upload-profile12 .file-upload__label {
  display: block;
  padding: 4px 14px;
  font-size: 13px;
  font-weight: 600;
  border: 1px #dcdcdc solid;
  background: #f9f9f9;
  color: #f15922;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.file-upload-profile12 .file-upload__label:hover {
  border: 1px #f15922 solid;
}
.file-upload-profile12 .file-upload__input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 1;
  width: 0;
  height: 100%;
  opacity: 0;
}
.my-name-lnk {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 30px;
}
.my-name-lnk li {
  list-style: none;
}
.my-name-lnk li a {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 22px 15px;
  border-bottom: 1px #f2f2f2 solid;
}
.my-name-lnk li a:hover {
  text-decoration: none;
}
.my-name-lnk li a .sid-ul-nam {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 0;
}
.my-name-lnk li a:hover .sid-ul-nam {
  color: #c05f30;
}
.my-name-lnk li a.active .sid-ul-nam {
  color: #c05f30;
}
.my-name-lnk li a span {
  width: 12px;
}
.my-name-lnk li a span img {
  width: 100%;
}
.my-name-lnk li a span svg {
  fill: #ec1717;
}
.logout-ab {
  padding: 16px 15px;
  margin: 50px 0 0 0;
}
.logout-ab a {
  display: flex;
  align-items: center;
}
.logout-ab a:hover {
  text-decoration: none;
}
.logout-ab svg {
  width: 25px;
  height: 25px;
}
.logout-ab svg path {
  fill: #e8344d !important;
  transition: all 0.4s ease-in-out;
}
.logout-ab a:hover svg path {
  fill: #000 !important;
}
.combo-main15 {
  margin: 20px 0 15px;
}
.combo-out12 {
  text-align: center;
  margin-bottom: 15px;
}
.combo-out12 .combo-outimg13 {
  display: flex;
  width: 110px;
  height: 110px;
  margin: 0 auto;
  border-radius: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border: 1px #ccc solid;
}
.combo-out12 .combo-outtxt14 {
  font-size: 15px;
  font-weight: 600;
  margin-top: 5px;
  padding: 0 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.combo-out12 .combo-outimg13 img {
  max-width: 100%;
  max-height: 100%;
}
.logout-ab span {
  margin-left: 10px;
  color: #e8344d;
  font-weight: 600;
  transition: all 0.4s ease-in-out;
}
.logout-ab a:hover span {
  text-decoration: none;
  color: #000;
}
@media screen and (max-width: 767px) {
  .my-pro-ac form .form-group .pro-fld-ad {
      width: 100%;
  }
  .combo-out12 .combo-outimg13 {
      width: 80px;
      height: 80px;
  }
  .combo-out12 .combo-outtxt14 {
      padding: 0 0px;
  }
}
.mange-add-ac {
  padding: 0 30px;
}
.in-heda-ab {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 25px;
}
.add-add-ab .pro-txt-ad {
  display: block;
  font-size: 16px;
}
.add-add-ab .form-group{
  margin-bottom:25px;
}
.add-add-ab .pro-txt-ad{
  margin-bottom:12px;
}
.add-add-ab .pro-fld-ad {
  display: block;
  width: 100%;
  padding: 10px 10px;
  border: 1px #dcdcdc solid;
  background: #f9f9f9;
}
.add-add-ab .form-group .pro-btn-ad {
  background: #ffd669;
  color: #3e2200;
  font-weight: 600;
  border: 1px #c5a95f solid;
  border-radius: 3px;
  padding: 9px 15px 10px 15px;
  width: 100px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.add-add-ab .form-group .pro-btn-ad:hover {
  background: #ffffff;
  border: 1px #c5c5c3 solid;
}
.add-add-ab .form-group .pro-btn-cnl {
  background: #ffffff;
  border: 1px #c5c5c3 solid;
  color: #212121;
  font-weight: 600;
  border-radius: 3px;
  padding: 9px 15px 10px 15px;
  margin-left: 10px;
  width: 100px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.add-add-ab .form-group .pro-btn-cnl:hover {
  color: #946713;
  border: 1px #c5a95f solid;
}
.add-add-ab .form-group .pro-btn-ad {
  margin: 10px 0 0;
}
#showa {
  background: #ffd669;
  color: #212121;
  font-weight: 600;
  border: 1px #c5a95f solid;
  border-radius: 3px;
  padding: 8px 15px 9px 15px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-in-out;
  margin: 10px 0 0 0;
}
#showa:focus,
#hidea:focus {
  outline: 0;
  border: 1px #b9b9b9 solid;
}
#showa:hover {
  background: #ffffff;
  color: #000;
  border: 1px #b9b9b9 solid;
}
#hidea {
  float: right;
  background: #fff;
  color: #969696;
  border: 1px #c7c7c7 solid;
  padding: 7px 20px 7px 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
#hidea:hover {
  background: #ffffff;
  color: #000;
  border: 1px #b9b9b9 solid;
}
.modal-hdfg13{
  border: none;
  background: #fff;
}
.modal-hdfg13:hover{
  color: #a5a5a5;
}


.mange-add-ac .add-del-ad {
  border: 1px #ccc solid;
  padding: 20px;
  margin-top: 30px;
}
.mange-add-ac .add-del-ad .add-ph-ae{
  font-size: 16px;
  font-weight: 500;
}
.mange-add-ac .add-del-ad .add-me-ae{
  font-size: 18px;
  font-weight: 600;
  margin: 15px 0 10px 0;
}
.mange-add-ac .add-del-ad .add-af {
  font-size: 15px;
  line-height: 22px;
  margin-top: 12px;
  margin-bottom: 0;
}
.add-del-ad .bt-radio-ab input {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  cursor: pointer;
}
.bt-radio-ab {
  color: #fff;
  background-color: #868e96;
  border-radius: 100%;
  width: 22px;
  height: 22px;
  padding: 0;
  border: 5px #d6d6d6 solid;
}
.bt-radio-ab.active {
  background: #488aef;
  border: 5px #cedbef solid;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.bt-radio-aa {
  display: block;
}
.bt-radio-aa .bt-radio-ab {
  float: right;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  padding: 0;
}
.noti-out-ab {
  display: block;
  margin-top: 20px;
}
.noti-in-ac {
  display: flex;
  width: 100%;
  margin: 10px 0;
}
.noti-in-ac .noti-in-ad {
  width: 100%;
  max-width: 80px;
  height: 80px;
  background: #cfdfe8;
  border-radius: 100%;
  overflow: hidden;
  justify-content: center;
  align-self: center;
  display: flex;
}
.noti-in-ac .noti-in-ad img {
  width: 100%;
}
.not-date-out {
  display: flex;
  justify-content: flex-end;
}
.not-date-out .not-date-ab {
  font-size: 13px;
  font-weight: 600;
}
.noti-in-ac .noti-in-ae {
  width: 100%;
  padding: 0 15px;
  border-bottom: 1px #e8e8e8 solid;
}
.noti-in-ae .not-had-ab {
  font-size: 20px;
  font-weight: 600;
}
.noti-in-ae .not-prg-ab {
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  color: #3a3a3a;
}
.not-fd-ban-a {
  display: block;
  background: #ececec;
  padding: 100px 0 200px;
}
.not-fd-ban-a img {
  max-width: 100%;
}
.not-fd-ban-ab {
  text-align: center;
}
.not-fd-ban-ab a {
  display: inline-block;
  background: #fff;
  color: #000;
  font-weight: 600;
  margin-top: 40px;
  padding: 12px 25px;
  border-radius: 7px;
  box-shadow: 1px 1px 1px #0000005c;
}
.not-fd-ban-ab a:hover {
  text-decoration: none;
  color: #305ad2;
}
.wsh-out-ab {
  display: block;
  margin-top: 20px;
}
.wsh-in-ac {
  display: flex;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px #e8e8e8 solid;
}
.wsh-in-ac:last-child {
  border-bottom: none;
}
.wsh-in-ac:hover {
  text-decoration: none;
}
.wsh-in-ac .wsh-in-ad {
  width: 100%;
  max-width: 100px;
  height: 100px;
  overflow: hidden;
  justify-content: center;
  align-self: center;
  display: flex;
}
.wsh-in-ac .wsh-in-ad img {
  max-width: 100%;
  max-height: 100%;
}
.wsh-in-ac .wsh-in-ae {
  width: 100%;
  padding: 0 15px;
}
.wsh-delete-out {
  display: flex;
  justify-content: flex-end;
}
.wsh-delete-out .wsh-delete-ab {
  font-size: 13px;
  background: #fff;
  border: #ddd;
  color: #7c7c7c;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.wsh-delete-out .wsh-delete-ab:hover {
  color: #c05f30;
}
.wsh-delete-out .wsh-delete-ab:focus {
  outline: 0;
  border: none;
}
.wsh-in-ac:hover .wsh-in-ae .wsh-had-ab {
  text-decoration: underline;
}
.wsh-in-ae .wsh-had-ab {
  font-size: 18px;
  margin-bottom: 13px;
  font-weight: 600;
  color: #212121;
}
.wsh-in-ac .pro-price {
  font-size: 18px;
  color: #c05f30;
  font-weight: 600;
}
.wsh-in-ac .pro-price .pro-price-in {
  font-size: 18px;
  font-weight: 700;
}
.wsh-in-ac .pro-price .pro-rup {
  font-size: 18px;
  font-weight: 300;
}
.pro-details-main {
  display: block;
  padding-bottom: 50px;
  padding-top: 8px;
  background: #fff;
}
.prd-left-main {
  height: 100%;
  background: #fff;
  padding: 0 0 30px;
}
.prd-right-main {
  display: block;
  height: 100%;
  background: #fff;
  padding: 5px 0 50px;
  position: relative;
}
.prd-rt-head {
  font-size: 30px;
  color: #484848;
  font-family: "Crimson Pro", serif;
}
.prd-pri-out {
  display: flex;
  align-items: flex-end;
}
.prd-pri-out .prd-pc{
  font-size: 14px;
  margin: 0 18px 2px 0;
  font-weight: 400;
}
.prd-pri-out .prd-pb {
  display: flex;
  padding-left: 12px;
  color: #a7a7a7;
  font-size: 21px;
  text-decoration: line-through!important;
}
.prd-pri-out .prd-p {
  display: flex;
  font-size: 24px;
  font-weight: 600;
  color: #484848;
}
.in-alfj12{
  font-size: 12px;
  line-height: 1.2;
  font-weight: 500;
  display: block;
  padding-top: 10px;
  font-style: italic;
}
.prd-pri-out .prd-p i {
  font-size: 17px;
  align-self: center;
  font-weight: 400;
  align-items: center;
  display: inline-flex;
}

.off-oud1{
  background: #fff;
  box-shadow:0px 0px 8px #00000024;
  padding: 30px 20px 36px 20px;
  margin-left: 2px;
  margin-right: 2px;
}
.off-oud1 .off-oud1-2 .off-oud1-3{
  color:#C06700;
  letter-spacing: 0.7px;
}
.off-oud1 .off-oud1-2 .off-oud1-4 li{
  padding-left: 28px;
  position: relative;
  line-height: 20px;
  margin-bottom: 14px;
}
.off-oud1 .off-oud1-2 .off-oud1-4 li::after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background-image: url(../images/offer-icon.svg);
  background-size: 20px;
  background-repeat: no-repeat;
}

.ovrif1 .ovrif1-1 ul{
  padding-left: 20px;
}
.ovrif1 .ovrif1-1 ul li{
  list-style: disc;
  color: #707070;
}
.ovrif1 .ovrif1-1 ul li h6{
  margin-bottom:7px;
}


.prd-li-co-out {
  justify-content: flex-end;
  display: flex;
}

.deli-cfj1{
  width: 100%;
  padding: 25px 20px 30px;
  background:#f7e9da;
}
.deli-cfj1 .deli-cfj1-3{
  display: flex;
  border-bottom: 1px #ddbe9f solid;
}
.deli-cfj1 .deli-cfj1-3 input{
  width: 100%;
  background: none;
  padding: 8px 0 8px;
  border: none;
}
.deli-cfj1 .deli-cfj1-3 input:focus{
  outline: 0;
  border: none;
}
.deli-cfj1 .deli-cfj1-3 input::placeholder{
  font-size:15px;
}
.deli-cfj1 .deli-cfj1-3 button{
  color: #c4601d;
  background: none;
  border: navajowhite;
  padding: 8px 0 8px;
}
.deli-cfj1 .deli-cfj1-4{
  margin: 15px 0 0 0;
}
.deli-cfj1 .deli-cfj1-4 .deli-cfj1-5{
  margin-bottom:8px;
}
.deli-cfj1 .deli-cfj1-4 .deli-cfj1-6{
  color: #d13c0f;
}


.benifgj1 .benifgj1-2{
  display: grid;
  height: 100%;
  border-top-right-radius: 100%;
  border-top-left-radius: 100%;
  background: #f5e7d2;
  overflow: hidden;
}
.benifgj1 .benifgj1-2 .benifgj1-3 img{
  width:100%;
}
.benifgj1 .benifgj1-2 .benifgj1-4{
  padding: 32px 15px 35px;
}
.benifgj1 .row .col-lg-4:nth-child(4n+1) .benifgj1-2{
  background:#f5e7d2;
}
.benifgj1 .row .col-lg-4:nth-child(4n+2) .benifgj1-2{
  background:#e1e0c4;
}
.benifgj1 .row .col-lg-4:nth-child(4n+3) .benifgj1-2{
  background:#c8d6e7;
}
.benifgj1 .row .col-lg-4:nth-child(4n+4) .benifgj1-2{
  background:#e7d1c8;
}





.prd-li-co-out {
  position: relative;
}
.alert-su {
  width: 50%;
  top: 53px;
  right: 0;
  z-index: 999;
  font-size: 14px;
  position: absolute;
}
.alert-su .alert{
  display:flex;
}
.alert-su .alert .close{
  color:#212121;
  font-size:16px;
  font-weight:600;
  text-decoration:none;
  margin-right:4px;
}

.prd-li-co-out .on-love {
  font-size: 15px;
  color: #203c8b;
  margin-left: 20px;
}
.prd-li-co-out .on-love:hover {
  text-decoration: none;
  color: #e8344d;
}
.prd-li-co-out .on-love:hover i {
  color: #e8344d;
}
.prd-li-co-out .on-love i {
  color: #a6a6a6;
  margin-right: 2px;
}


.prd-de-co-b a {
  width: 100%;
  color: #0056b3;
}
.prd-de-co-b p {
  width: 100%;
}


.prd-size {
  display: flex;
  margin-bottom: 15px;
}
.prd-size h2 {
  font-size: 15px;
  font-weight: 400;
  display: inline-flex;
  margin: 9px 25px 0 0;
}


.prd-details-out-b .prd-de-co-b p {
  font-size: 14px;
  line-height: 27px;
}

.prd-tab-con-in {
  margin-bottom: 29px;
}
.prd-tab-con-in .prd-tab-de-h {
  font-size: 15px;
  margin: 0 0 13px 19px;
}
.prd-tab-con-in .prd-tab-con-a {
  display: flex;
  background: #f8f8f8;
  padding: 11px 10px 11px 20px;
  margin-bottom: 2px;
}
.prd-tab-con-in .prd-tab-con-a h3,
.prd-tab-con-in .prd-tab-con-a h4,
.prd-tab-con-in .prd-tab-con-a p {
  width: 50%;
  font-size: 14px;
  margin-bottom: 0px;
}
.prd-tab-con-in .prd-tab-con-a p {
  word-wrap: break-word;
  font-weight: 600;
}
.prd-tab-con-in .prd-tab-con-a h4 {
  font-weight: 600;
}
.prd-rating-out {
  margin-top: 30px;
  padding: 30px 30px;
  border: 1px #bcbcbc solid;
}
.prd-rating-out .prd-rating-txt {
  font-size: 18px;
}
.prd-rating-out .prd-rating-in .prd-rating-in-a {
  width: 30%;
  float: left;
}
.prd-rating-out .prd-rating-in .prd-rating-in-b {
  width: 60%;
  float: right;
  padding-top: 70px;
}
.prd-rating-out .prd-rating-in .prd-rating-in-a .prd-rating-in-a-a {
  text-align: center;
}
.prd-rating-m form {
  display: block;
}
.prd-rating-m input[type="radio"] {
  display: none;
}
.prd-rating-m label {
  color: grey;
  margin-bottom: 0;
  font-size: 30px;
}
.prd-rating-m .clasificacion {
  direction: rtl;
  unicode-bidi: bidi-override;
  margin-bottom: 0;
}
.prd-rating-m label:hover,
.prd-rating-m label:hover ~ label {
  color: orange;
  margin-bottom: 0;
}
.prd-rating-m input[type="radio"]:checked ~ label {
  color: orange;
}
.prd-rating-in-a-b {
}
.prd-rating-in-a-b .prd-rating-in-a-c {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border: 3px #ccc solid;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.prd-rating-in-a-b .prd-rating-in-a-c a {
  font-size: 25px;
  font-weight: 600;
  color: #000;
}
.prd-progrees-rate-out {
  display: flex;
  margin-bottom: 5px;
}
.prd-progrees-rate-out .progress {
  width: 70%;
  height: 5px;
  align-self: center;
}
.prd-progrees-rate-out .progress .progress-bar {
  background: #e76814;
}
.prd-progrees-rate {
  width: 10%;
  font-size: 12px;
  align-self: center;
}
.prd-progrees-rate i {
  color: #e2e2e2;
}
.prd-at-rate {
  width: 20%;
  font-size: 12px;
  align-self: center;
  margin-left: 10px;
}
.prd-control-out {
  display: flex;
  margin-top: 40px;
}
.prd-control-out button {
  width: 35%;
  cursor: pointer;
  transition: all 0.2s linear;
}
.prd-control-out .prd-control-add {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 16px 5px 16px;
  border-radius: 4px;
  margin-right: 20px;
  color: #C06700;
  border: 1px #C06700 solid;
  background: #fff;
}
.prd-control-out .prd-control-add:hover,
.prd-control-out .prd-control-add:focus {
  outline: 0;
  color: #80ba28;
  border: 1px #80ba28 solid;
}
.prd-control-out .prd-control-buy {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  background: #C06700;
  padding: 16px 5px 16px;
  border-radius: 4px;
  border: 1px #C06700 solid;
}
.prd-control-out .prd-control-buy:hover,
.prd-control-out .prd-control-buy:focus {
  outline: 0;
  background: #d77b10;
  border: 1px #d77b10 solid;
}
.fixedsticky {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  z-index: 8;
}
.fixedsticky {
  top: 145px;
}
.fixedsticky-withoutfixedfixed .fixedsticky-off,
.fixed-supported .fixedsticky-off {
  position: static;
}
.fixedsticky-withoutfixedfixed .fixedsticky-on,
.fixed-supported .fixedsticky-on {
  position: fixed;
}
.fixedsticky-dummy {
  display: none;
}
.fixedsticky-on + .fixedsticky-dummy {
  display: block;
}
.cloud-zoom-lens {
  border: 4px solid #888;
  margin: -4px;
  background-color: #fff;
  cursor: move;
}
.cloud-zoom-title {
  position: absolute !important;
  background-color: #000;
  color: #fff;
  padding: 3px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 10px;
  top: 0px;
}
.cloud-zoom-wrap {
  top: 0;
  z-index: 9999;
  position: relative;
}
.cloud-zoom-big {
  border: 4px solid #ccc;
  overflow: hidden;
}
.cloud-zoom-loading {
  color: white;
  background: #222;
  padding: 3px;
  border: 1px solid #000;
}
div#colorbox {
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 9999;
}
div#cboxOverlay {
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
div#cboxContent {
  margin: 0 auto !important;
  float: none !important;
  height: 100% !important;
  text-align: center;
  width: 80% !important;
  position: relative;
}
div#cboxWrapper {
  width: 100% !important;
  height: 100% !important;
}
div#cboxMiddleLeft {
  position: absolute;
}
img.cboxPhoto {
  max-width: 100% !important;
  width: auto !important;
  height: auto !important;
}
div#cboxTitle {
  display: none !important;
}
button#cboxClose {
  position: absolute;
  right: -40px;
  top: 0px;
  font-size: 0;
  background: transparent;
  border: 0;
  height: 60px;
  cursor: pointer;
}
button#cboxClose:before {
  content: "";
  transform: rotate(45deg);
  height: 1px;
  width: 60px;
  background: #fff;
  display: block;
  margin-top: 0px;
}
button#cboxClose:after {
  content: "";
  transform: rotate(-45deg);
  height: 2px;
  width: 60px;
  background: #fff;
  display: block;
  margin-top: 0px;
}
div#cboxLoadedContent {
  text-align: center;
  width: 100% !important;
  position: relative;
}
.cloud-zoom-lens {
  border: 4px solid #888;
  margin: -4px;
  background-color: #fff;
  cursor: move;
}
.cloud-zoom-title {
  position: absolute !important;
  background-color: #000;
  color: #fff;
  padding: 3px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 10px;
  top: 0px;
}
.cloud-zoom-wrap {
  top: 0;
  z-index: 9999;
  position: relative;
}
.cloud-zoom-gallery-thumbs a.cloud-zoom-gallery:only-child {
  display: none;
}
.cloud-zoom-big {
  border: 4px solid #ccc;
  overflow: hidden;
  z-index: 999999999999999 !important;
}
.cloud-zoom-loading {
  color: white;
  background: #222;
  padding: 3px;
  border: 1px solid #000;
}



.prd-li-co-out {
  justify-content: flex-end;
  display: flex;
}
.prd-li-co-out .prd-size-radio input[type="checkbox"] {
  display: none;
}
.prd-li-co-out .prd-size-radio input[type="radio"] {
  display: none;
}
.prd-li-co-out .prd-size-radio label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  width: 37px;
  height: 37px;
  color: #C06700;
  border: 1px #C06700 solid;
  border-radius:4px;
  text-align: center;
  cursor: pointer;
  float: left;
  font-size: 17px;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
}
.prd-li-co-out .prd-size-radio label:hover {
  color: #fff;
  background: #C06700;
  border: 1px #C06700 solid;
}
.prd-li-co-out .prd-size-radio input[type="checkbox"]:checked + label {
  color: #fff;
  background: #C06700;
  border: 1px #C06700 solid;
}

.prd-li-co-out {
  position: relative;
}



.basic-sample > div {
  width: 300px;
  height: 400px;
}
.gallery-sample {
  display: flex;
  padding: 3px;
  justify-content: center;
  align-items: center;
  border: 1px #e4e6e6 solid;
}
.gallery-sample .cloud-zoom-wrap a {
  padding: 3px;
  display: flex;
  height: 400px;
  padding: 3px;
  justify-content: center;
  align-items: center;
}
.gallery-sample .cloud-zoom-wrap {
}
.gallery-sample a.cloud-zoom {
  position: relative;
  display: block;
}
.gallery-sample a.cloud-zoom img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
}
.recent_list {
  display: block;
  list-style: none;
  padding-left: 0;
}
.recent_list li {
  margin-bottom: 10px;
  border: 1px solid #ddd;
}
.recent_list li:hover {
  border: 1px #dd790e solid;
}
.recent_list li img {
  display: block;
  max-width: 100%;
  max-height: 60px;
  margin: 0 auto;
}
@media screen and (max-width: 1024px) {
  .recent_list li {
      float: left;
      margin-right: 4px;
  }
  .gallery-sample .cloud-zoom-wrap a {
      height: 160px;
  }
  .recent_list li img {
      max-height: 35px;
  }
  .prd-control-out .prd-control-add,
  .prd-control-out .prd-control-buy {
      font-size: 13px;
      padding: 12px 5px 12px;
  }
  .cloud-zoom-big,
  .cloud-zoom-lens {
      display: none !important;
  }
  .gallery-sample a.cloud-zoom {
      cursor: default;
  }

  .prd-rt-head {
      font-size: 18px;
  }
  .prd-pri-out .prd-p {
      font-size: 19px;
  }
  .prd-pri-out .prd-p i
  {
      font-size: 15px;
  }

  .prd-select h2,
  .prd-size h2{
      font-size: 12px;
      font-weight: 600;
  }

  .prd-size {
      justify-content: flex-end;
  }
  .prd-details-out-b .prd-de-co-b p,
  .prd-select select {
      font-size: 13px;
  }


  .prd-tab-con-in .prd-tab-de-h {
      font-size: 13px;
  }
  .prd-tab-con-in .prd-tab-con-a h3,
  .prd-tab-con-in .prd-tab-con-a h4 {
      font-size: 12px;
  }
  .prd-rating-out .prd-rating-txt {
      font-size: 15px;
      font-weight: 600;
  }

  .prd-left-main {
      padding: 0 0 24px;
  }
  .prd-right-main {
      padding: 5px 0 50px;
  }
  .mar-top40 {
      margin-top: 35px;
  }
  .prd-rating-in-a-b .prd-rating-in-a-c a {
      font-size: 20px;
  }
}
@media screen and (max-width: 991px) {
  .my-profile-right {
      margin: 12px 0 0 0;
  }
}
@media screen and (max-width: 767px) {
  .my-app-gfwout .my-app-df12 .my-app-df16 {
      display: block;
  }
  .my-app-gfwout .my-app-df12 .my-app-df16 .my-app-df19{
      margin-bottom:5px;
  }
  .my-app-gfwout .my-app-df12 .my-app-df16 .my-app-df19 .my-app-df17 {
      margin-bottom: 15px;
  }
  .prd-size {
      justify-content: flex-start;
  }
  .prd-rating-in-a-b .prd-rating-in-a-c {
      width: 125px;
      height: 125px;
  }
  .prd-rating-out .prd-rating-in .prd-rating-in-b {
      width: 60%;
      float: right;
      padding-top: 38px;
  }
  .prd-rating-out {
      margin-top: 2px;
  }

  .prd-li-co-out .on-love {
      font-size: 13px;
  }
  .off-oud1 .off-oud1-2 .off-oud1-3 {
      font-size: 20px;
  }
  .off-oud1 .off-oud1-2 .off-oud1-4 li {
      font-size: 13px;
  }
  .off-oud1 .off-oud1-2 .off-oud1-4 li::after {
      top: 2px;
      background-size: 16px;
  }
  .off-oud1 .off-oud1-2 .off-oud1-4 li {
      padding-left: 25px;
  }
  .deli-cfj1 .deli-cfj1-2{
      margin-bottom: 6px;
      font-size: 20px;
  }
  .deli-cfj1 {
      margin-top: 10px;
  }
  .ovrif1 .ovrif1-1 h3{
      font-size: 24px;
  }
  .benifgj1 .benifgj1-1{
      font-size:24px;
  }
  .benifgj1 .benifgj1-2 .benifgj1-4 {
      font-size: 18px;
      padding: 15px 15px 20px;
  }
  .pro-flt-main .pro-spm-txt-m h1 {
      padding: 20px 0 20px 0;
  }
  .off-oud1 {
      margin-top: 8px;
  }
  .benifgj1 .benifgj1-2 {
      border-top-right-radius: 50px;
      border-top-left-radius: 50px;
  }
}
@media screen and (max-width: 575px) {
  .prd-li-co-out .on-love {
      font-size: 12px;
  }
  .prd-rt-head {
      font-size: 16px;
  }
  .prd-pri-out .prd-p {
      font-size: 17px;
  }
  .prd-pri-out .prd-p i{
      font-size: 13px;
  }

  .prd-rating-out .prd-rating-in .prd-rating-in-a {
      width: 100%;
  }
  .prd-rating-out .prd-rating-txt {
      text-align: center;
  }
  .prd-rating-out .prd-rating-in .prd-rating-in-b {
      width: 100%;
  }
  .prd-at-rate {
      text-align: right;
  }
  .prd-progrees-rate {
      width: 20%;
  }
  .prd-select select {
      width: 100%;
  }

  .prd-tab-con-in .prd-tab-de-h {
      margin: 0 0 13px 10px;
  }
  .prd-tab-con-in .prd-tab-con-a {
      padding: 11px 11px 11px 11px;
  }
  .prd-control-out .prd-control-add,
  .prd-control-out .prd-control-buy {
      font-size: 11px;
  }
}
.cart-main {
  display: block;
  padding-bottom: 50px;
}
.crt-left-main {
  height: 100%;
  background: #fff;
  border-radius: 4px;
  padding: 30px 0 30px;
}
.crt-right-main {
  display: block;
  height: 100%;
  background: #fff;
  border-radius: 4px;
  padding: 30px 0 10px;
}
.crt-left-main .crt-head-txt {
  font-size: 22px;
  margin: 0 0 20px 0;
  padding-bottom: 20px;
}
.crt-right-main .crt-cart-totals .crt-head-sum-12 {
  font-size: 22px;
  margin: 0 0 20px 0;
  padding-bottom: 20px;
}
.cart-main .crt-product-col ._grid {
  text-align: justify !important;
  text-justify: distribute-all-lines;
  text-rendering: optimizespeed;
  display: flex;
}
.cart-main ._column {
}
.cart-main ._btn {
  display: inline-block;
  background-color: #e8344d;
  border: 1px #e8344d solid;
  color: #fff;
  padding: 5px 12px;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
}
.che-ck12 .checkout-btn {
  display: block;
  background-color: #1277b4;
  border: 1px #1277b4 solid;
  color: #fff;
  padding: 10px 12px 11px;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
}
.che-ck12 .checkout-btn:hover {
  text-decoration: none;
  background-color: #065687;
  border: 1px #065687 solid;
}
.cart-main ._btn:hover,
.cart-main ._btn:focus {
  outline: 0;
  background-color: #ffffff;
  color: #000;
  text-decoration: none;
}
.cart-main ._btn-b {
  display: block;
  background-color: #ebf7ff;
  border: 1px #333333 solid;
  color: #484848;
  padding: 10px 12px 11px;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
  margin-bottom: 10px;
}
.cart-main ._btn-b:hover,
.cart-main ._btn-b:focus {
  outline: 0;
  border: 1px #484848 solid;
  color: #fff;
  background: #484848;
  text-decoration: none;
}
.cart-main .shopping-cart {
  display: block;
}
.cart-main .shopping-cart--list-item {
  display: flex;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 30px;
  padding-bottom: 23px;
}
.cart-main .shopping-cart--list-item:last-child {
  border-bottom: none;
}
.cart-main .shopping-cart--list-item > ._column {
  letter-spacing: inherit;
  height: 100%;
}
.cart-main .cart-img-out {
  width: 15%;
  align-items: center;
}
.cart-main .cart-img-out img {
  max-width: 100%;
  max-height: 100%;
}
.cart-main .crt-product-info {
  width: 55%;
  padding: 5px 10px 0 20px;
}
.cart-main .product-name {
  font-size: 18px;
  color: #212121;
  margin: 0 0 15px;
}
.crt-product-desc {
  font-size: 13px;
  border: 1px #ccc solid;
  display: inline-block;
  padding: 2px 10px;
  line-height: 21px;
  margin-top: 10px;
}
.cart-main .crt-price {
  line-height: 1;
  font-size: 16px;
  color: #484848;
}
.cart-main .product-modifiers {
  text-align: right;
}
.cart-main .crt-product-col {
  margin-left: auto;
}
.cart-main .product-qty {
  width: 35px;
  background-color: transparent;
  color: #686868;
  text-align: center;
  align-self: center;
}
.cart-main .product-subtract,
.cart-main .product-plus {
  background-color: #ffffff;
  color: #000000;
  border: 1px #c1c1c1 solid;
  border-radius: 9px;
  font-size: 17px;
  cursor: pointer;
}
.shopping-cart--list-item:hover .product-subtract,
.shopping-cart--list-item:hover .product-plus {
  border: 1px #c1c1c1 solid;
}
.cart-main .product-plus:hover,
.cart-main .product-plus:focus,
.cart-main .product-subtract:hover,
.cart-main .product-subtract:focus {
  outline: 0;
  border: 1px #aeaeae solid;
  border-radius: 9px;
}
.cart-main .product-qty {
}
.cart-main .crt-product-info .product-remove {
  font-size: 13px;
  margin-top: 13px;
  background-color: #ffffff;
  color: #848484;
  padding: 0;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
}
.cart-main .crt-product-info .product-remove:hover,
.cart-main .crt-product-info .product-remove:focus {
  outline: 0;
  border: none;
  color: #000000;
}
.cart-main .product-modifiers:hover .product-remove {
  visibility: visible;
}
.cart-main .product-remove:before {
  margin-right: 0.5em;
}
.cart-main .product-total-price {
  font-size: 18px;
  text-align: right;
  color: #484848;
  font-weight: 600;
  margin-top: 36px;
}
.crt-cart-totals {
  padding: 25px 25px;
  background: #ebf7ff;
}
.cart-main .cart-totals ._column {
  line-height: 1.2;
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
}
.cart-main .crt-cart-totals .cart-totals-key {
  font-size: 17px;
  color: #484848;
}
.cart-main .cart-totals ._column:hover .cart-totals-value {
  color: #333;
}
.cart-main .cart-totals-value {
  font-size: 15px;
  font-weight: 600;
}
.cart-main ._column.checkout {
  text-align: right;
  padding: 0;
  margin-top: 1.5em;
  vertical-align: middle;
  border-bottom: none;
  flex-wrap: wrap;
}
.cart-main .checkout-btn:before {
  margin-right: 0.5em;
}
.cart-main .product-remove,
.cart-main .cart-totals * {
  transition: all 0.2s ease;
}
.cart-main .closing {
  transition: all 0.5s ease;
  transform: translate3d(0, -100%, 0);
  opacity: 0;
}
*,
.cart-main :before,
.cart-main :after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.cart-main img {
  display: block;
  max-width: 100%;
  height: auto;
}
.cart-main .group:after {
  content: "";
  display: table;
  clear: both;
}
.cart-main .ui-list,
.cart-main nav ul,
.cart-main nav ol,
.cart-main .grid-list {
  list-style: none inside;
  padding-left: 0;
  margin-bottom: 0;
}
.cart-main .grid-list > li {
  display: inline-block;
  vertical-align: top;
}
.cart-main .hidden {
  display: none;
}
.cart-main .inner-container {
  width: 98%;
  max-width: 70em;
  margin: 0 auto;
}
@media screen and (min-width: 30em) {
  .cart-main .inner-container {
      width: 92%;
  }
}
@media screen and (min-width: 60em) {
  .cart-main .inner-container {
      width: 80%;
  }
  body .cart-main .debug:before {
      content: "min-width: 60em fired";
      background-color: blue;
  }
}
@media screen and (min-width: 320px) {
  body .cart-main .debug:before {
      opacity: 0.2;
      position: fixed;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      text-align: center;
      color: #fff;
      content: "nothing fired!";
      background-color: #ffc0cb;
      padding: 0.25rem;
  }
}
@media screen and (min-width: 35em) {
  body .cart-main .debug:before {
      content: "min-width: 35em fired";
      background-color: orange;
  }
}
@media screen and (min-width: 46em) {
  body .cart-main .debug:before {
      content: "min-width: 46em fired";
      background-color: #ff0;
  }
}
@media screen and (min-width: 68em) {
  body .cart-main .debug:before {
      content: "min-width: 68em fired";
      background-color: green;
  }
}
@media screen and (min-width: 768px) {
  .crt-i-sticky {
      float: left;
      width: 100%;
  }
}
@supports (position: sticky) or (position: -webkit-sticky) {
  @media screen and (min-width: 768px) {
      .crt-i-sticky {
          position: -webkit-sticky;
          position: -moz-sticky;
          position: -o-sticky;
          position: -ms-sticky;
          position: sticky;
          top: 125px;
      }
  }
}
@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 1024px) {
  .crt-i-sticky {
      position: -webkit-inherit;
      position: -moz-inherit;
      position: -o-inherit;
      position: -ms-inherit;
      position: inherit;
  }
  .cart-main .cart-img-out {
      width: 30%;
  }
  .cart-main .crt-product-info {
      width: 70%;
  }
  .crt-product-desc {
      font-size: 12px;
      margin-top: 7px;
      margin-bottom: 10px;
  }
  .cart-main .product-qty {
      font-size: 12px;
  }
  .cart-main .crt-product-col ._btn-b {
      padding: 5px 10px;
  }
}
@media screen and (max-width: 767px) {
  .crt-left-main .crt-head-txt {
      text-align: center;
  }
  .crt-right-main {
      margin-top: 2px;
  }
  .cart-main .shopping-cart--list-item {
      display: block;
      text-align: center;
      border-bottom: 1px solid #e4e4e4;
      margin-bottom: 20px;
      padding-bottom: 20px;
  }
  .cart-main .cart-img-out {
      margin: 0 auto;
  }
  .cart-main .crt-product-info {
      width: 100%;
  }
  .cart-main .crt-product-info .product-remove {
      text-align: center;
  }
  .cart-main .crt-product-col {
      width: 200px;
      margin: 0 auto;
  }
  .cart-main .product-total-price {
      text-align: center;
      margin-top: 5px;
  }
  .cart-main .crt-product-col ._grid {
      justify-content: center;
      margin-top: 15px;
  }
  .cart-main .product-name {
      margin: 5px 0 10px;
  }
}
.rst-h-ban {
  position: relative;
  overflow: inherit;
  height: 295px;
  background: #000;
}
.rst-search-out {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 18%;
}
.rst-search-head {
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  color: #ffff;
  text-shadow: 1px 1px 3px #000;
  margin-bottom: 20px;
  text-transform: capitalize;
  position: relative;
  z-index: 999;
}
.rst-search-a {
  margin: 0 100px;
  position: relative;
  z-index: 999;
}
.select-wrapper select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.rst-search-input:hover,
.rst-search-input:focus {
  outline: 0;
  border-right: 1px #fff solid;
  border: 1px #e2213c solid;
}
.rst-search-input {
  display: flex;
  width: 100%;
  position: relative;
  border: 1px #c1c1c1 solid;
  border-left: 1px #fff solid;
  box-shadow: 0px 3px 6px #0000004d;
  background: #dcdcdc;
}
.rst-search-input .rst-search-drop {
  padding: 10px;
  margin: 0;
  display: none;
  position: absolute;
  min-height: 200px;
  top: 46px;
  left: -1px;
  right: -1px;
  z-index: 10;
  background: #fdfdfd;
  border: 1px solid;
  border-color: #cbcfe2 #c8cee7 #c4c7d7;
  background-image: -webkit-linear-gradient(top, #fdfdfd, #eceef4);
  background-image: -moz-linear-gradient(top, #fdfdfd, #eceef4);
  background-image: -o-linear-gradient(top, #fdfdfd, #eceef4);
  background-image: linear-gradient(to bottom, #fdfdfd, #eceef4);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.rst-search-input .rst-field-a:focus + .rst-search-drop {
  display: block;
}
.rst-search-input .rst-field-a {
  width: 80%;
  height: 45px;
  font-size: 14px;
  padding-left: 15px;
  border: 1px #fff solid;
}
.rst-search-input .rst-field-a:hover,
.rst-search-input .rst-field-a:focus {
  outline: 0;
  border: 1px #fff solid;
}
.rst-search-input .rst-field-a,
.rst-search-input .rst-field-b,
.select-wrapper select,
.rst-search-input,
.rst-search-input .rst-field-b {
  transition: all 0.4s linear;
}
.rst-search-input .rst-field-b {
  width: 21%;
  background: #e2213c;
  color: #fff;
  border: 1px #e8344d solid;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.rst-search-input .rst-field-b:hover,
.rst-search-input .rst-field-b:focus {
  outline: 0;
  border: 1px #c7283d solid;
  background: #c7283d;
}
.select-icon {
  position: relative;
  bottom: 52px;
  float: right;
  padding: 16px;
  height: 100%;
  border-right: none;
  pointer-events: none;
}



.rst-size-out-a {
  display: flex;
  flex-wrap: wrap;
}





.rst-size-out-b {
  display: flex;
  flex-wrap: wrap;
}

.rst-num-out {
  display: flex;
  width: 100%;
}
.rst-num-out .rst-input-number {
  width: 80px;
  padding: 0 12px;
  font-size: 15px;
  vertical-align: top;
  text-align: center;
  outline: none;
}
.rst-num-out .rst-input-number,
.rst-num-out .input-number-decrement,
.rst-num-out .input-number-increment {
  width: 60%;
  border: 1px solid #ccc;
  height: 40px;
  user-select: none;
}
.rst-num-out .input-number-decrement,
.rst-num-out .input-number-increment {
  display: inline-block;
  width: 20%;
  line-height: 38px;
  background: #f1f1f1;
  color: #444;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
.rst-num-out .input-number-decrement:active,
.rst-num-out .input-number-increment:active,
.rst-num-out .input-number-increment:focus,
.rst-num-out .input-number-decrement:focus,
.rst-num-out .input-number-increment:hover,
.rst-num-out .input-number-decrement:hover {
  transition: all 0.4s linear;
  outline: 0;
  background: #ddd;
}
.rst-num-out .input-number-decrement {
  width: 20%;
  border-right: none;
  border-radius: 4px 0 0 4px;
}
.rst-num-out .input-number-increment {
  border-left: none;
  border-radius: 0 4px 4px 0;
}
@media screen and (max-width: 1024px) {
  .rst-num-out .rst-input-number {
      font-size: 13px;
  }
  .rst-search-head {
      font-size: 20px;
      margin-bottom: 9px;
  }
  .rst-h-ban {
      height: 280px;
  }
}
@media screen and (max-width: 999px) {
  .rst-search-a {
      margin: 0 0;
  }
  .select-wrapper select {
      font-size: 10pt;
  }
  rst-search-input .rst-field-a {
      font-size: 12px;
  }
  .rst-search-head {
      font-size: 20px;
      margin-bottom: 12px;
  }
  .rst-search-input .rst-field-b {
      font-size: 11px;
  }
}
@media screen and (max-width: 767px) {
  .rst-search-a {
      flex-wrap: wrap;
  }
  .select-wrapper {
      height: 35px;
      min-width: 100%;
  }
  .select-icon {
      bottom: 36px;
      padding: 7px;
  }
  .rst-search-input {
      flex-wrap: wrap;
      width: 100%;
      background: none;
      border: none;
  }
  .rst-search-input .rst-field-a {
      width: 100%;
      height: 35px;
      font-size: 12px;
      margin-right: 0;
      margin-top: 3px;
      border: 1px #c1c1c1 solid;
  }
  .rst-search-input .rst-search-drop {
      top: 38px;
      left: 0px;
      right: 0px;
  }
  .rst-search-a {
      box-shadow: none;
  }
  .rst-search-input:hover,
  .rst-search-input:focus {
      border-right: none;
      border: none;
  }
  .rst-search-head {
      font-size: 15px;
  }
  .rst-search-input .rst-field-b {
      width: 100%;
      height: 35px;
      margin-top: 2px;
  }
  .rst-search-input .rst-field-a:hover,
  .rst-search-input .rst-field-a:focus {
      outline: 0;
      border: 1px #e2213c solid;
  }
}
@media screen and (max-width: 560px) {
  .rst-h-ban {
      height: 215px;
  }
}
.rom-str-a {
  display: flex;
  justify-content: flex-end;
  align-self: center;
  align-items: center;
  margin-bottom: 0;
  padding: 5px 0 5px 5px;
  margin-top: 5px;
}
.rom-str-a span {
  font-size: 12px;
  display: flex;
  color: #e8344d;
}
.rom-h-ban .owl-carousela .owl-item img {
  display: block;
  width: 100%;
  height: 295px;
}
.rom-h-ban {
  position: relative;
  overflow: inherit;
  height: 295px;
  background: #000;
}
.rom-str-a .star-6-points {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 0;
  height: 0;
  position: relative;
  border: 5px solid rgba(0, 0, 0, 0);
  border-top: 0 solid;
  border-bottom: 8px solid #e8344d;
  color: rgba(0, 0, 0, 1);
  -o-text-overflow: clip;
  text-overflow: clip;
  float: left;
  margin-top: -3px;
  margin-right: 3px;
}
.star-6-points::after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 0;
  height: 0;
  position: absolute;
  content: "";
  top: 2px;
  left: -5px;
  border: 5px solid rgba(0, 0, 0, 0);
  border-top: 8px solid #e8344d;
  border-bottom: 0 solid;
  color: rgba(0, 0, 0, 1);
  -o-text-overflow: clip;
  text-overflow: clip;
  text-shadow: none;
}
.prd-select input.rom-date {
  width: 100%;
  font-size: 14px;
  background: #e9e9e9;
  color: #000;
  padding: 6px 10px 7px 10px;
  border: 1px #d1d1d1 solid;
}
.prd-select input.rom-date:hover,
.prd-select input.rom-date:focus {
  outline: 0;
  border: 1px #454545 solid;
}
.rom-g-map iframe {
  width: 100%;
  height: 350px;
}
.rom-filter-in label {
  font-size: 13px;
}
.rom-filter-out {
  display: flex;
  justify-content: space-between;
}
.rom-filter-out .rom-filter-in {
  width: 50%;
  margin-right: 5px;
}
.rom-filter-out .rom-filter-in:last-child {
  margin-right: 0;
}
.rom-filter-out .rom-filter-in label {
  font-size: 13px;
  color: #fff;
  margin-left: 10px;
}
.rom-filter-out .rom-filter-in input.flt-sear-in-a {
  margin-bottom: 6px;
  height: 34px;
  background: #dbdbdb;
  padding-left: 10px;
}
@media screen and (max-width: 992px) {
  .rom-str-a {
      justify-content: center;
      margin-top: 0;
  }
  .rom-str-a .star-6-points {
      margin-top: -2px;
  }
  .rom-str-a span {
      font-size: 11px;
  }
  .flt-pro-box:hover .flt-cont-out {
      left: 0;
      right: 0;
  }
}
@media screen and (max-width: 767px) {
  .rom-filter-out .rom-filter-in label {
      font-size: 12px;
  }
}
@media screen and (max-width: 560px) {
  .rom-filter-out .rom-filter-in input.flt-sear-in-a {
      height: 30px;
  }
  .rom-h-ban .owl-carousela .owl-item img {
      display: block;
      width: 100%;
      height: 270px;
  }
  .rom-h-ban {
      height: 270px;
  }
}
.drs-search-a {
  display: flex;
  box-shadow: 0px 3px 6px #0000004d;
}
.drs-search-a .drs-filter-in {
  width: 35%;
}
.drs-search-a .rst-search-input {
  box-shadow: none;
}
.drs-select {
  width: 100%;
  height: 100%;
  background-color: #eee;
  position: relative;
}
.drs-select {
  background-color: #fff;
}
.drs-select select {
  width: 100%;
  height: 100%;
  font-size: 14px;
  border: none;
  position: relative;
  z-index: 2;
  padding: 0 10px 0 15px;
  background: #dbdbdb;
  border: 1px #dbdbdb solid;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.4s linear;
}
.drs-select select:hover,
.drs-select select:focus {
  outline: 0;
  border: 1px #e2213c solid;
}
.drs-select select option {
  background: #dbdbdb;
}
.drs-select:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 7px solid #868686;
  line-height: 20px;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 42%;
  right: 10px;
  z-index: 1;
  transition: all 0.4s linear;
}
.drs-select:hover:after {
  border-top: 7px solid #e2213c;
}
.drs-select-b {
  height: 39px;
  background-color: #dbdbdb;
}
.drs-select-b:hover {
  background-color: #fff;
}
.drs-select-b select {
  float: left;
  font-size: 13px;
  padding: 0 10px 0 10px;
  margin-bottom: 13px;
}
.drs-gallery-sample .cloud-zoom-wrap a {
  cursor: default;
}
.drs-gallery-sample .cloud-zoom-big,
.drs-gallery-sample .mousetrap {
  display: none !important;
}
.drs-pro-d-out .prd-select h2 {
  width: 100%;
}

.on-out-pro .job-rs-price .pro-rup {
  font-size: 12px;
  color: #a7a2a2 !important;
  margin-right: 2px;
}
.on-out-pro .job-rs-price span {
  font-size: 11px;
  color: #e8344d;
}
.drs-p-rgt {
  justify-content: flex-end;
}
@media screen and (max-width: 767px) {
  .drs-search-a .drs-filter-in {
      width: 100%;
      height: 35px;
  }
  .drs-select select {
      width: 100%;
      height: 100%;
      font-size: 12px;
  }
}
.jpr-cont-out .flt-cont-price .flt-p-out .flt-p {
  font-size: 12px;
}
.jpr-cont-out .flt-cont-price .flt-p-out .flt-p i {
  font-size: 14px;
  margin-right: 4px;
}
.jpr-cont-out .flt-cont-price .flt-p-out .flt-p span {
  margin-top: 2px;
}
.jpr-cont-out .on-shop-in {
  text-align: right;
}
.jpr-cont-out-b .on-shop-in {
  text-align: left;
}
.jpr-cont-out .on-shop-in i {
  font-size: 12px;
  color: #b2b2b2;
}
.jpr-cont-out .on-shop-in span {
  font-size: 12px;
  color: #b2b2b2;
}
.jpr-pri-out {
  display: flex;
}
.jpr-pri-out .jpr-p-rgt .jpr-p-off {
  font-size: 18px;
  font-weight: 600;
  color: #e8344d;
}
.jpr-pri-out .jpr-p-rgt .jpr-p-off i {
  color: #a6a6a6;
}
.jpr-pri-out .jpr-p-rgt .jpr-p-off span {
  font-size: 15px;
}
.jpr-p-rgt {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  align-self: center;
}
.jpr-pri-out .jpr-p-n i {
  color: #a6a6a6;
}
.jpr-pri-out .jpr-p-n span {
  font-size: 15px;
  font-weight: 600;
  color: #e8344d;
}
.jpr-p-rgt .jpr-p-n {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.jpr-pri-out .jpr-p-n-b i {
  color: #a6a6a6;
  font-size: 15px;
  margin-right: 3px;
}
.jpr-pri-out .jpr-p-n-b span {
  font-size: 13px;
  font-weight: 400;
  color: #a6a6a6;
}
.jpr-p-rgt .jpr-p-n-b {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.jpr-details-out h3 {
  width: 165px;
}
.jpr-gallery-sample {
  display: flex;
  height: 400px;
  padding: 3px;
  justify-content: center;
  align-items: center;
  border: 1px #e4e6e6 solid;
}
.jpr-gallery-sample img {
  max-width: 100%;
  max-height: 100%;
}
@media screen and (max-width: 767px) {
  .jpr-details-out h3 {
      width: 118px;
  }
}
@media screen and (max-width: 1024px) {
  .jpr-gallery-sample {
      height: 160px;
  }
}
@media screen and (max-width: 992px) {
  .jpr-cont-out-b .on-shop-in {
      text-align: center;
  }

}
.tvs-select {
  width: 100%;
  height: 34px;
  background-color: #eee;
  position: relative;
}
.tvs-select select {
  width: 100%;
  height: 100%;
  font-size: 14px;
  border: none;
  position: relative;
  z-index: 2;
  padding: 0 10px 0 15px;
  background: #dbdbdb;
  border: 1px #dbdbdb solid;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.4s linear;
}
.tvs-select select:hover,
.tvs-select select:focus {
  outline: 0;
  border: 1px #e2213c solid;
}
.tvs-select select option {
  background: #dbdbdb;
}
.tvs-select:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 7px solid #868686;
  line-height: 20px;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 42%;
  right: 10px;
  z-index: 1;
  transition: all 0.4s linear;
}
.tvs-select:hover:after {
  border-top: 7px solid #e2213c;
}
.rst-search-a .tvs-list-out {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.rst-search-a .tvs-list-out a {
  width: 90px;
  height: 90px;
  border-radius: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 7px;
  margin-bottom: 6px;
  box-shadow: 1px 3px 3px #000;
  border: 4px #fff solid;
  transition: all 0.4s linear;
}
.rst-search-a .tvs-list-out a i {
  font-size: 35px;
  color: #000;
}
.rst-search-a .tvs-list-out a:hover {
  text-decoration: none;
  border: 4px #a7a7a7 solid;
}
.tvs-search-out .rom-filter-in {
  position: relative;
}
.rom-filter-in .tvs-search-drop {
  padding: 10px;
  margin: 0;
  display: none;
  position: absolute;
  min-height: 200px;
  top: 64px;
  left: -1px;
  right: -1px;
  z-index: 10;
  background: #fdfdfd;
  border: 1px solid;
  border-color: #cbcfe2 #c8cee7 #c4c7d7;
  background-image: -webkit-linear-gradient(top, #fdfdfd, #eceef4);
  background-image: -moz-linear-gradient(top, #fdfdfd, #eceef4);
  background-image: -o-linear-gradient(top, #fdfdfd, #eceef4);
  background-image: linear-gradient(to bottom, #fdfdfd, #eceef4);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.rom-filter-in .tvs-sear-in:focus + .tvs-search-drop {
  display: block;
}
.tvs-min {
  font-size: 12px;
}
.tvs-main .h-pro-out .h-pro-out-a .h-pro-slide .on-out-pro .on-out-pro-img,
.tvs-main .h-spm-out-a .h-spm-slider .on-out-pro .on-out-pro-img,
.tvs-main .flt-tab-out .flt-pro-out .flt-pro-box .flt-pro-img {
  height: 160px;
}
@media screen and (max-width: 1024px) {
  .rst-search-a .tvs-list-out a i {
      font-size: 25px;
  }
  .rst-search-a .tvs-list-out a {
      width: 60px;
      height: 60px;
      border: 2px #fff solid;
  }
  .rst-search-a .tvs-list-out a:hover {
      border: 2px #a7a7a7 solid;
  }
  .tvs-main .h-pro-out .h-pro-out-a .h-pro-slide .on-out-pro .on-out-pro-img,
  .tvs-main .h-spm-out-a .h-spm-slider .on-out-pro .on-out-pro-img,
  .tvs-main .flt-tab-out .flt-pro-out .flt-pro-box .flt-pro-img {
      height: 130px;
  }
}
@media screen and (max-width: 560px) {
  .rst-search-a .tvs-list-out a {
      margin: 0 3px;
  }
  .tvs-select {
      height: 30px;
  }
  .tvs-select select {
      font-size: 13px;
  }
  .tvs-main .h-pro-out .h-pro-out-a .h-pro-slide .on-out-pro .on-out-pro-img,
  .tvs-main .h-spm-out-a .h-spm-slider .on-out-pro .on-out-pro-img,
  .tvs-main .flt-tab-out .flt-pro-out .flt-pro-box .flt-pro-img {
      height: 80px;
  }
}
