
.abt-ban85 {
  position: relative;
  min-height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
}
.abt-ban85 .abt-ban86 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-serlt-1{
  display:block;
}
.trdv-ser-sec1 .trdv-ser-sec1-3{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-column-gap: 30px;
}
.trdv-ser-sec1 .trdv-ser-sec1-3 img{
  max-width: 100%;
}
.trdv-ser-sec1 .trdv-ser-sec1-3 .trdv-ser-sec1-5{
  margin: 80px 0 0 0;
}
.trdv-ser-sec1 .trdv-ser-sec1-3 .trdv-ser-sec1-4 img{
  border-bottom-left-radius: 150px;
  overflow: hidden;
}
.trdv-ser-sec1 .trdv-ser-sec1-3 .trdv-ser-sec1-5 img{
  border-bottom-right-radius: 150px;
  border-bottom-left-radius: 150px;
  overflow: hidden;
}
.trdv-ser-sec1 .trdv-ser-sec1-3 .trdv-ser-sec1-6 img{
  border-bottom-right-radius: 150px;
  overflow: hidden;
}

.trdv-ser-sec2 .trdv-ser-sec2-5{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  padding: 80px 0 70px 50px;
}
.trdv-ser-sec2 .trdv-ser-sec2-5 .trdv-ser-sec2-10{
  display: flex;
  justify-content: flex-start;
}

.trdv-ser-sec2 .trdv-ser-sec2-5 .trdv-ser-sec2-10 .trdv-ser-sec2-6:hover .trdv-ser-sec2-8{
  text-decoration: overline;

}
.trdv-ser-sec2-8 {
  max-width: 180px;
}

.trdv-ser-sec2 .trdv-ser-sec2-5 .trdv-ser-sec2-6 .trdv-ser-sec2-7{
  width: 100px;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  transition: all 0.6s ease-in-out;
}
.trdv-ser-sec2 .trdv-ser-sec2-5 .trdv-ser-sec2-6:hover .trdv-ser-sec2-7{
  transform: rotate(90deg);
}
.trdv-ser-sec2 .trdv-ser-sec2-5 .trdv-ser-sec2-6 .trdv-ser-sec2-7 img{
  max-width:100%;
}
.trdv-ser-sec2 .trdv-ser-sec2-11{
    background: #ffeee1;
    border-bottom-left-radius: 125px;
    border-top-right-radius: 125px;
    margin: 20px 0px 0px 0px;
}
.trdv-ser-sec2 .trdv-ser-sec2-1 .trdv-ser-sec2-4{
    display: inline-block;
    padding: 12px 12px;
    border: 1px #999 solid;
    margin: 14px 0 0;
}
.trdv-ser-sec2 .trdv-ser-sec2-1 .trdv-ser-sec2-4:hover{
    color: #C05F30;
    border: 1px #C05F30 solid;
}
.trdv-ser-sec2 .trdv-ser-sec2-1 .trdv-ser-sec2-2a1{
    color: rgb(135 135 135);
}


@media screen and (max-width: 1024px) {
  .trdv-ser-sec2 .trdv-ser-sec2-5 {
    grid-template-columns: repeat(3,1fr);
  }
}

@media screen and (max-width: 767px) {
  .trdv-ser-sec2 .trdv-ser-sec2-5 {
    grid-template-columns: repeat(2,1fr);
  }
  .trdv-ser-sec1 .trdv-ser-sec1-2{
    font-size:30px;
    line-height: 40px;
  }
  .trdv-ser-sec1{
    padding-top: 50px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .trdv-ser-sec2{
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .trdv-ser-sec2 .trdv-ser-sec2-1{
    padding-right: 0;
  }
  .trdv-ser-sec2-3 .trdv-ser-sec2-1 .trdv-ser-sec2-3{
    font-size: 14px;
    line-height: 26px;
  }

  .trdv-ser-sec2 .trdv-ser-sec2-5 {
    padding: 24px 10px 20px;
  }
  .trdv-ser-sec2 .trdv-ser-sec2-5 .trdv-ser-sec2-10 {
    text-align: center;
    justify-content: center;
  }
  .trdv-ser-sec2 .trdv-ser-sec2-5 .trdv-ser-sec2-6 .trdv-ser-sec2-7 {
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }
  .trdv-ser-sec2 .trdv-ser-sec2-11 {
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
  }
  .trdv-ser-sec1 .trdv-ser-sec1-3 {
    grid-column-gap: 10px;
  }
  .trdv-ser-sec1 .trdv-ser-sec1-3 .trdv-ser-sec1-5 {
    margin: 0;
  }
}
