@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600&display=swap");
:root {
  --font-family: "Open Sans", sans-serif;
  --content-width: 2000px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --color-ackcent: #D52027;
  --dark-color: #141024;
  --text-color:rgba(20, 16, 36, 0.7);
  --light-color: #fff;
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable value-keyword-case */
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.content-inner {
  margin: 0 auto;
  max-width: 1430px;
  padding: 0px 20px;
}

.header {
  position: absolute;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  color: #fff;
}

.product {
  max-width: 1500px;
  margin: 0 auto;
}

.add-to-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 1px #C06700 solid;
  border-radius: 2px;
  --color: #fff;
  margin: 10px 0 0 0;
  position: relative;
  outline: none;
  font-size: 14px;
  padding: 10px 32px;
  font-weight: 500;
  line-height: 20px;
  box-shadow: 0 var(--s-y, 4px) var(--s-b, 12px) var(--shadow);
  color: var(--color);
  background: #C06700;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.pro-flt-main {
  display: block;
  margin-top: 10px;
}

.pro-flt-main .pro-spm-txt-m {
  position: relative;
}

.pro-flt-main .pro-spm-txt-m h1 {
  font-size: 28px;
  padding: 20px 0 20px 10px;
  text-align: left;
  text-transform: capitalize;
  font-weight: 500;
  font-family: "Crimson Pro", serif;
}

.pro-flt-main .pro-spm-txt-m a {
  font-size: 15px;
  color: #f15922;
  font-weight: 600;
  padding: 3px 14px 3px;
  border: none;
  background: none;
  position: absolute;
  right: 0;
  top: 21px;
  border-radius: 3px;
}

.pro-flt-main .pro-spm-txt-m a:hover {
  background: #fff;
  color: #203c8b;
  text-decoration: none;
}

.flt-right-main {
  display: block;
  height: 100%;
  background: #fff;
  border-radius: 4px;
  padding: 0 0 50px;
}

.product-nav .filter_data {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 5px;
  flex-wrap: wrap;
}

.flt-pro-box {
  display: grid;
  float: left;
  position: relative;
  border: 1px solid #D9D9D9;
}

.flt-pro-box:hover {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1215686275);
}

.flt-pro-out {
  display: flex;
  margin-bottom: 15px;
}

.flt-pro-img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  overflow: hidden;
  background: #f5f1ef;
}

.flt-pro-img:hover img {
  transform: scale(1.1);
}

.flt-pro-img img {
  max-width: 100%;
  max-height: 100%;
  transition: all 0.2s ease-in-out;
}

.flt-p-out {
  display: flex;
  align-items: center;
}

.flt-cont-out {
  margin-top: auto;
  display: block;
  padding: 18px 10px 10px 10px;
  position: relative;
  background: #fff;
  top: 0;
  z-index: 9;
}

.flt-pro-box:hover .flt-cont-out {
  display: block;
  left: 0;
  right: 0;
  z-index: 999;
  background: #fff;
  -webkit-transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
  -moz-transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
  -o-transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  -moz-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  -o-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.flt-cont-price {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
}

.flt-on-love {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
}

.flt-cont-price .flt-p {
  font-size: 18px;
  color: #212121;
  font-weight: 600;
  display: flex;
  margin-right: 10px;
  align-items: center;
}

.flt-cont-price .flt-p-dis-12 {
  font-size: 15px;
  color: #c3bfba;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-decoration: line-through;
}

.flt-cont-price .flt-p i {
  font-size: 15px;
  font-weight: 400;
}

.flt-cont-price .flt-p-n {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #cbcbcb;
}

.flt-cont-price .flt-p-n i {
  font-size: 10px;
  font-weight: 400;
}

.flt-cont-price .flt-p-n span {
  display: flex;
  align-items: center;
  text-decoration: line-through;
}

.flt-cont-price .on-love {
  font-size: 13px;
  color: #3f66d4;
  margin-bottom: 3px;
  align-items: center;
}

.flt-cont-price .on-love:hover {
  text-decoration: none;
}

.flt-cont-price .on-love:hover i,
.flt-cont-price .on-love.active i {
  color: #e8344d;
}

.flt-cont-price .on-love i {
  color: #a6a6a6;
  font-size: 11px;
  margin-right: 1px;
  border: 1px #a6a6a6 solid;
  border-radius: 100%;
  width: 21px;
  height: 20px;
  padding-left: 1px;
  display: inline-block;
  line-height: 20px;
  text-align: center;
  margin-right: 2px;
}

.flt-img-txt {
  font-size: 18px;
  color: #282d3b;
  font-weight: 400;
  line-height: 19px;
}

.flt-img-txt-b {
  font-size: 13px;
  color: #28bc2b;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 0;
}

.flt-img-txt-bb {
  font-size: 16px !important;
}

.flt-img-txt-out {
  display: block;
  margin: 0 0 10px 0;
  text-decoration: none;
}

.flt-img-txt-out:hover .flt-img-txt {
  color: #84868d;
}

.flt-img-txt-out:hover {
  text-decoration: none;
}

.flt-pro-box:hover .flt-pro-ho {
  visibility: visible;
  opacity: 1;
  display: block;
}

.flt-pro-ho {
  display: none;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  width: 100.2%;
  left: 0;
  right: 0;
  padding: 12px 10px 10px;
  background: #fff;
  z-index: 999;
  margin-top: 5px;
  transition: all 0.2s ease-in-out;
  border: 1px #c3c3c3 solid;
  margin-top: 10px;
}

.flt-pro-ho ul {
  padding-left: 18px;
  margin-bottom: 0;
}

.flt-pro-ho ul li {
  font-size: 12px;
  color: #000;
  margin-bottom: 6px;
  line-height: 17px;
}

.product-nav {
  margin: 0 0;
}

.fil-head-txt {
  font-size: 26px;
  margin: 0 0 10px 0;
  font-weight: 400;
  color: #212121;
  font-family: "Crimson Pro", serif;
}

.pro-vh12-3 {
  font-size: 16px;
  color: #484848;
}

.flt-right-main .pro-vh12-1 {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.flt-right-main .pro-vh12-4 {
  display: flex;
  align-items: center;
}

.flt-right-main .pro-vh12-4 .pro-vh12-5 .pro-vh12-6 {
  margin: 0 5px 0 0;
  color: #787878;
  font-size: 14px;
}

.flt-right-main .pro-vh12-4 .pro-vh12-5 .pro-vh12-7 {
  color: #787878;
  height: 35px;
  padding: 0 0px 0 7px;
  font-size: 15px;
  border: 1px solid #ddd;
}

.product-nav ul {
  display: block;
}

.product-nav ul li {
  float: left;
}

.product-nav .nav-tabs .nav-link {
  font-size: 13px;
  color: #000;
  font-weight: 400;
  border: none;
  border-bottom: 1px #ddd solid;
  padding: 0.5rem 10px;
}

.product-nav .nav-tabs .nav-link:hover {
  color: #2954cd;
}

.product-nav .nav-tabs {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  z-index: 9;
}

.product-nav .nav-tabs .nav-link.active {
  border-bottom: 1px #f15922 solid;
  color: #f15922;
}

.product-nav .tab-content {
  display: block;
  width: 100%;
  float: left;
}

.flt-accordion {
  width: 100%;
  max-width: 100%;
}

.flt-table tr td {
  padding-right: 0;
  padding-left: 0;
  padding: 5px 0;
  border-top: none;
}

.flt-table tr td label {
  margin-right: 7px;
}

.flt-accordion li {
  list-style: none;
}

.flt-accordion li ul li {
  border-bottom: none;
}

.flt-accordion .link {
  cursor: pointer;
  display: block;
  padding: 15px 0 15px 0;
  color: #4d4d4d;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.flt-accordion .link a {
  color: #4d4d4d;
}

#myUL li {
  display: flex;
}

#myUL li a {
  width: 70%;
}

#myUL li a label {
  width: 30%;
}

.flt-sear-in-a {
  display: flex;
  width: 100%;
  font-size: 13px;
  margin-bottom: 13px;
  color: #181818;
  border: 1px #dbdbdb solid;
  padding: 7px 10px 7px 31px;
  background: #dbdbdb url(../images/search-icon.png) no-repeat 10px 9px;
}

.flt-sear-in-a:hover,
.flt-sear-in-a:focus {
  transition: all 0.4s linear;
  outline: 0;
  border: 1px #e2213c solid;
  background: #fff url(../images/search-icon.png) no-repeat 10px 9px;
}

.flt-sear-in-a::placeholder {
  color: #000;
}

.flt-accordion li:last-child .link {
  border-bottom: 0;
}

.flt-accordion li i {
  position: absolute;
  top: 16px;
  left: 12px;
  font-size: 18px;
  color: #595959;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.flt-accordion li i {
  right: 3px;
  left: auto;
  font-size: 16px;
}

.flt-accordion li.open .link {
  color: #b63b4d;
}

.flt-accordion li.open .link a {
  font-size: 16px;
  color: #c03500;
}

.flt-accordion li.open i {
  color: #040404;
}

.flt-accordion li.open i.fa-chevron-down {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.no-data12 {
  font-size: 20px;
  color: #c1c1c1;
  font-weight: 400;
  text-align: center;
  padding: 140px 0;
}

.filter-ty12 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
}

.flt-accordion li.default .submenu {
  display: block;
}

.flt-accordion li.default .submenu li a {
  padding-left: 5px;
}

.submenu {
  display: none;
  font-size: 14px;
  padding: 10px 0;
}

.submenu-open {
  display: block;
}

.flt-see-all {
  display: block;
  text-align: right;
}

.flt-see-all a {
  font-size: 13px;
  color: #3f66d4 !important;
}

.flt-see-all a:hover {
  color: #3f66d4;
  text-decoration: underline;
}

.submenu a {
  font-size: 14px;
  font-weight: 500;
  display: block;
  text-decoration: none;
  color: #979797;
  margin: 0px 0 12px;
  padding-left: 27px;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.submenu a:hover {
  color: #212121;
  font-weight: 600;
  text-decoration: none;
}

.flt-price-out {
  padding-left: 0 !important;
}

.flt-con {
  display: block;
  position: relative;
  height: 25px;
  width: 25px;
  margin-bottom: 0;
  margin-right: 7px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flt-con input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #f5d6b2;
  border: 1px #e39d4b solid;
}

.flt-con input:checked ~ .checkmark {
  background-color: #C06700;
  border: 1px #C06700 solid;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.flt-con input:checked ~ .checkmark:after {
  display: block;
}

.flt-con .checkmark:after {
  left: 10px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.flt-check-out {
  display: flex;
  margin-bottom: 10px;
}

.flt-check-out span {
  align-items: center;
  font-size: 13px;
  display: flex;
}

#flt-toggle {
  display: none;
  width: 28px;
  height: 30px;
  margin-left: auto;
}

#flt-toggle span:after,
#flt-toggle span:before {
  content: "";
  position: absolute;
  left: 0;
  top: -9px;
}

#flt-toggle span:after {
  top: 9px;
}

#flt-toggle span {
  position: relative;
  display: block;
}

#flt-toggle span,
#flt-toggle span:after,
#flt-toggle span:before {
  width: 100%;
  height: 5px;
  background-color: #888;
  transition: all 0.3s;
  backface-visibility: hidden;
  border-radius: 2px;
}

#flt-toggle.on span {
  background-color: transparent;
}

#flt-toggle.on span:before {
  transform: rotate(45deg) translate(5px, 5px);
}

#flt-toggle.on span:after {
  transform: rotate(-45deg) translate(7px, -8px);
}

#flt-toggle.on + #flt-toggle-menu {
  opacity: 1;
  visibility: visible;
  display: block;
}

#flt-toggle-menu {
  position: relative;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.4s;
  display: block;
}

@media screen and (max-width: 1200px) {
  .flt-cont-out {
    padding: 10px 4px;
  }
  .flt-pro-box:hover .flt-cont-out {
    left: 2px;
    right: 2px;
  }
  .product-nav .filter_data {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 1024px) {
  #flt-toggle-menu {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  #flt-toggle {
    display: flex;
    width: auto;
    height: auto;
    margin-left: 0;
    text-decoration: none;
  }
  #flt-toggle:hover {
    text-decoration: none;
  }
  #flt-toggle span,
  #flt-toggle span:after,
  #flt-toggle span:before {
    width: 35px;
    height: 5px;
  }
  #flt-toggle h1 {
    font-size: 17px;
    margin-bottom: 0;
    display: inline-flex;
    margin-top: -8px;
    margin-left: 9px;
    color: #000;
  }
  .flt-left-main {
    display: block;
    margin-bottom: 17px;
    padding: 30px 0 5px;
    width: 100%;
    float: left;
  }
  .product-nav .nav-tabs {
    position: relative;
    margin-top: 0;
  }
  .flt-right-main {
    margin-top: 2px;
  }
  .flt-accordion li.default .submenu {
    display: none;
  }
  .flt-accordion {
    margin: 20px auto 0;
  }
}
@media screen and (max-width: 992px) {
  .foot-ab {
    padding: 0 0 80px;
  }
  .flt-cont-price .flt-p {
    font-size: 15px;
    display: inline-block;
  }
  .flt-cont-price .flt-p i,
  .flt-cont-price .flt-p-n i {
    font-size: 8px;
  }
  .flt-cont-price .flt-p-n {
    font-size: 13px;
    display: inline-block;
  }
  .flt-img-txt {
    font-size: 16px;
  }
  .flt-img-txt-b {
    font-size: 12px;
  }
  .flt-img-txt {
    margin-bottom: 0;
  }
  .flt-pro-ho ul li {
    font-size: 11px;
    margin-bottom: 3px;
    float: none;
  }
  .flt-cont-price .flt-p-n span {
    display: inline-block;
  }
  .flt-on-love {
    display: block;
    align-items: center;
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .pro-flt-main .pro-spm-txt-m h1 {
    font-size: 24px;
  }
  .flt-pro-img {
    height: 250px;
  }
}
@media screen and (max-width: 768px) {
  .product-nav .filter_data {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .lt-wo12 {
    display: block;
  }
  .le-wsh12 .le-wsh14 {
    display: flex;
    height: 320px;
    overflow: hidden;
  }
  .le-wsh12 .le-wsh14 img {
    position: absolute;
    top: 0;
  }
  .hp-fhk14 {
    justify-content: center;
  }
  .pop-dj12 ul.pop-dj13 {
    justify-content: center;
  }
  .pop-dj12 h2 {
    text-align: center;
  }
  .flt-pro-out {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .flt-pro-box:hover .flt-cont-out {
    position: relative;
    top: 0;
  }
  .flt-pro-box:hover .flt-pro-ho {
    display: none;
  }
  .flt-pro-box:nth-child(3) {
    border-left: none;
  }
  .flt-cont-price {
    display: block;
    margin-bottom: 4px;
  }
  .flt-img-txt-out {
    margin: 10px 0 10px;
  }
  .product-nav .nav-tabs .nav-link {
    font-size: 11px;
    padding: 0.5rem 4px;
  }
  .flt-pro-img {
    height: 150px;
  }
}
@media screen and (max-width: 560px) {
  .flt-img-txt {
    font-size: 14px;
  }
  .flt-cont-price .flt-p {
    font-size: 13px;
  }
  .flt-cont-price .flt-p-dis-12 {
    font-size: 11px;
  }
  .pro-vh12-3 {
    font-size: 13px;
  }
  .flt-right-main .pro-vh12-1 {
    display: block;
  }
  .flt-right-main .pro-vh12-1 .pro-vh12-2 {
    margin-bottom: 15px;
  }
  .flt-right-main .pro-vh12-4 .pro-vh12-5 .pro-vh12-6 {
    font-size: 12px;
  }
  .flt-right-main .pro-vh12-4 .pro-vh12-5 .pro-vh12-7 {
    font-size: 12px;
  }
}
.my-profile-main {
  padding-bottom: 50px;
}

.my-profile-left {
  height: 100%;
  background: #fdfbe9;
  border: 1px #e1ddb5 solid;
  border-radius: 4px;
  padding: 12px 12px 30px;
}

.my-profile-right {
  display: block;
  background: #fff;
  border: 1px #e0e0e0 solid;
  border-radius: 2px;
  padding: 22px 0 22px;
  letter-spacing: 0;
}

.flt-accordion li.open .link i {
  transform: rotate(90deg);
}

.my-pro-ac {
  padding: 0 30px;
}

.my-pro-ac form .form-group .pro-txt-ad {
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: #4a4a4a;
  margin-bottom: 15px;
}

.my-pro-ac form .form-group {
  margin-bottom: 50px;
}

.my-pro-ac form .form-group .pro-fld-ad {
  width: 50%;
  padding: 10px 10px;
  border: 1px #dcdcdc solid;
  background: #f9f9f9;
}

.my-pro-ac form .form-group .pro-btn-ad {
  background: #ffd669;
  color: #000;
  font-weight: 600;
  border: 1px #c5a95f solid;
  border-radius: 3px;
  padding: 6px 15px 7px 15px;
  width: 100px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.my-pro-ac form .form-group .pro-btn-ad:hover {
  background: #ffffff;
  border: 1px #c5c5c3 solid;
}

.my-pro-ac form .form-group .pro-btn-ad:focus {
  outline: 0;
  border: 1px #c5a95f solid;
}

.my-pro-ac form .form-group .pro-fld-ad:focus {
  outline: 0;
  border: 1px #adadad solid;
}

.my-pro-ac form .form-group .pro-fld-ad::placeholder {
  color: #ccc;
}

.my-name-txt {
  text-align: center;
  padding: 18px 0 22px;
  border-bottom: 1px #999999 solid;
}

.my-name-txt .my-name-pro {
  width: 70px;
  height: 70px;
  background: #ccc;
  max-width: 100%;
  border: 1px #afaeae solid;
  border-radius: 100%;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-name-txt .my-name-pro svg {
  width: 100%;
}

.my-name-txt .my-name-pro img {
  width: 100%;
}

.my-name-txt .my-name {
  font-size: 18px;
  font-weight: 600;
  margin-top: 8px;
  letter-spacing: normal;
}

.file-upload-profile12 {
  position: relative;
  display: inline-block;
}

.file-upload-profile12 .file-upload__label {
  display: block;
  padding: 4px 14px;
  font-size: 13px;
  font-weight: 600;
  border: 1px #dcdcdc solid;
  background: #f9f9f9;
  color: #f15922;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.file-upload-profile12 .file-upload__label:hover {
  border: 1px #f15922 solid;
}

.file-upload-profile12 .file-upload__input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 1;
  width: 0;
  height: 100%;
  opacity: 0;
}

.my-name-lnk {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 30px;
}

.my-name-lnk li {
  list-style: none;
}

.my-name-lnk li a {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 22px 15px;
  border-bottom: 1px #f2f2f2 solid;
}

.my-name-lnk li a:hover {
  text-decoration: none;
}

.my-name-lnk li a .sid-ul-nam {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 0;
}

.my-name-lnk li a:hover .sid-ul-nam {
  color: #c05f30;
}

.my-name-lnk li a.active .sid-ul-nam {
  color: #c05f30;
}

.my-name-lnk li a span {
  width: 12px;
}

.my-name-lnk li a span img {
  width: 100%;
}

.my-name-lnk li a span svg {
  fill: #ec1717;
}

.logout-ab {
  padding: 16px 15px;
  margin: 50px 0 0 0;
}

.logout-ab a {
  display: flex;
  align-items: center;
}

.logout-ab a:hover {
  text-decoration: none;
}

.logout-ab svg {
  width: 25px;
  height: 25px;
}

.logout-ab svg path {
  fill: #e8344d !important;
  transition: all 0.4s ease-in-out;
}

.logout-ab a:hover svg path {
  fill: #000 !important;
}

.combo-main15 {
  margin: 20px 0 15px;
}

.combo-out12 {
  text-align: center;
  margin-bottom: 15px;
}

.combo-out12 .combo-outimg13 {
  display: flex;
  width: 110px;
  height: 110px;
  margin: 0 auto;
  border-radius: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border: 1px #ccc solid;
}

.combo-out12 .combo-outtxt14 {
  font-size: 15px;
  font-weight: 600;
  margin-top: 5px;
  padding: 0 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.combo-out12 .combo-outimg13 img {
  max-width: 100%;
  max-height: 100%;
}

.logout-ab span {
  margin-left: 10px;
  color: #e8344d;
  font-weight: 600;
  transition: all 0.4s ease-in-out;
}

.logout-ab a:hover span {
  text-decoration: none;
  color: #000;
}

@media screen and (max-width: 767px) {
  .my-pro-ac form .form-group .pro-fld-ad {
    width: 100%;
  }
  .combo-out12 .combo-outimg13 {
    width: 80px;
    height: 80px;
  }
  .combo-out12 .combo-outtxt14 {
    padding: 0 0px;
  }
}
.mange-add-ac {
  padding: 0 30px;
}

.in-heda-ab {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 25px;
}

.add-add-ab .pro-txt-ad {
  display: block;
  font-size: 16px;
}

.add-add-ab .form-group {
  margin-bottom: 25px;
}

.add-add-ab .pro-txt-ad {
  margin-bottom: 12px;
}

.add-add-ab .pro-fld-ad {
  display: block;
  width: 100%;
  padding: 10px 10px;
  border: 1px #dcdcdc solid;
  background: #f9f9f9;
}

.add-add-ab .form-group .pro-btn-ad {
  background: #ffd669;
  color: #3e2200;
  font-weight: 600;
  border: 1px #c5a95f solid;
  border-radius: 3px;
  padding: 9px 15px 10px 15px;
  width: 100px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.add-add-ab .form-group .pro-btn-ad:hover {
  background: #ffffff;
  border: 1px #c5c5c3 solid;
}

.add-add-ab .form-group .pro-btn-cnl {
  background: #ffffff;
  border: 1px #c5c5c3 solid;
  color: #212121;
  font-weight: 600;
  border-radius: 3px;
  padding: 9px 15px 10px 15px;
  margin-left: 10px;
  width: 100px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.add-add-ab .form-group .pro-btn-cnl:hover {
  color: #946713;
  border: 1px #c5a95f solid;
}

.add-add-ab .form-group .pro-btn-ad {
  margin: 10px 0 0;
}

#showa {
  background: #ffd669;
  color: #212121;
  font-weight: 600;
  border: 1px #c5a95f solid;
  border-radius: 3px;
  padding: 8px 15px 9px 15px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-in-out;
  margin: 10px 0 0 0;
}

#showa:focus,
#hidea:focus {
  outline: 0;
  border: 1px #b9b9b9 solid;
}

#showa:hover {
  background: #ffffff;
  color: #000;
  border: 1px #b9b9b9 solid;
}

#hidea {
  float: right;
  background: #fff;
  color: #969696;
  border: 1px #c7c7c7 solid;
  padding: 7px 20px 7px 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

#hidea:hover {
  background: #ffffff;
  color: #000;
  border: 1px #b9b9b9 solid;
}

.modal-hdfg13 {
  border: none;
  background: #fff;
}

.modal-hdfg13:hover {
  color: #a5a5a5;
}

.mange-add-ac .add-del-ad {
  border: 1px #ccc solid;
  padding: 20px;
  margin-top: 30px;
}

.mange-add-ac .add-del-ad .add-ph-ae {
  font-size: 16px;
  font-weight: 500;
}

.mange-add-ac .add-del-ad .add-me-ae {
  font-size: 18px;
  font-weight: 600;
  margin: 15px 0 10px 0;
}

.mange-add-ac .add-del-ad .add-af {
  font-size: 15px;
  line-height: 22px;
  margin-top: 12px;
  margin-bottom: 0;
}

.add-del-ad .bt-radio-ab input {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  cursor: pointer;
}

.bt-radio-ab {
  color: #fff;
  background-color: #868e96;
  border-radius: 100%;
  width: 22px;
  height: 22px;
  padding: 0;
  border: 5px #d6d6d6 solid;
}

.bt-radio-ab.active {
  background: #488aef;
  border: 5px #cedbef solid;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.bt-radio-aa {
  display: block;
}

.bt-radio-aa .bt-radio-ab {
  float: right;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  padding: 0;
}

.noti-out-ab {
  display: block;
  margin-top: 20px;
}

.noti-in-ac {
  display: flex;
  width: 100%;
  margin: 10px 0;
}

.noti-in-ac .noti-in-ad {
  width: 100%;
  max-width: 80px;
  height: 80px;
  background: #cfdfe8;
  border-radius: 100%;
  overflow: hidden;
  justify-content: center;
  align-self: center;
  display: flex;
}

.noti-in-ac .noti-in-ad img {
  width: 100%;
}

.not-date-out {
  display: flex;
  justify-content: flex-end;
}

.not-date-out .not-date-ab {
  font-size: 13px;
  font-weight: 600;
}

.noti-in-ac .noti-in-ae {
  width: 100%;
  padding: 0 15px;
  border-bottom: 1px #e8e8e8 solid;
}

.noti-in-ae .not-had-ab {
  font-size: 20px;
  font-weight: 600;
}

.noti-in-ae .not-prg-ab {
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  color: #3a3a3a;
}

.not-fd-ban-a {
  display: block;
  background: #ececec;
  padding: 100px 0 200px;
}

.not-fd-ban-a img {
  max-width: 100%;
}

.not-fd-ban-ab {
  text-align: center;
}

.not-fd-ban-ab a {
  display: inline-block;
  background: #fff;
  color: #000;
  font-weight: 600;
  margin-top: 40px;
  padding: 12px 25px;
  border-radius: 7px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3607843137);
}

.not-fd-ban-ab a:hover {
  text-decoration: none;
  color: #305ad2;
}

.wsh-out-ab {
  display: block;
  margin-top: 20px;
}

.wsh-in-ac {
  display: flex;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px #e8e8e8 solid;
}

.wsh-in-ac:last-child {
  border-bottom: none;
}

.wsh-in-ac:hover {
  text-decoration: none;
}

.wsh-in-ac .wsh-in-ad {
  width: 100%;
  max-width: 100px;
  height: 100px;
  overflow: hidden;
  justify-content: center;
  align-self: center;
  display: flex;
}

.wsh-in-ac .wsh-in-ad img {
  max-width: 100%;
  max-height: 100%;
}

.wsh-in-ac .wsh-in-ae {
  width: 100%;
  padding: 0 15px;
}

.wsh-delete-out {
  display: flex;
  justify-content: flex-end;
}

.wsh-delete-out .wsh-delete-ab {
  font-size: 13px;
  background: #fff;
  border: #ddd;
  color: #7c7c7c;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.wsh-delete-out .wsh-delete-ab:hover {
  color: #c05f30;
}

.wsh-delete-out .wsh-delete-ab:focus {
  outline: 0;
  border: none;
}

.wsh-in-ac:hover .wsh-in-ae .wsh-had-ab {
  text-decoration: underline;
}

.wsh-in-ae .wsh-had-ab {
  font-size: 18px;
  margin-bottom: 13px;
  font-weight: 600;
  color: #212121;
}

.wsh-in-ac .pro-price {
  font-size: 18px;
  color: #c05f30;
  font-weight: 600;
}

.wsh-in-ac .pro-price .pro-price-in {
  font-size: 18px;
  font-weight: 700;
}

.wsh-in-ac .pro-price .pro-rup {
  font-size: 18px;
  font-weight: 300;
}

.pro-details-main {
  display: block;
  padding-bottom: 50px;
  padding-top: 8px;
  background: #fff;
}

.prd-left-main {
  height: 100%;
  background: #fff;
  padding: 0 0 30px;
}

.prd-right-main {
  display: block;
  height: 100%;
  background: #fff;
  padding: 5px 0 50px;
  position: relative;
}

.prd-rt-head {
  font-size: 30px;
  color: #484848;
  font-family: "Crimson Pro", serif;
}

.prd-pri-out {
  display: flex;
  align-items: flex-end;
}

.prd-pri-out .prd-pc {
  font-size: 14px;
  margin: 0 18px 2px 0;
  font-weight: 400;
}

.prd-pri-out .prd-pb {
  display: flex;
  padding-left: 12px;
  color: #a7a7a7;
  font-size: 21px;
  text-decoration: line-through !important;
}

.prd-pri-out .prd-p {
  display: flex;
  font-size: 24px;
  font-weight: 600;
  color: #484848;
}

.in-alfj12 {
  font-size: 12px;
  line-height: 1.2;
  font-weight: 500;
  display: block;
  padding-top: 10px;
  font-style: italic;
}

.prd-pri-out .prd-p i {
  font-size: 17px;
  align-self: center;
  font-weight: 400;
  align-items: center;
  display: inline-flex;
}

.off-oud1 {
  background: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1411764706);
  padding: 30px 20px 36px 20px;
  margin-left: 2px;
  margin-right: 2px;
}

.off-oud1 .off-oud1-2 .off-oud1-3 {
  color: #C06700;
  letter-spacing: 0.7px;
}

.off-oud1 .off-oud1-2 .off-oud1-4 li {
  padding-left: 28px;
  position: relative;
  line-height: 20px;
  margin-bottom: 14px;
}

.off-oud1 .off-oud1-2 .off-oud1-4 li::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background-image: url(../images/offer-icon.svg);
  background-size: 20px;
  background-repeat: no-repeat;
}

.ovrif1 .ovrif1-1 ul {
  padding-left: 20px;
}

.ovrif1 .ovrif1-1 ul li {
  list-style: disc;
  color: #707070;
}

.ovrif1 .ovrif1-1 ul li h6 {
  margin-bottom: 7px;
}

.prd-li-co-out {
  justify-content: flex-end;
  display: flex;
}

.deli-cfj1 {
  width: 100%;
  padding: 25px 20px 30px;
  background: #f7e9da;
}

.deli-cfj1 .deli-cfj1-3 {
  display: flex;
  border-bottom: 1px #ddbe9f solid;
}

.deli-cfj1 .deli-cfj1-3 input {
  width: 100%;
  background: none;
  padding: 8px 0 8px;
  border: none;
}

.deli-cfj1 .deli-cfj1-3 input:focus {
  outline: 0;
  border: none;
}

.deli-cfj1 .deli-cfj1-3 input::placeholder {
  font-size: 15px;
}

.deli-cfj1 .deli-cfj1-3 button {
  color: #c4601d;
  background: none;
  border: navajowhite;
  padding: 8px 0 8px;
}

.deli-cfj1 .deli-cfj1-4 {
  margin: 15px 0 0 0;
}

.deli-cfj1 .deli-cfj1-4 .deli-cfj1-5 {
  margin-bottom: 8px;
}

.deli-cfj1 .deli-cfj1-4 .deli-cfj1-6 {
  color: #d13c0f;
}

.benifgj1 .benifgj1-2 {
  display: grid;
  height: 100%;
  border-top-right-radius: 100%;
  border-top-left-radius: 100%;
  background: #f5e7d2;
  overflow: hidden;
}

.benifgj1 .benifgj1-2 .benifgj1-3 img {
  width: 100%;
}

.benifgj1 .benifgj1-2 .benifgj1-4 {
  padding: 32px 15px 35px;
}

.benifgj1 .row .col-lg-4:nth-child(4n+1) .benifgj1-2 {
  background: #f5e7d2;
}

.benifgj1 .row .col-lg-4:nth-child(4n+2) .benifgj1-2 {
  background: #e1e0c4;
}

.benifgj1 .row .col-lg-4:nth-child(4n+3) .benifgj1-2 {
  background: #c8d6e7;
}

.benifgj1 .row .col-lg-4:nth-child(4n+4) .benifgj1-2 {
  background: #e7d1c8;
}

.prd-li-co-out {
  position: relative;
}

.alert-su {
  width: 50%;
  top: 53px;
  right: 0;
  z-index: 999;
  font-size: 14px;
  position: absolute;
}

.alert-su .alert {
  display: flex;
}

.alert-su .alert .close {
  color: #212121;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  margin-right: 4px;
}

.prd-li-co-out .on-love {
  font-size: 15px;
  color: #203c8b;
  margin-left: 20px;
}

.prd-li-co-out .on-love:hover {
  text-decoration: none;
  color: #e8344d;
}

.prd-li-co-out .on-love:hover i {
  color: #e8344d;
}

.prd-li-co-out .on-love i {
  color: #a6a6a6;
  margin-right: 2px;
}

.prd-de-co-b a {
  width: 100%;
  color: #0056b3;
}

.prd-de-co-b p {
  width: 100%;
}

.prd-size {
  display: flex;
  margin-bottom: 15px;
}

.prd-size h2 {
  font-size: 15px;
  font-weight: 400;
  display: inline-flex;
  margin: 9px 25px 0 0;
}

.prd-details-out-b .prd-de-co-b p {
  font-size: 14px;
  line-height: 27px;
}

.prd-tab-con-in {
  margin-bottom: 29px;
}

.prd-tab-con-in .prd-tab-de-h {
  font-size: 15px;
  margin: 0 0 13px 19px;
}

.prd-tab-con-in .prd-tab-con-a {
  display: flex;
  background: #f8f8f8;
  padding: 11px 10px 11px 20px;
  margin-bottom: 2px;
}

.prd-tab-con-in .prd-tab-con-a h3,
.prd-tab-con-in .prd-tab-con-a h4,
.prd-tab-con-in .prd-tab-con-a p {
  width: 50%;
  font-size: 14px;
  margin-bottom: 0px;
}

.prd-tab-con-in .prd-tab-con-a p {
  word-wrap: break-word;
  font-weight: 600;
}

.prd-tab-con-in .prd-tab-con-a h4 {
  font-weight: 600;
}

.prd-rating-out {
  margin-top: 30px;
  padding: 30px 30px;
  border: 1px #bcbcbc solid;
}

.prd-rating-out .prd-rating-txt {
  font-size: 18px;
}

.prd-rating-out .prd-rating-in .prd-rating-in-a {
  width: 30%;
  float: left;
}

.prd-rating-out .prd-rating-in .prd-rating-in-b {
  width: 60%;
  float: right;
  padding-top: 70px;
}

.prd-rating-out .prd-rating-in .prd-rating-in-a .prd-rating-in-a-a {
  text-align: center;
}

.prd-rating-m form {
  display: block;
}

.prd-rating-m input[type=radio] {
  display: none;
}

.prd-rating-m label {
  color: grey;
  margin-bottom: 0;
  font-size: 30px;
}

.prd-rating-m .clasificacion {
  direction: rtl;
  unicode-bidi: bidi-override;
  margin-bottom: 0;
}

.prd-rating-m label:hover,
.prd-rating-m label:hover ~ label {
  color: orange;
  margin-bottom: 0;
}

.prd-rating-m input[type=radio]:checked ~ label {
  color: orange;
}

.prd-rating-in-a-b .prd-rating-in-a-c {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border: 3px #ccc solid;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.prd-rating-in-a-b .prd-rating-in-a-c a {
  font-size: 25px;
  font-weight: 600;
  color: #000;
}

.prd-progrees-rate-out {
  display: flex;
  margin-bottom: 5px;
}

.prd-progrees-rate-out .progress {
  width: 70%;
  height: 5px;
  align-self: center;
}

.prd-progrees-rate-out .progress .progress-bar {
  background: #e76814;
}

.prd-progrees-rate {
  width: 10%;
  font-size: 12px;
  align-self: center;
}

.prd-progrees-rate i {
  color: #e2e2e2;
}

.prd-at-rate {
  width: 20%;
  font-size: 12px;
  align-self: center;
  margin-left: 10px;
}

.prd-control-out {
  display: flex;
  margin-top: 40px;
}

.prd-control-out button {
  width: 35%;
  cursor: pointer;
  transition: all 0.2s linear;
}

.prd-control-out .prd-control-add {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 16px 5px 16px;
  border-radius: 4px;
  margin-right: 20px;
  color: #C06700;
  border: 1px #C06700 solid;
  background: #fff;
}

.prd-control-out .prd-control-add:hover,
.prd-control-out .prd-control-add:focus {
  outline: 0;
  color: #80ba28;
  border: 1px #80ba28 solid;
}

.prd-control-out .prd-control-buy {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  background: #C06700;
  padding: 16px 5px 16px;
  border-radius: 4px;
  border: 1px #C06700 solid;
}

.prd-control-out .prd-control-buy:hover,
.prd-control-out .prd-control-buy:focus {
  outline: 0;
  background: #d77b10;
  border: 1px #d77b10 solid;
}

.fixedsticky {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  z-index: 8;
}

.fixedsticky {
  top: 145px;
}

.fixedsticky-withoutfixedfixed .fixedsticky-off,
.fixed-supported .fixedsticky-off {
  position: static;
}

.fixedsticky-withoutfixedfixed .fixedsticky-on,
.fixed-supported .fixedsticky-on {
  position: fixed;
}

.fixedsticky-dummy {
  display: none;
}

.fixedsticky-on + .fixedsticky-dummy {
  display: block;
}

.cloud-zoom-lens {
  border: 4px solid #888;
  margin: -4px;
  background-color: #fff;
  cursor: move;
}

.cloud-zoom-title {
  position: absolute !important;
  background-color: #000;
  color: #fff;
  padding: 3px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 10px;
  top: 0px;
}

.cloud-zoom-wrap {
  top: 0;
  z-index: 9999;
  position: relative;
}

.cloud-zoom-big {
  border: 4px solid #ccc;
  overflow: hidden;
}

.cloud-zoom-loading {
  color: white;
  background: #222;
  padding: 3px;
  border: 1px solid #000;
}

div#colorbox {
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 9999;
}

div#cboxOverlay {
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

div#cboxContent {
  margin: 0 auto !important;
  float: none !important;
  height: 100% !important;
  text-align: center;
  width: 80% !important;
  position: relative;
}

div#cboxWrapper {
  width: 100% !important;
  height: 100% !important;
}

div#cboxMiddleLeft {
  position: absolute;
}

img.cboxPhoto {
  max-width: 100% !important;
  width: auto !important;
  height: auto !important;
}

div#cboxTitle {
  display: none !important;
}

button#cboxClose {
  position: absolute;
  right: -40px;
  top: 0px;
  font-size: 0;
  background: transparent;
  border: 0;
  height: 60px;
  cursor: pointer;
}

button#cboxClose:before {
  content: "";
  transform: rotate(45deg);
  height: 1px;
  width: 60px;
  background: #fff;
  display: block;
  margin-top: 0px;
}

button#cboxClose:after {
  content: "";
  transform: rotate(-45deg);
  height: 2px;
  width: 60px;
  background: #fff;
  display: block;
  margin-top: 0px;
}

div#cboxLoadedContent {
  text-align: center;
  width: 100% !important;
  position: relative;
}

.cloud-zoom-lens {
  border: 4px solid #888;
  margin: -4px;
  background-color: #fff;
  cursor: move;
}

.cloud-zoom-title {
  position: absolute !important;
  background-color: #000;
  color: #fff;
  padding: 3px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 10px;
  top: 0px;
}

.cloud-zoom-wrap {
  top: 0;
  z-index: 9999;
  position: relative;
}

.cloud-zoom-gallery-thumbs a.cloud-zoom-gallery:only-child {
  display: none;
}

.cloud-zoom-big {
  border: 4px solid #ccc;
  overflow: hidden;
  z-index: 999999999999999 !important;
}

.cloud-zoom-loading {
  color: white;
  background: #222;
  padding: 3px;
  border: 1px solid #000;
}

.prd-li-co-out {
  justify-content: flex-end;
  display: flex;
}

.prd-li-co-out .prd-size-radio input[type=checkbox] {
  display: none;
}

.prd-li-co-out .prd-size-radio input[type=radio] {
  display: none;
}

.prd-li-co-out .prd-size-radio label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  width: 37px;
  height: 37px;
  color: #C06700;
  border: 1px #C06700 solid;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  float: left;
  font-size: 17px;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
}

.prd-li-co-out .prd-size-radio label:hover {
  color: #fff;
  background: #C06700;
  border: 1px #C06700 solid;
}

.prd-li-co-out .prd-size-radio input[type=checkbox]:checked + label {
  color: #fff;
  background: #C06700;
  border: 1px #C06700 solid;
}

.prd-li-co-out {
  position: relative;
}

.basic-sample > div {
  width: 300px;
  height: 400px;
}

.gallery-sample {
  display: flex;
  padding: 3px;
  justify-content: center;
  align-items: center;
  border: 1px #e4e6e6 solid;
}

.gallery-sample .cloud-zoom-wrap a {
  padding: 3px;
  display: flex;
  height: 400px;
  padding: 3px;
  justify-content: center;
  align-items: center;
}

.gallery-sample a.cloud-zoom {
  position: relative;
  display: block;
}

.gallery-sample a.cloud-zoom img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
}

.recent_list {
  display: block;
  list-style: none;
  padding-left: 0;
}

.recent_list li {
  margin-bottom: 10px;
  border: 1px solid #ddd;
}

.recent_list li:hover {
  border: 1px #dd790e solid;
}

.recent_list li img {
  display: block;
  max-width: 100%;
  max-height: 60px;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .recent_list li {
    float: left;
    margin-right: 4px;
  }
  .gallery-sample .cloud-zoom-wrap a {
    height: 160px;
  }
  .recent_list li img {
    max-height: 35px;
  }
  .prd-control-out .prd-control-add,
  .prd-control-out .prd-control-buy {
    font-size: 13px;
    padding: 12px 5px 12px;
  }
  .cloud-zoom-big,
  .cloud-zoom-lens {
    display: none !important;
  }
  .gallery-sample a.cloud-zoom {
    cursor: default;
  }
  .prd-rt-head {
    font-size: 18px;
  }
  .prd-pri-out .prd-p {
    font-size: 19px;
  }
  .prd-pri-out .prd-p i {
    font-size: 15px;
  }
  .prd-select h2,
  .prd-size h2 {
    font-size: 12px;
    font-weight: 600;
  }
  .prd-size {
    justify-content: flex-end;
  }
  .prd-details-out-b .prd-de-co-b p,
  .prd-select select {
    font-size: 13px;
  }
  .prd-tab-con-in .prd-tab-de-h {
    font-size: 13px;
  }
  .prd-tab-con-in .prd-tab-con-a h3,
  .prd-tab-con-in .prd-tab-con-a h4 {
    font-size: 12px;
  }
  .prd-rating-out .prd-rating-txt {
    font-size: 15px;
    font-weight: 600;
  }
  .prd-left-main {
    padding: 0 0 24px;
  }
  .prd-right-main {
    padding: 5px 0 50px;
  }
  .mar-top40 {
    margin-top: 35px;
  }
  .prd-rating-in-a-b .prd-rating-in-a-c a {
    font-size: 20px;
  }
}
@media screen and (max-width: 991px) {
  .my-profile-right {
    margin: 12px 0 0 0;
  }
}
@media screen and (max-width: 767px) {
  .my-app-gfwout .my-app-df12 .my-app-df16 {
    display: block;
  }
  .my-app-gfwout .my-app-df12 .my-app-df16 .my-app-df19 {
    margin-bottom: 5px;
  }
  .my-app-gfwout .my-app-df12 .my-app-df16 .my-app-df19 .my-app-df17 {
    margin-bottom: 15px;
  }
  .prd-size {
    justify-content: flex-start;
  }
  .prd-rating-in-a-b .prd-rating-in-a-c {
    width: 125px;
    height: 125px;
  }
  .prd-rating-out .prd-rating-in .prd-rating-in-b {
    width: 60%;
    float: right;
    padding-top: 38px;
  }
  .prd-rating-out {
    margin-top: 2px;
  }
  .prd-li-co-out .on-love {
    font-size: 13px;
  }
  .off-oud1 .off-oud1-2 .off-oud1-3 {
    font-size: 20px;
  }
  .off-oud1 .off-oud1-2 .off-oud1-4 li {
    font-size: 13px;
  }
  .off-oud1 .off-oud1-2 .off-oud1-4 li::after {
    top: 2px;
    background-size: 16px;
  }
  .off-oud1 .off-oud1-2 .off-oud1-4 li {
    padding-left: 25px;
  }
  .deli-cfj1 .deli-cfj1-2 {
    margin-bottom: 6px;
    font-size: 20px;
  }
  .deli-cfj1 {
    margin-top: 10px;
  }
  .ovrif1 .ovrif1-1 h3 {
    font-size: 24px;
  }
  .benifgj1 .benifgj1-1 {
    font-size: 24px;
  }
  .benifgj1 .benifgj1-2 .benifgj1-4 {
    font-size: 18px;
    padding: 15px 15px 20px;
  }
  .pro-flt-main .pro-spm-txt-m h1 {
    padding: 20px 0 20px 0;
  }
  .off-oud1 {
    margin-top: 8px;
  }
  .benifgj1 .benifgj1-2 {
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
  }
}
@media screen and (max-width: 575px) {
  .prd-li-co-out .on-love {
    font-size: 12px;
  }
  .prd-rt-head {
    font-size: 16px;
  }
  .prd-pri-out .prd-p {
    font-size: 17px;
  }
  .prd-pri-out .prd-p i {
    font-size: 13px;
  }
  .prd-rating-out .prd-rating-in .prd-rating-in-a {
    width: 100%;
  }
  .prd-rating-out .prd-rating-txt {
    text-align: center;
  }
  .prd-rating-out .prd-rating-in .prd-rating-in-b {
    width: 100%;
  }
  .prd-at-rate {
    text-align: right;
  }
  .prd-progrees-rate {
    width: 20%;
  }
  .prd-select select {
    width: 100%;
  }
  .prd-tab-con-in .prd-tab-de-h {
    margin: 0 0 13px 10px;
  }
  .prd-tab-con-in .prd-tab-con-a {
    padding: 11px 11px 11px 11px;
  }
  .prd-control-out .prd-control-add,
  .prd-control-out .prd-control-buy {
    font-size: 11px;
  }
}
.cart-main {
  display: block;
  padding-bottom: 50px;
}

.crt-left-main {
  height: 100%;
  background: #fff;
  border-radius: 4px;
  padding: 30px 0 30px;
}

.crt-right-main {
  display: block;
  height: 100%;
  background: #fff;
  border-radius: 4px;
  padding: 30px 0 10px;
}

.crt-left-main .crt-head-txt {
  font-size: 22px;
  margin: 0 0 20px 0;
  padding-bottom: 20px;
}

.crt-right-main .crt-cart-totals .crt-head-sum-12 {
  font-size: 22px;
  margin: 0 0 20px 0;
  padding-bottom: 20px;
}

.cart-main .crt-product-col ._grid {
  text-align: justify !important;
  text-justify: distribute-all-lines;
  text-rendering: optimizespeed;
  display: flex;
}

.cart-main ._btn {
  display: inline-block;
  background-color: #e8344d;
  border: 1px #e8344d solid;
  color: #fff;
  padding: 5px 12px;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
}

.che-ck12 .checkout-btn {
  display: block;
  background-color: #1277b4;
  border: 1px #1277b4 solid;
  color: #fff;
  padding: 10px 12px 11px;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
}

.che-ck12 .checkout-btn:hover {
  text-decoration: none;
  background-color: #065687;
  border: 1px #065687 solid;
}

.cart-main ._btn:hover,
.cart-main ._btn:focus {
  outline: 0;
  background-color: #ffffff;
  color: #000;
  text-decoration: none;
}

.cart-main ._btn-b {
  display: block;
  background-color: #ebf7ff;
  border: 1px #333333 solid;
  color: #484848;
  padding: 10px 12px 11px;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
  margin-bottom: 10px;
}

.cart-main ._btn-b:hover,
.cart-main ._btn-b:focus {
  outline: 0;
  border: 1px #484848 solid;
  color: #fff;
  background: #484848;
  text-decoration: none;
}

.cart-main .shopping-cart {
  display: block;
}

.cart-main .shopping-cart--list-item {
  display: flex;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 30px;
  padding-bottom: 23px;
}

.cart-main .shopping-cart--list-item:last-child {
  border-bottom: none;
}

.cart-main .shopping-cart--list-item > ._column {
  letter-spacing: inherit;
  height: 100%;
}

.cart-main .cart-img-out {
  width: 15%;
  align-items: center;
}

.cart-main .cart-img-out img {
  max-width: 100%;
  max-height: 100%;
}

.cart-main .crt-product-info {
  width: 55%;
  padding: 5px 10px 0 20px;
}

.cart-main .product-name {
  font-size: 18px;
  color: #212121;
  margin: 0 0 15px;
}

.crt-product-desc {
  font-size: 13px;
  border: 1px #ccc solid;
  display: inline-block;
  padding: 2px 10px;
  line-height: 21px;
  margin-top: 10px;
}

.cart-main .crt-price {
  line-height: 1;
  font-size: 16px;
  color: #484848;
}

.cart-main .product-modifiers {
  text-align: right;
}

.cart-main .crt-product-col {
  margin-left: auto;
}

.cart-main .product-qty {
  width: 35px;
  background-color: transparent;
  color: #686868;
  text-align: center;
  align-self: center;
}

.cart-main .product-subtract,
.cart-main .product-plus {
  background-color: #ffffff;
  color: #000000;
  border: 1px #c1c1c1 solid;
  border-radius: 9px;
  font-size: 17px;
  cursor: pointer;
}

.shopping-cart--list-item:hover .product-subtract,
.shopping-cart--list-item:hover .product-plus {
  border: 1px #c1c1c1 solid;
}

.cart-main .product-plus:hover,
.cart-main .product-plus:focus,
.cart-main .product-subtract:hover,
.cart-main .product-subtract:focus {
  outline: 0;
  border: 1px #aeaeae solid;
  border-radius: 9px;
}

.cart-main .crt-product-info .product-remove {
  font-size: 13px;
  margin-top: 13px;
  background-color: #ffffff;
  color: #848484;
  padding: 0;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
}

.cart-main .crt-product-info .product-remove:hover,
.cart-main .crt-product-info .product-remove:focus {
  outline: 0;
  border: none;
  color: #000000;
}

.cart-main .product-modifiers:hover .product-remove {
  visibility: visible;
}

.cart-main .product-remove:before {
  margin-right: 0.5em;
}

.cart-main .product-total-price {
  font-size: 18px;
  text-align: right;
  color: #484848;
  font-weight: 600;
  margin-top: 36px;
}

.crt-cart-totals {
  padding: 25px 25px;
  background: #ebf7ff;
}

.cart-main .cart-totals ._column {
  line-height: 1.2;
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
}

.cart-main .crt-cart-totals .cart-totals-key {
  font-size: 17px;
  color: #484848;
}

.cart-main .cart-totals ._column:hover .cart-totals-value {
  color: #333;
}

.cart-main .cart-totals-value {
  font-size: 15px;
  font-weight: 600;
}

.cart-main ._column.checkout {
  text-align: right;
  padding: 0;
  margin-top: 1.5em;
  vertical-align: middle;
  border-bottom: none;
  flex-wrap: wrap;
}

.cart-main .checkout-btn:before {
  margin-right: 0.5em;
}

.cart-main .product-remove,
.cart-main .cart-totals * {
  transition: all 0.2s ease;
}

.cart-main .closing {
  transition: all 0.5s ease;
  transform: translate3d(0, -100%, 0);
  opacity: 0;
}

*,
.cart-main :before,
.cart-main :after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.cart-main img {
  display: block;
  max-width: 100%;
  height: auto;
}

.cart-main .group:after {
  content: "";
  display: table;
  clear: both;
}

.cart-main .ui-list,
.cart-main nav ul,
.cart-main nav ol,
.cart-main .grid-list {
  list-style: none inside;
  padding-left: 0;
  margin-bottom: 0;
}

.cart-main .grid-list > li {
  display: inline-block;
  vertical-align: top;
}

.cart-main .hidden {
  display: none;
}

.cart-main .inner-container {
  width: 98%;
  max-width: 70em;
  margin: 0 auto;
}

@media screen and (min-width: 30em) {
  .cart-main .inner-container {
    width: 92%;
  }
}
@media screen and (min-width: 60em) {
  .cart-main .inner-container {
    width: 80%;
  }
  body .cart-main .debug:before {
    content: "min-width: 60em fired";
    background-color: blue;
  }
}
@media screen and (min-width: 320px) {
  body .cart-main .debug:before {
    opacity: 0.2;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    color: #fff;
    content: "nothing fired!";
    background-color: #ffc0cb;
    padding: 0.25rem;
  }
}
@media screen and (min-width: 35em) {
  body .cart-main .debug:before {
    content: "min-width: 35em fired";
    background-color: orange;
  }
}
@media screen and (min-width: 46em) {
  body .cart-main .debug:before {
    content: "min-width: 46em fired";
    background-color: #ff0;
  }
}
@media screen and (min-width: 68em) {
  body .cart-main .debug:before {
    content: "min-width: 68em fired";
    background-color: green;
  }
}
@media screen and (min-width: 768px) {
  .crt-i-sticky {
    float: left;
    width: 100%;
  }
}
@supports (position: sticky) or (position: -webkit-sticky) {
  @media screen and (min-width: 768px) {
    .crt-i-sticky {
      position: -webkit-sticky;
      position: -moz-sticky;
      position: -o-sticky;
      position: -ms-sticky;
      position: sticky;
      top: 125px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .crt-i-sticky {
    position: -webkit-inherit;
    position: -moz-inherit;
    position: -o-inherit;
    position: -ms-inherit;
    position: inherit;
  }
  .cart-main .cart-img-out {
    width: 30%;
  }
  .cart-main .crt-product-info {
    width: 70%;
  }
  .crt-product-desc {
    font-size: 12px;
    margin-top: 7px;
    margin-bottom: 10px;
  }
  .cart-main .product-qty {
    font-size: 12px;
  }
  .cart-main .crt-product-col ._btn-b {
    padding: 5px 10px;
  }
}
@media screen and (max-width: 767px) {
  .crt-left-main .crt-head-txt {
    text-align: center;
  }
  .crt-right-main {
    margin-top: 2px;
  }
  .cart-main .shopping-cart--list-item {
    display: block;
    text-align: center;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .cart-main .cart-img-out {
    margin: 0 auto;
  }
  .cart-main .crt-product-info {
    width: 100%;
  }
  .cart-main .crt-product-info .product-remove {
    text-align: center;
  }
  .cart-main .crt-product-col {
    width: 200px;
    margin: 0 auto;
  }
  .cart-main .product-total-price {
    text-align: center;
    margin-top: 5px;
  }
  .cart-main .crt-product-col ._grid {
    justify-content: center;
    margin-top: 15px;
  }
  .cart-main .product-name {
    margin: 5px 0 10px;
  }
}
.rst-h-ban {
  position: relative;
  overflow: inherit;
  height: 295px;
  background: #000;
}

.rst-search-out {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 18%;
}

.rst-search-head {
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  color: white;
  text-shadow: 1px 1px 3px #000;
  margin-bottom: 20px;
  text-transform: capitalize;
  position: relative;
  z-index: 999;
}

.rst-search-a {
  margin: 0 100px;
  position: relative;
  z-index: 999;
}

.select-wrapper select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.rst-search-input:hover,
.rst-search-input:focus {
  outline: 0;
  border-right: 1px #fff solid;
  border: 1px #e2213c solid;
}

.rst-search-input {
  display: flex;
  width: 100%;
  position: relative;
  border: 1px #c1c1c1 solid;
  border-left: 1px #fff solid;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3019607843);
  background: #dcdcdc;
}

.rst-search-input .rst-search-drop {
  padding: 10px;
  margin: 0;
  display: none;
  position: absolute;
  min-height: 200px;
  top: 46px;
  left: -1px;
  right: -1px;
  z-index: 10;
  background: #fdfdfd;
  border: 1px solid;
  border-color: #cbcfe2 #c8cee7 #c4c7d7;
  background-image: -webkit-linear-gradient(top, #fdfdfd, #eceef4);
  background-image: -moz-linear-gradient(top, #fdfdfd, #eceef4);
  background-image: -o-linear-gradient(top, #fdfdfd, #eceef4);
  background-image: linear-gradient(to bottom, #fdfdfd, #eceef4);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.rst-search-input .rst-field-a:focus + .rst-search-drop {
  display: block;
}

.rst-search-input .rst-field-a {
  width: 80%;
  height: 45px;
  font-size: 14px;
  padding-left: 15px;
  border: 1px #fff solid;
}

.rst-search-input .rst-field-a:hover,
.rst-search-input .rst-field-a:focus {
  outline: 0;
  border: 1px #fff solid;
}

.rst-search-input .rst-field-a,
.rst-search-input .rst-field-b,
.select-wrapper select,
.rst-search-input,
.rst-search-input .rst-field-b {
  transition: all 0.4s linear;
}

.rst-search-input .rst-field-b {
  width: 21%;
  background: #e2213c;
  color: #fff;
  border: 1px #e8344d solid;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.rst-search-input .rst-field-b:hover,
.rst-search-input .rst-field-b:focus {
  outline: 0;
  border: 1px #c7283d solid;
  background: #c7283d;
}

.select-icon {
  position: relative;
  bottom: 52px;
  float: right;
  padding: 16px;
  height: 100%;
  border-right: none;
  pointer-events: none;
}

.rst-size-out-a {
  display: flex;
  flex-wrap: wrap;
}

.rst-size-out-b {
  display: flex;
  flex-wrap: wrap;
}

.rst-num-out {
  display: flex;
  width: 100%;
}

.rst-num-out .rst-input-number {
  width: 80px;
  padding: 0 12px;
  font-size: 15px;
  vertical-align: top;
  text-align: center;
  outline: none;
}

.rst-num-out .rst-input-number,
.rst-num-out .input-number-decrement,
.rst-num-out .input-number-increment {
  width: 60%;
  border: 1px solid #ccc;
  height: 40px;
  user-select: none;
}

.rst-num-out .input-number-decrement,
.rst-num-out .input-number-increment {
  display: inline-block;
  width: 20%;
  line-height: 38px;
  background: #f1f1f1;
  color: #444;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

.rst-num-out .input-number-decrement:active,
.rst-num-out .input-number-increment:active,
.rst-num-out .input-number-increment:focus,
.rst-num-out .input-number-decrement:focus,
.rst-num-out .input-number-increment:hover,
.rst-num-out .input-number-decrement:hover {
  transition: all 0.4s linear;
  outline: 0;
  background: #ddd;
}

.rst-num-out .input-number-decrement {
  width: 20%;
  border-right: none;
  border-radius: 4px 0 0 4px;
}

.rst-num-out .input-number-increment {
  border-left: none;
  border-radius: 0 4px 4px 0;
}

@media screen and (max-width: 1024px) {
  .rst-num-out .rst-input-number {
    font-size: 13px;
  }
  .rst-search-head {
    font-size: 20px;
    margin-bottom: 9px;
  }
  .rst-h-ban {
    height: 280px;
  }
}
@media screen and (max-width: 999px) {
  .rst-search-a {
    margin: 0 0;
  }
  .select-wrapper select {
    font-size: 10pt;
  }
  rst-search-input .rst-field-a {
    font-size: 12px;
  }
  .rst-search-head {
    font-size: 20px;
    margin-bottom: 12px;
  }
  .rst-search-input .rst-field-b {
    font-size: 11px;
  }
}
@media screen and (max-width: 767px) {
  .rst-search-a {
    flex-wrap: wrap;
  }
  .select-wrapper {
    height: 35px;
    min-width: 100%;
  }
  .select-icon {
    bottom: 36px;
    padding: 7px;
  }
  .rst-search-input {
    flex-wrap: wrap;
    width: 100%;
    background: none;
    border: none;
  }
  .rst-search-input .rst-field-a {
    width: 100%;
    height: 35px;
    font-size: 12px;
    margin-right: 0;
    margin-top: 3px;
    border: 1px #c1c1c1 solid;
  }
  .rst-search-input .rst-search-drop {
    top: 38px;
    left: 0px;
    right: 0px;
  }
  .rst-search-a {
    box-shadow: none;
  }
  .rst-search-input:hover,
  .rst-search-input:focus {
    border-right: none;
    border: none;
  }
  .rst-search-head {
    font-size: 15px;
  }
  .rst-search-input .rst-field-b {
    width: 100%;
    height: 35px;
    margin-top: 2px;
  }
  .rst-search-input .rst-field-a:hover,
  .rst-search-input .rst-field-a:focus {
    outline: 0;
    border: 1px #e2213c solid;
  }
}
@media screen and (max-width: 560px) {
  .rst-h-ban {
    height: 215px;
  }
}
.rom-str-a {
  display: flex;
  justify-content: flex-end;
  align-self: center;
  align-items: center;
  margin-bottom: 0;
  padding: 5px 0 5px 5px;
  margin-top: 5px;
}

.rom-str-a span {
  font-size: 12px;
  display: flex;
  color: #e8344d;
}

.rom-h-ban .owl-carousela .owl-item img {
  display: block;
  width: 100%;
  height: 295px;
}

.rom-h-ban {
  position: relative;
  overflow: inherit;
  height: 295px;
  background: #000;
}

.rom-str-a .star-6-points {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 0;
  height: 0;
  position: relative;
  border: 5px solid rgba(0, 0, 0, 0);
  border-top: 0 solid;
  border-bottom: 8px solid #e8344d;
  color: rgb(0, 0, 0);
  -o-text-overflow: clip;
  text-overflow: clip;
  float: left;
  margin-top: -3px;
  margin-right: 3px;
}

.star-6-points::after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 0;
  height: 0;
  position: absolute;
  content: "";
  top: 2px;
  left: -5px;
  border: 5px solid rgba(0, 0, 0, 0);
  border-top: 8px solid #e8344d;
  border-bottom: 0 solid;
  color: rgb(0, 0, 0);
  -o-text-overflow: clip;
  text-overflow: clip;
  text-shadow: none;
}

.prd-select input.rom-date {
  width: 100%;
  font-size: 14px;
  background: #e9e9e9;
  color: #000;
  padding: 6px 10px 7px 10px;
  border: 1px #d1d1d1 solid;
}

.prd-select input.rom-date:hover,
.prd-select input.rom-date:focus {
  outline: 0;
  border: 1px #454545 solid;
}

.rom-g-map iframe {
  width: 100%;
  height: 350px;
}

.rom-filter-in label {
  font-size: 13px;
}

.rom-filter-out {
  display: flex;
  justify-content: space-between;
}

.rom-filter-out .rom-filter-in {
  width: 50%;
  margin-right: 5px;
}

.rom-filter-out .rom-filter-in:last-child {
  margin-right: 0;
}

.rom-filter-out .rom-filter-in label {
  font-size: 13px;
  color: #fff;
  margin-left: 10px;
}

.rom-filter-out .rom-filter-in input.flt-sear-in-a {
  margin-bottom: 6px;
  height: 34px;
  background: #dbdbdb;
  padding-left: 10px;
}

@media screen and (max-width: 992px) {
  .rom-str-a {
    justify-content: center;
    margin-top: 0;
  }
  .rom-str-a .star-6-points {
    margin-top: -2px;
  }
  .rom-str-a span {
    font-size: 11px;
  }
  .flt-pro-box:hover .flt-cont-out {
    left: 0;
    right: 0;
  }
}
@media screen and (max-width: 767px) {
  .rom-filter-out .rom-filter-in label {
    font-size: 12px;
  }
}
@media screen and (max-width: 560px) {
  .rom-filter-out .rom-filter-in input.flt-sear-in-a {
    height: 30px;
  }
  .rom-h-ban .owl-carousela .owl-item img {
    display: block;
    width: 100%;
    height: 270px;
  }
  .rom-h-ban {
    height: 270px;
  }
}
.drs-search-a {
  display: flex;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3019607843);
}

.drs-search-a .drs-filter-in {
  width: 35%;
}

.drs-search-a .rst-search-input {
  box-shadow: none;
}

.drs-select {
  width: 100%;
  height: 100%;
  background-color: #eee;
  position: relative;
}

.drs-select {
  background-color: #fff;
}

.drs-select select {
  width: 100%;
  height: 100%;
  font-size: 14px;
  border: none;
  position: relative;
  z-index: 2;
  padding: 0 10px 0 15px;
  background: #dbdbdb;
  border: 1px #dbdbdb solid;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.4s linear;
}

.drs-select select:hover,
.drs-select select:focus {
  outline: 0;
  border: 1px #e2213c solid;
}

.drs-select select option {
  background: #dbdbdb;
}

.drs-select:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 7px solid #868686;
  line-height: 20px;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 42%;
  right: 10px;
  z-index: 1;
  transition: all 0.4s linear;
}

.drs-select:hover:after {
  border-top: 7px solid #e2213c;
}

.drs-select-b {
  height: 39px;
  background-color: #dbdbdb;
}

.drs-select-b:hover {
  background-color: #fff;
}

.drs-select-b select {
  float: left;
  font-size: 13px;
  padding: 0 10px 0 10px;
  margin-bottom: 13px;
}

.drs-gallery-sample .cloud-zoom-wrap a {
  cursor: default;
}

.drs-gallery-sample .cloud-zoom-big,
.drs-gallery-sample .mousetrap {
  display: none !important;
}

.drs-pro-d-out .prd-select h2 {
  width: 100%;
}

.on-out-pro .job-rs-price .pro-rup {
  font-size: 12px;
  color: #a7a2a2 !important;
  margin-right: 2px;
}

.on-out-pro .job-rs-price span {
  font-size: 11px;
  color: #e8344d;
}

.drs-p-rgt {
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  .drs-search-a .drs-filter-in {
    width: 100%;
    height: 35px;
  }
  .drs-select select {
    width: 100%;
    height: 100%;
    font-size: 12px;
  }
}
.jpr-cont-out .flt-cont-price .flt-p-out .flt-p {
  font-size: 12px;
}

.jpr-cont-out .flt-cont-price .flt-p-out .flt-p i {
  font-size: 14px;
  margin-right: 4px;
}

.jpr-cont-out .flt-cont-price .flt-p-out .flt-p span {
  margin-top: 2px;
}

.jpr-cont-out .on-shop-in {
  text-align: right;
}

.jpr-cont-out-b .on-shop-in {
  text-align: left;
}

.jpr-cont-out .on-shop-in i {
  font-size: 12px;
  color: #b2b2b2;
}

.jpr-cont-out .on-shop-in span {
  font-size: 12px;
  color: #b2b2b2;
}

.jpr-pri-out {
  display: flex;
}

.jpr-pri-out .jpr-p-rgt .jpr-p-off {
  font-size: 18px;
  font-weight: 600;
  color: #e8344d;
}

.jpr-pri-out .jpr-p-rgt .jpr-p-off i {
  color: #a6a6a6;
}

.jpr-pri-out .jpr-p-rgt .jpr-p-off span {
  font-size: 15px;
}

.jpr-p-rgt {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  align-self: center;
}

.jpr-pri-out .jpr-p-n i {
  color: #a6a6a6;
}

.jpr-pri-out .jpr-p-n span {
  font-size: 15px;
  font-weight: 600;
  color: #e8344d;
}

.jpr-p-rgt .jpr-p-n {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.jpr-pri-out .jpr-p-n-b i {
  color: #a6a6a6;
  font-size: 15px;
  margin-right: 3px;
}

.jpr-pri-out .jpr-p-n-b span {
  font-size: 13px;
  font-weight: 400;
  color: #a6a6a6;
}

.jpr-p-rgt .jpr-p-n-b {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.jpr-details-out h3 {
  width: 165px;
}

.jpr-gallery-sample {
  display: flex;
  height: 400px;
  padding: 3px;
  justify-content: center;
  align-items: center;
  border: 1px #e4e6e6 solid;
}

.jpr-gallery-sample img {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 767px) {
  .jpr-details-out h3 {
    width: 118px;
  }
}
@media screen and (max-width: 1024px) {
  .jpr-gallery-sample {
    height: 160px;
  }
}
@media screen and (max-width: 992px) {
  .jpr-cont-out-b .on-shop-in {
    text-align: center;
  }
}
.tvs-select {
  width: 100%;
  height: 34px;
  background-color: #eee;
  position: relative;
}

.tvs-select select {
  width: 100%;
  height: 100%;
  font-size: 14px;
  border: none;
  position: relative;
  z-index: 2;
  padding: 0 10px 0 15px;
  background: #dbdbdb;
  border: 1px #dbdbdb solid;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.4s linear;
}

.tvs-select select:hover,
.tvs-select select:focus {
  outline: 0;
  border: 1px #e2213c solid;
}

.tvs-select select option {
  background: #dbdbdb;
}

.tvs-select:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 7px solid #868686;
  line-height: 20px;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 42%;
  right: 10px;
  z-index: 1;
  transition: all 0.4s linear;
}

.tvs-select:hover:after {
  border-top: 7px solid #e2213c;
}

.rst-search-a .tvs-list-out {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.rst-search-a .tvs-list-out a {
  width: 90px;
  height: 90px;
  border-radius: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 7px;
  margin-bottom: 6px;
  box-shadow: 1px 3px 3px #000;
  border: 4px #fff solid;
  transition: all 0.4s linear;
}

.rst-search-a .tvs-list-out a i {
  font-size: 35px;
  color: #000;
}

.rst-search-a .tvs-list-out a:hover {
  text-decoration: none;
  border: 4px #a7a7a7 solid;
}

.tvs-search-out .rom-filter-in {
  position: relative;
}

.rom-filter-in .tvs-search-drop {
  padding: 10px;
  margin: 0;
  display: none;
  position: absolute;
  min-height: 200px;
  top: 64px;
  left: -1px;
  right: -1px;
  z-index: 10;
  background: #fdfdfd;
  border: 1px solid;
  border-color: #cbcfe2 #c8cee7 #c4c7d7;
  background-image: -webkit-linear-gradient(top, #fdfdfd, #eceef4);
  background-image: -moz-linear-gradient(top, #fdfdfd, #eceef4);
  background-image: -o-linear-gradient(top, #fdfdfd, #eceef4);
  background-image: linear-gradient(to bottom, #fdfdfd, #eceef4);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.rom-filter-in .tvs-sear-in:focus + .tvs-search-drop {
  display: block;
}

.tvs-min {
  font-size: 12px;
}

.tvs-main .h-pro-out .h-pro-out-a .h-pro-slide .on-out-pro .on-out-pro-img,
.tvs-main .h-spm-out-a .h-spm-slider .on-out-pro .on-out-pro-img,
.tvs-main .flt-tab-out .flt-pro-out .flt-pro-box .flt-pro-img {
  height: 160px;
}

@media screen and (max-width: 1024px) {
  .rst-search-a .tvs-list-out a i {
    font-size: 25px;
  }
  .rst-search-a .tvs-list-out a {
    width: 60px;
    height: 60px;
    border: 2px #fff solid;
  }
  .rst-search-a .tvs-list-out a:hover {
    border: 2px #a7a7a7 solid;
  }
  .tvs-main .h-pro-out .h-pro-out-a .h-pro-slide .on-out-pro .on-out-pro-img,
  .tvs-main .h-spm-out-a .h-spm-slider .on-out-pro .on-out-pro-img,
  .tvs-main .flt-tab-out .flt-pro-out .flt-pro-box .flt-pro-img {
    height: 130px;
  }
}
@media screen and (max-width: 560px) {
  .rst-search-a .tvs-list-out a {
    margin: 0 3px;
  }
  .tvs-select {
    height: 30px;
  }
  .tvs-select select {
    font-size: 13px;
  }
  .tvs-main .h-pro-out .h-pro-out-a .h-pro-slide .on-out-pro .on-out-pro-img,
  .tvs-main .h-spm-out-a .h-spm-slider .on-out-pro .on-out-pro-img,
  .tvs-main .flt-tab-out .flt-pro-out .flt-pro-box .flt-pro-img {
    height: 80px;
  }
}
.popup-staf {
  background: #fff !important;
  padding: 30px;
  width: 100%;
  border-radius: 10px;
}
@media (max-width: 768px) {
  .popup-staf {
    padding-top: 50px;
  }
}
@media (max-width: 576px) {
  .popup-staf {
    padding: 40px 10px 10px 10px;
  }
}
.popup-staf__close {
  right: 30px;
  top: 30px;
  color: #000;
}
@media (max-width: 768px) {
  .popup-staf__close {
    font-size: 12px;
  }
}
.popup-staf__top {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .popup-staf__top {
    gap: 5px;
  }
}
.popup-staf__image {
  border-radius: 10px;
  min-width: 120px;
  height: 180px;
}
@media (max-width: 768px) {
  .popup-staf__image {
    min-width: 80px;
    height: 120px;
  }
}
.popup-staf__about {
  margin-left: 10px;
  background-image: url("../img/card-decor-2.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
}
.popup-staf__name {
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
}
@media (max-width: 768px) {
  .popup-staf__name {
    font-size: 16px;
    line-height: 18px;
  }
}
.popup-staf__link {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 35px;
  background-color: #C06700;
  color: #fff;
  border-radius: 4px;
}
@media (max-width: 768px) {
  .popup-staf__link {
    font-size: 14px;
    width: 80px;
    height: 30px;
  }
}
@media (any-hover: hover) {
  .popup-staf__link:hover:hover {
    background-color: #fff;
    color: #C06700;
    transition: background-color 0.4s ease-in;
  }
}
.popup-staf__post {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(20, 16, 36, 0.7);
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .popup-staf__post {
    font-size: 14px;
    line-height: 16px;
  }
}
.popup-staf__button-content {
  padding: 20px 20px 0px 0px;
  max-height: 375px;
}
.popup-staf__descr {
  margin-bottom: 40px;
}
.popup-staf__text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
@media (max-width: 576px) {
  .popup-staf__text {
    font-size: 16px;
    line-height: 130%;
  }
}
.popup-staf__subtitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 160%;
}
@media (max-width: 576px) {
  .popup-staf__subtitle {
    font-size: 20px;
  }
}
.popup-staf .simplebar-track.simplebar-vertical {
  margin: 20px 0px;
  border-radius: 40px;
  background: #dedce2;
  width: 5px;
}
.popup-staf .simplebar-scrollbar::before {
  left: 0;
  right: 0;
  opacity: 1;
  width: 5;
  border-radius: 40px;
  background: #C06700;
}

.graph-modal__close {
  background-repeat: no-repeat;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  width: auto;
  height: auto;
  right: 20px;
  top: 0px;
  background-image: none;
  color: #fff;
  z-index: 100;
}

.popup {
  max-width: 500px;
  padding-top: 20px;
  background-color: transparent;
}

.modal-img {
  width: 500px !important;
  height: 650px !important;
}

@media (max-width: 768px) {
  .popup {
    max-width: 400px;
    padding: 20px;
    background-color: transparent;
  }
  .modal-img {
    width: 400px !important;
    height: 550px !important;
  }
}
.modal-content {
  border-radius: 5px;
  width: 100%;
  background-color: #fff;
  padding: 30px;
}

.order-modal__top {
  margin-bottom: 20px;
}
.order-modal__title {
  color: #C06700;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.order-modal__info {
  padding: 20px;
  width: 100%;
  min-height: 154px;
  border: 1px solid #D9D9D9;
  margin-bottom: 20px;
}
.order-modal__info-item {
  color: #808080;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
}
.order-modal__info-item:not(:last-child) {
  margin-bottom: 15px;
}
.order-modal__info-item span {
  color: #808080;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  text-align: left;
}
.order-modal__btn {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  background-color: #C06700;
  color: #fff;
  border-radius: 4px;
}

@media (max-width: 576px) {
  .modal-content {
    padding: 20px 10px;
  }
}
.trainers {
  position: relative;
  padding-top: 130px;
}
.trainers__content {
  margin: 0 auto;
  padding: 15px 0px;
  max-width: 1100px;
  position: relative;
}
.trainers__title {
  font-size: 38px;
  position: absolute;
  top: 30px;
  left: 50px;
  padding: 30px 0px;
}
@media (max-width: 768px) {
  .trainers__title {
    top: 0px;
    left: 20px;
    font-size: 22px;
  }
}
.trainers__link {
  position: absolute;
  right: 50px;
  top: 30px;
  padding: 40px 0px 30px 0px;
}
@media (max-width: 768px) {
  .trainers__link {
    top: 0px;
    right: 20px;
  }
}
.trainers__image {
  width: 250px;
  height: 300px;
  border-radius: 20px;
  margin-bottom: 20px;
}
.trainers__name {
  margin: 0;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 20px;
}
.trainers__post {
  margin: 0;
  max-width: 300px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-color);
}
.trainers__btn {
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: #C05F30;
  margin-bottom: 55px;
}
@media (max-width: 768px) {
  .trainers__btn {
    margin-bottom: 30px;
  }
}
.trainers__scroll {
  width: 360px;
  height: 5px;
  background: rgba(20, 16, 36, 0.08);
  border-radius: 40px;
  margin-bottom: 58px;
}
@media (max-width: 768px) {
  .trainers__scroll {
    margin-bottom: 30px;
  }
}
.trainers__nav {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
}
.trainers__btns {
  display: flex;
  gap: 5px;
}
.trainers .swiper-scrollbar-drag {
  background: #6c757d;
}
.trainers .swiper-button-prev {
  top: 37%;
  border: none;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-image: url("../img/tre-left-arrow.png");
  background-size: contain;
}
.trainers .swiper-button-prev::after {
  display: none;
}
.trainers .swiper-button-next {
  border: none;
  top: 37%;
  right: 30px;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-image: url("../img/tre-right-arrow.png");
  background-size: contain;
}
.trainers .swiper-button-next::after {
  display: none;
}
.trainers__staff-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
  cursor: pointer;
}
.trainers__staff-img {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 10px;
  left: 10px;
}

@media (max-width: 768px) {
  .swiper-wrapper {
    margin: 0 auto;
    max-width: 650px;
  }
  .trainers {
    padding-top: 60px;
  }
}
@media (max-width: 576px) {
  .swiper-wrapper {
    margin: 0 auto;
    max-width: 450px;
  }
  .trainers__image {
    width: 180px;
    height: 210px;
  }
  .trainers__name {
    font-size: 14px;
    line-height: 18px;
    max-width: 180px;
    margin-bottom: 5px;
  }
  .trainers__post {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 5px;
  }
  .swiper-button-prev {
    top: 37%;
    border: none;
    width: 25px !important;
    height: 25px !important;
    border-radius: 100%;
    background-image: url("../img/tre-left-arrow.png");
    background-size: contain;
  }
  .swiper-button-prev::after {
    display: none;
  }
  .swiper-button-next {
    border: none;
    top: 37%;
    right: 5px !important;
    width: 25px !important;
    height: 25px !important;
    border-radius: 100%;
    background-image: url("../img/tre-right-arrow.png");
    background-size: contain;
  }
  .swiper-button-next::after {
    display: none;
  }
}
.abt-ban85 {
  position: relative;
  min-height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
}

.abt-ban85 .abt-ban86 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.test-sec130 {
  background: #f5e8d9;
}

.test-sec130 .test-sec130-2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 75px;
  flex-wrap: wrap;
}

.test-sec130 .test-sec130-2 .test-sec130-3 {
  background: #fff;
  padding: 40px 31px 25px;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.1294117647);
}

.test-sec130 .test-sec130-2 .test-sec130-3 .test-sec130-5 .test-sec130-7 {
  position: relative;
}

.test-sec130 .test-sec130-2 .test-sec130-3 .test-sec130-4 {
  display: flex;
  align-items: center;
}

.test-sec130 .test-sec130-2 .test-sec130-3 .test-sec130-4 .test-sec130-5 {
  width: 55px;
  min-width: 55px;
  height: 55px;
  border-radius: 100%;
  border: 1px #ddd solid;
  overflow: hidden;
  margin-right: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.test-sec130 .test-sec130-2 .test-sec130-3 .test-sec130-5 .test-sec130-7 .test-sec130-10 {
  margin: -60px 0 0 -13px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
}

.test-sec130 .test-sec130-2 .test-sec130-3 .test-sec130-5 .test-sec130-7 .test-sec130-8 {
  margin-bottom: 25px;
}

.test-sec130 .test-sec130-2 .test-sec130-3 .test-sec130-5 .test-sec130-7 .test-sec130-10 img {
  width: 65px;
}

@media screen and (max-width: 991px) {
  .test-sec130 .test-sec130-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .test-sec130 .test-sec130-2 {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 50px;
  }
  .test-sec130 .test-sec130-1 {
    margin-bottom: 60px;
  }
}
.galry-sec131 .gelar-outdn {
  display: flex;
  background: rgb(192, 103, 0);
  background: linear-gradient(326deg, rgb(192, 103, 0) 0%, rgb(255, 239, 223) 100%);
}

.galry-sec131 .gelar-outdn:hover {
  background: rgb(192, 103, 0);
  background: linear-gradient(266deg, rgb(192, 103, 0) 0%, rgb(255, 239, 223) 100%);
}

.galry-sec131 .gelar-outdn .galry-sec131-2 {
  width: 50%;
  display: flex;
}

.galry-sec131 .galry-sec131-2 img {
  max-width: 100%;
}

.galry-sec131 .galry-sec131-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.galry-sec131 .galry-sec131-1 .galry-sec131-5 .galry-sec131-3 {
  color: #ffffff;
  text-shadow: 2px 2px 3px rgba(121, 73, 19, 0.431372549);
  margin-bottom: 0;
}

.abt-ban85 {
  position: relative;
  min-height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
}

.abt-ban85 .abt-ban86 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.galry-sec132 .galry-sec131 {
  margin-bottom: 50px;
}

.gary-dets133 .demo-gallery #lightgallery {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-wrap: wrap;
}

@media screen and (max-width: 991px) {
  .galry-sec131 .galry-sec131-1 .galry-sec131-5 .galry-sec131-3 {
    font-size: 24px;
    line-height: 30px;
  }
  .gary-dets133 .demo-gallery #lightgallery {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .galry-sec132 .galry-sec131 {
    margin-bottom: 20px;
  }
  .galry-sec132 {
    padding-top: 25px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
  }
  .galry-sec131 .galry-sec131-1 .galry-sec131-5 .galry-sec131-3 {
    font-size: 16px;
    line-height: 22px;
  }
  .galry-sec132 .galry-sec132-1 {
    font-size: 22px;
    margin-bottom: 25px;
    line-height: 26px;
  }
  .gary-dets133 {
    padding-bottom: 25px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .gary-dets134 {
    padding-top: 25px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .gary-dets133 .demo-gallery #lightgallery {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 0px;
  }
  .demo-gallery > ul > li {
    margin-bottom: 3px !important;
  }
  .gary-dets134 .gary-dets134-1 {
    font-size: 22px;
    margin-bottom: 25px;
    line-height: 26px;
  }
}
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.item {
  background-color: #FFF;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  	This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  	calculation of the height of the owl-item that breaks page layouts
   */
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

.slide-box {
  margin: 40px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slide-section {
  background-color: #fff !important;
}

.slide-text {
  color: #B68E62;
  font-size: 18px;
}

.slide-title {
  font-size: 38px;
  text-align: center;
  color: #B68E62;
}

.treatement-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 0px 20px;
}

@media (max-width: 768px) {
  .slide-box {
    margin-bottom: 20px;
  }
  .slide-title {
    font-size: 22px !important;
  }
  .slide-text {
    font-size: 12px;
  }
  .slide-section {
    padding-bottom: 10px !important;
    background-color: #fff !important;
  }
}
@media (max-width: 1200px) {
  .treatement-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 991px) {
  .treatement-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .treatement-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
body {
  overflow-x: hidden;
}

body.open, body:after {
  overflow: hidden;
}

.b-nav, body:after {
  position: absolute;
  right: 0;
}

.b-brand, .b-link {
  font-size: 18px;
  font-weight: 700;
  margin-left: 0;
  text-decoration: none;
  font-family: "Roboto Slab", serif;
  text-transform: uppercase;
}

@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(345px, 0, 0);
    transform: translate3d(345px, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}
@keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(345px, 0, 0);
    transform: translate3d(345px, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}
* {
  box-sizing: border-box;
}

body:after {
  background: #000;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  padding: 0;
  top: 0;
  visibility: hidden;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
  width: 100%;
}

body.open:after {
  z-index: 999;
  opacity: 0.65;
  height: 100000%;
  visibility: visible;
}

.b-nav {
  background: #fff none repeat scroll 0 0;
  position: fixed;
  top: 0;
  width: 320px;
  z-index: 9999999;
  height: 100%;
  overflow: auto;
}

.b-nav:not(.open) {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

.b-nav {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

.b-nav ul {
  padding-left: 0px;
}

.b-nav:not(.open) {
  visibility: hidden;
  animation-duration: 0.4s;
  animation-fill-mode: backwards;
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

.b-link {
  background: 0 0;
  border-left: rgba(255, 255, 255, 0) solid 2px;
  color: #212121;
  transition: all 0.4s ease;
  width: auto;
}

.b-link, .b-menu {
  -webkit-transition: all 0.4s ease;
}

/*.b-link--active,
.b-link:hover {
  border-left: #e00a12 solid 5px;
  padding-left: 30px
}*/
#menuheader .b-menu {
  cursor: pointer;
  display: block;
  height: 27px;
  position: absolute;
  top: 20px;
  transition: all 0.4s ease 0s;
  width: 27px;
  z-index: 999999999;
  right: 46px;
}

.b-bun--bottom, .b-bun--mid, .b-bun--top {
  height: 2px;
  width: 25px;
}

.b-bun {
  background: #7D7D7D;
  transition: all 0.4s ease;
}

#menuheader.menuactive .b-menu .b-bun {
  background: #1f8ecf;
}

#menuheader.menuactive .b-menu {
  top: 21px;
}

.b-container.open .b-menu {
  top: 4px !important;
  right: 25px !important;
}

.b-brand, .b-bun {
  position: relative;
  -webkit-transition: all 0.4s ease;
}

.b-bun--top {
  top: 0;
}

.b-bun--mid {
  top: 8px;
}

.b-bun--bottom {
  top: 16px;
}

.b-brand {
  color: #2196f3;
  top: -21.43px;
  transition: all 0.4s ease;
  z-index: 13;
}

.b-container {
  position: absolute;
  right: 0;
  top: 20px;
}

.b-container:hover:not(.open) .bun-bottom, .b-container:hover:not(.open) .bun-mid, .b-container:hover:not(.open) .bun-top {
  background: #2196f3;
}

.b-container.open .b-bun--top {
  background: #414141;
  top: 9px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.b-container.open .b-bun--mid {
  opacity: 0;
}

.b-container.open .b-bun--bottom {
  background: #414141;
  top: 5px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.b-container.open .b-brand {
  color: #fff;
}

.mobile-search-btn {
  background-color: #414141;
  border-radius: 4px;
  color: #fff;
  margin-bottom: 25px;
  margin-left: 30px;
  padding: 10px 20px;
  text-transform: uppercase;
  border: none;
}

.mobile-search {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #fff;
  color: #949494;
  font-size: 14px;
  margin-bottom: 20px;
  margin-left: 30px;
  padding: 10px;
  width: 80%;
}

.sign-fj85 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 0 0 0;
}

.sign-fj85 li {
  line-height: 22px;
  width: 50%;
}

.sign-fj85 li a {
  display: block;
  line-height: 22px;
  color: #1f8ecf;
  padding: 15px 0 15px 15px;
  background: #f2faff;
}

.sign-fj85 li a:hover {
  background: #e5f3fb;
}

@media screen and (max-width: 767px) {
  #menuheader .b-menu {
    top: 10px;
    right: 15px;
  }
  .b-nav {
    padding-top: 35px;
  }
}
.menu {
  height: auto;
  margin: 67px 0 0 0;
}

.menu__list {
  list-style-type: none;
  width: 100%;
  display: inline-block;
  background-color: #fff;
  margin: 0;
  padding: 13px 0 0;
}

.menu__item {
  color: #515151;
  display: block;
  width: 100%;
  height: 100%;
  padding: 13px 15px 13px;
  text-decoration: none;
  border-bottom: 1px solid #eee8e0;
  cursor: pointer;
}

.menu__item:hover {
  color: #c06700;
}

.menu__item--active {
  position: relative;
}

.menu__item--active:before {
  content: "";
  display: block;
  width: 1px;
  height: 1px;
  color: #000;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -7px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #c06700;
}

.menu__item--open:before {
  margin-top: -3px;
  border-top: 5px solid #c06700;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.menu__sublist {
  display: none;
  list-style-type: none;
  background-color: #f9efe3;
  margin: 0;
  padding: 0;
}

.menu__sublist .menu__sublist {
  background-color: #888;
}

.menu__sublist .menu__sublist .menu__sublist {
  background-color: #111;
}

.menu__subitem {
  list-style-type: none;
  display: block;
  color: #222222;
  text-decoration: none;
  padding: 13px 12px 13px;
  border-bottom: 1px solid #e1ceb8;
  cursor: pointer;
}

.sign-fj85 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 0 0 0;
}

.sign-fj85 li {
  line-height: 22px;
  width: 100%;
  text-align: center;
}

.sign-fj85 li a {
  display: block;
  line-height: 22px;
  color: #ffffff;
  padding: 15px 0 15px 15px;
  background: rgb(192, 103, 0);
  margin: 0 10px;
  border-radius: 6px;
}

.sign-fj85 li a:hover {
  background: rgb(163, 93, 12);
}

.spa__container {
  margin: 0 auto;
  max-width: 1400px;
  padding: 10px;
}
.spa__list {
  margin: 0 auto;
  max-width: 1000px;
  padding: 0px 15px;
  margin-top: 40px;
}
.spa__link {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 220px;
  height: 40px;
  border: 2px solid #9d640e;
  font-size: 16px;
  color: #9d640e;
  font-weight: 700;
}
.spa__right {
  max-width: 600px;
}
.spa__img {
  border-radius: 10px;
  object-fit: cover;
  width: 220px;
  height: 170px;
}
.spa__img-staff {
  width: 250px;
  height: 300px;
}
.spa__item {
  border-radius: 10px;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .spa__item {
    margin: 0 auto;
    flex-direction: column-reverse;
    align-items: center;
    max-width: 400px;
    gap: 0;
    margin-bottom: 20px;
  }
  .spa__link {
    margin-bottom: 20px;
    width: 400px;
  }
  .spa__img {
    width: 400px;
    height: 300px;
  }
}
@media (max-width: 400px) {
  .spa__item {
    max-width: 350px;
  }
  .spa__link {
    margin-bottom: 20px;
    width: 350px;
  }
  .spa__img {
    width: 350px;
    height: 230px;
  }
}
@media (max-width: 360px) {
  .spa__item {
    max-width: 320px;
  }
  .spa__link {
    margin-bottom: 20px;
    width: 320px;
  }
  .spa__img {
    width: 320px;
    height: 230px;
  }
}
.font-opensans {
  font-family: "Open Sans", sans-serif;
}

.font-ebgaramond {
  font-family: "EB Garamond", serif;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a:hover {
  transition: all 0.2s ease-in-out;
}

.color-white {
  color: #fff;
}

.color-black {
  color: #212121;
}

.color-black2 {
  color: #414141;
}

.color-brown {
  color: #C05F30;
}

.color-blue {
  color: #1799E8;
}

.color-gray-p {
  color: #828282;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-17 {
  font-size: 17px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-19 {
  font-size: 19px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-21 {
  font-size: 21px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-25 {
  font-size: 25px;
}

.font-size-26 {
  font-size: 26px;
}

.font-size-28 {
  font-size: 28px;
}

.font-size-30 {
  font-size: 30px;
}

.font-size-32 {
  font-size: 32px;
}

.font-size-34 {
  font-size: 34px;
}

.font-size-36 {
  font-size: 36px;
}

.font-size-38 {
  font-size: 38px;
}

.font-size-42 {
  font-size: 42px;
}

.line-height-18 {
  line-height: 18px;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-26 {
  line-height: 26px;
}

.line-height-28 {
  line-height: 28px;
}

.line-height-30 {
  line-height: 30px;
}

.line-height-33 {
  line-height: 33px;
}

.line-height-40 {
  line-height: 30px;
}

.line-height-50 {
  line-height: 50px;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.padding-right-0 {
  padding-right: 0px;
}

.padding-right-40 {
  padding-right: 40px;
}

.padding-right-50 {
  padding-right: 50px;
}

.padding-left-0 {
  padding-left: 0px;
}

.padding-left-20 {
  padding-left: 20px;
}

.padding-left-40 {
  padding-left: 40px;
}

.padding-top-100 {
  padding-top: 100px;
}

.padding-top-70 {
  padding-top: 70px;
}

.padding-top-60 {
  padding-top: 60px;
}

.padding-top-50 {
  padding-top: 50px;
}

.padding-bottom-100 {
  padding-bottom: 100px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.padding-bottom-60 {
  padding-bottom: 60px;
}

.padding-bottom-70 {
  padding-bottom: 70px;
}

.margin-top-100 {
  margin-top: 100px;
}

.margin-top-70 {
  margin-top: 70px;
}

.margin-top-60 {
  margin-top: 60px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-35 {
  margin-top: 35px;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-top-24 {
  margin-top: 24px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-bottom-100 {
  margin-bottom: 100px;
}

.margin-bottom-80 {
  margin-bottom: 80px;
}

.margin-bottom-70 {
  margin-bottom: 70px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-bottom-35 {
  margin-bottom: 35px;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.text-decoration-none {
  text-decoration: none;
}

.border-bottom-none {
  border-bottom: none !important;
}

.border-bt-lt-radius-50 {
  border-bottom-left-radius: 50px;
}

.border-bt-rt-radius-50 {
  border-bottom-right-radius: 50px;
}

body {
  font-family: "Open Sans", sans-serif;
  color: #5a5a5a;
}

.lang-box {
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 5px 0px;
}

.rus {
  display: block;
  padding: 5px;
  background-color: #80ba28;
  color: #fff;
  border-bottom-left-radius: 10px;
}

.eng {
  display: block;
  font-weight: 600;
  padding: 5px;
  background-color: #fff;
  color: #80ba28;
  border-top-right-radius: 10px;
}

.top-img-box {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 130px;
  height: 130px;
  float: left;
  margin: 5px 5px 0px 0px;
}

.top-img {
  border-radius: 3px;
  width: 110px;
  height: 110px;
}

.top-text {
  font-size: 12px;
  font-weight: 700;
  color: #000;
}

/*--- head start ---*/
.hmhd12 .hmhd12-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F9F2EC;
  padding-right: 48px;
}

.hmhd12 .hmhd12-1-wel {
  color: #9F5C00;
}

.ph-12d1 .ph-12d1-1 {
  display: block;
  display: flex;
  min-width: 150px;
  padding: 8px 14px 7px 14px;
  background: #80ba28;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 13px;
  border-bottom-left-radius: 13px;
  margin-left: 40px;
  margin-right: 20px;
}

.add-fp1 {
  margin-left: auto;
}

.add-fp1 .add-fp1-1 {
  display: flex;
  margin-right: 20px;
}

.add-fp1 .add-fp1-1 .add-fp1-2 {
  width: 14px;
  height: 20px;
}

.add-fp1 .add-fp1-1 .add-fp1-3 {
  margin-left: 5px;
}

.ph-12d1 .ph-12d1-1 .ph-12d1-3 {
  margin-left: 5px;
}

.ph-12d1 .ph-12d1-1 .ph-12d1-2 {
  width: 14px;
  animation-name: trin;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes trin {
  from {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  20%, 32%, 44%, 56%, 68% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  23%, 35%, 47%, 59%, 71% {
    transform: rotate3d(0, 0, 1, 15deg);
  }
  26%, 38%, 50%, 62%, 74% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  29%, 41%, 53%, 65%, 77% {
    transform: rotate3d(0, 0, 1, -15deg);
  }
  80% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
.faqfr12 {
  background: #f1fdf5;
}

.faqfr12 .faqfr12-1 {
  overflow: hidden;
  border-end-start-radius: 100px;
}

.faqfr12 .faqfr12-1 img,
.faqfr12 .faqfr12-2 img {
  width: 100%;
}

.faqfr12 .faqfr12-2 {
  overflow: hidden;
  border-start-end-radius: 100px;
}

.faqfr12 .faqfr13 {
  text-align: center;
  margin: 30px 0 0 0;
  position: relative;
}

.faqfr12 .faqfr13 .faqfr13-1 {
  color: #012207;
}

.faqfr12 .faqfr13 .faqfr13-2 {
  margin: 12px 0 0 0;
  color: #64a77c;
}

.faqfr12 .faqfr14 {
  margin: 50px 0 0 0;
}

.ayrvd-sec-12 {
  background: #f5f1f0;
}

.ayrvd-sec-12 .ayrvd-sec-12-1 img {
  max-width: 100%;
}

.spaf-sec-12 {
  border-bottom: 1px #e5c8a7 dashed;
}

.spaf-sec-12 .spaf-sec-12-1 img {
  max-width: 100%;
}

.spaf-sec-12 .spaf-sec-12-1 {
  height: 100%;
  display: flex;
  align-items: center;
}

.faqfr14 .accordion-button:not(.collapsed) {
  font-weight: 600;
  color: #206e3a !important;
  background-color: #d4ebdc !important;
}

.faqfr14 .accordion-body {
  font-size: 15px;
  line-height: 20px;
}

.faqfr14 .accordion-button:focus {
  z-index: 3 !important;
  border-color: initial !important;
  outline: 0 !important;
  box-shadow: initial !important;
}

.hmhd12 .hmhd12-2 {
  display: flex;
}

.hmhd12 .hmhd12-15 {
  padding-top: 27px;
  padding-bottom: 27px;
}

.hmhd12 .hmhd12-2 .hmhd12-3 {
  display: flex;
  flex: 1;
  align-items: center;
}

.dropdown {
  position: static;
}

.header-link {
  display: flex;
  gap: 5px;
}

.header-phone {
  margin-right: 15px;
}

.header-dropdown {
  max-width: 263px;
  position: absolute !important;
  top: 35px;
  right: 100px;
  z-index: 101;
}

.location {
  width: 18px;
  height: 25px;
}

.tube {
  width: 20px;
  height: 20px;
}

.btn-header {
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  font-size: 16px !important;
  color: #000;
}
.btn-header-phone {
  color: #fff;
}
.btn-header-footer {
  color: #000;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: start;
}

.dropdown-menu li {
  padding: 5px !important;
  display: flex;
}

.footer-dropdown {
  margin-bottom: 10px;
  font-size: 16px;
}

@keyframes glowing {
  0% {
    background-color: #2ba805;
    box-shadow: 0 0 3px #2ba805;
  }
  50% {
    background-color: #49e819;
    box-shadow: 0 0 10px #49e819;
  }
  100% {
    background-color: #2ba805;
    box-shadow: 0 0 3px #2ba805;
  }
}
.drop {
  z-index: 9999999 !important;
}

.dropwown-btn {
  animation: glowing 1300ms infinite;
  border-radius: 60px !important;
  border: none !important;
  font-family: sans-serif;
  font-size: 16px;
  padding: 7px 10px;
  color: #fff;
}

.dropdown-header-item {
  color: #828282 !important;
  font-size: 14px;
}

.dropdown-item {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  margin-right: 15px;
}

.program {
  background-color: #FFF8F0;
}

.hmhd12 .hmhd12-2 .hmhd12-3 .hmhd12-5 {
  margin-left: 32px;
  color: #fff;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Josefin Sans", sans-serif;
  background: linear-gradient(to right, #ad562b 10%, #80ba28 50%, #a3f357 60%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 1.4s linear infinite;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.hmhd12 .hmhd12-2 .hmhd12-3 .hmhd12-5:hover {
  color: rgb(219, 176, 128);
}

.hmhd12 .hmhd12-2 .hmhd12-6 {
  flex: 1;
}

.hmhd12 .hmhd12-2 .hmhd12-8 {
  flex: 1;
  display: flex;
  justify-content: end;
  align-items: center;
}

.hmhd12 .hmhd12-2 .hmhd12-8 .hmhd12-9 {
  display: flex;
  align-items: center;
}

.hmhd12 .hmhd12-2 .hmhd12-8 .hmhd12-9 .hmhd12-11 {
  position: relative;
  margin: 0 25px 0 23px;
}

.hmhd12 .hmhd12-2 .hmhd12-8 .hmhd12-9 .hmhd12-11 .hmhd12-12 {
  position: absolute;
  top: -6px;
  right: -10px;
  background: #C06700;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.hmhd12 .hmhd12-2 .hmhd12-8 .hmhd12-9 .hmhd12-13 {
  margin: 0 42px 0 0;
}

.hmhd12 .hmhd12-2 .hmhd12-8 .hmhd12-9 .hmhd12-10 {
  margin: 1px 0 0 0;
}

/* menu start */
.hmhd12 .hmhd12-16 {
  border-top: 1px #ECD5BA solid;
}

.hmhd12-16 nav {
  position: relative;
  z-index: 99;
}

.hmhd12-16 nav ul {
  display: flex;
  padding: 0px;
  margin: 0px;
  list-style: none;
  position: relative;
  justify-content: space-between;
  flex-wrap: wrap;
}

.hmhd12-16 nav ul li {
  display: inline-block;
}

.hmhd12-16 nav a {
  display: block;
  padding: 0px 10px;
  color: #C05F30;
  font-size: 14px;
  line-height: 60px;
  text-decoration: none;
}

.hmhd12-16 nav a:hover {
  background-color: rgb(252, 245, 238);
}

/* Hide Dropdowns by Default */
.hmhd12-16 nav ul ul {
  display: none;
  position: absolute;
  top: 60px; /* the height of the main nav */
}

/* Display Dropdowns on Hover */
.hmhd12-16 nav ul li:hover > ul {
  display: inherit;
}

/* Fisrt Tier Dropdown */
.hmhd12-16 nav ul ul li {
  width: 170px;
  float: none;
  display: list-item;
  position: relative;
  background: #c05f30;
}

.hmhd12-16 nav ul ul li a {
  line-height: 20px;
  color: #fff;
  padding: 10px 10px 10px;
  border-bottom: 1px #c3a887 solid;
}

.hmhd12-16 nav ul ul li a:hover {
  background: rgb(213, 120, 76);
}

/* Second, Third and more Tiers	*/
.hmhd12-16 nav ul ul ul li {
  position: relative;
  top: -60px;
  left: 170px;
}

/* Change this in order to change the Dropdown symbol */
.hmhd12-16 li > a:after {
  content: " +";
  display: none;
}

.hmhd12-16 li > a:only-child:after {
  content: "";
  display: none;
}

/* menu end */
/*--- head end ---*/
.main-sec13 .ban-sec14 {
  position: relative;
}

.ban-sec14 .hm-banner .owl-nav {
  position: absolute;
  top: 43%;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  margin: 0px 40px;
}

.ban-sec14 .hm-banner .owl-nav .owl-prev {
  width: 40px;
  height: 40px;
  background: url(../img/ban-arrow-left.svg) no-repeat center center;
}

.ban-sec14 .hm-banner .owl-nav .owl-prev:hover {
  background: url(../img/ban-arrow-left.svg) no-repeat center center;
  opacity: 0.6;
}

.ban-sec14 .hm-banner .owl-nav .owl-next {
  width: 40px;
  height: 40px;
  background: url(../img/ban-arrow-right.svg) no-repeat center center;
}

.ban-sec14 .hm-banner .owl-nav .owl-next:hover {
  background: url(../img/ban-arrow-right.svg) no-repeat center center;
  opacity: 0.6;
}

.ban-sec14 .hm-banner .owl-nav .owl-prev span,
.ban-sec14 .hm-banner .owl-nav .owl-next span {
  opacity: 0;
}

.main-sec13 .sli-sec15 .sli-sec15-1 {
  display: flex;
  align-items: flex-end;
}

.main-sec13 .sli-sec15 .sli-sec15-1 .sli-sec15-3 {
  color: #C06700;
  margin-left: auto;
  transition: all 0.2s ease-in-out;
}

.sli-sec17 {
  color: #C06700;
  font-size: 12px;
  text-align: right;
}

.main-sec13 .sli-sec15 .sli-sec15-1 .sli-sec15-3:hover {
  color: rgb(219, 176, 128);
}

.sli-sec15 .hm-best .owl-stage {
  display: flex;
}

.sli-sec15 .hm-best .owl-stage .owl-item {
  margin-bottom: 15px;
}

.sli-sec15 .hm-best .owl-stage .item {
  height: 100%;
}

.sli-sec15 .hm-best .owl-stage .item .caro-sec22 {
  height: 100%;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  overflow: hidden;
  margin: 0 4px 0 4px;
  transition: all 0.2s ease-in-out;
}

.caro-sec22:hover {
  background: #FCF8F3;
}

.caro-sec22 .caro-sec22-2 {
  padding: 21px 24px;
  margin-bottom: auto;
}

.caro-sec22 .caro-sec22-2 .caro-sec22-6 {
  color: rgb(72, 72, 72);
  line-height: 30px;
}

.ad-sec-19a .ad-sec-19-4 img {
  width: 100%;
}

.shopby-sec17 .shopby-sec17-1 .shopby-sec17-2 {
  color: #B68E62;
}

.shopby-sec17 .shopby-sec17-4 {
  display: block;
  border: 1px #EBEBEB solid;
  margin: 1px;
  padding: 35px 10px 50px;
  text-decoration: none;
  border-radius: 12px;
  transition: all 0.2s ease-in-out;
}

.shopby-sec17 .shopby-sec17-4 .shopby-sec17-5 {
  width: 150px;
  height: 150px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center center !important;
  background-size: cover !important;
  background: #f1ede4;
  border-radius: 100%;
  overflow: hidden;
  margin: 0px auto;
}

.shopby-sec17 .shopby-sec17-4:hover {
  background: #FCF8F3;
}

.shopby-sec17 .shopby-sec17-4:hover .shopby-sec17-6 {
  color: #9F3817;
}

.shopby-sec17 .shopby-sec17-4 .shopby-sec17-5 img {
  max-width: 100%;
  width: 100%;
}

.shopby-sec17 .shopby-sec17-4 .shopby-sec17-6 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 0 0;
}

.shopby-sec17 .shopby-sec17-1 {
  display: flex;
  align-items: flex-end;
}

.shopby-sec17 .shopby-sec17-1 .treatment-sec26-3 {
  color: #C06700;
  margin-left: auto;
  transition: all 0.2s ease-in-out;
}

.shopby-sec17 .shopby-sec17-1 .treatment-sec26-3:hover {
  color: rgb(219, 176, 128);
}

.shopby-sec17 .hm-cate-treatment {
  position: relative;
}

.shopby-sec17 .hm-cate-treatment .owl-stage {
  display: flex;
}

.shopby-sec17 .hm-cate-treatment .owl-stage .owl-item {
  margin-bottom: 3px;
}

.shopby-sec17 .hm-cate-treatment .owl-stage .owl-item .item,
.shopby-sec17 .hm-cate-treatment .owl-stage .owl-item .item .shopby-sec17-4 {
  height: 100%;
}

.shopby-sec17 .hm-cate-treatment .owl-nav {
  position: absolute;
  top: 43%;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  margin: 0px 15px;
}

.shopby-sec17 .hm-cate-treatment .owl-nav .owl-prev {
  position: absolute;
  left: -10px;
  top: 43%;
  width: 40px;
  height: 40px;
  background: url(../img/tre-left-arrow.png) no-repeat center center;
}

.shopby-sec17 .hm-cate-treatment .owl-nav .owl-prev:hover {
  background: url(../img/tre-left-arrow.png) no-repeat center center;
  opacity: 0.6;
}

.shopby-sec17 .hm-cate-treatment .owl-nav .owl-next {
  position: absolute;
  top: 43%;
  width: 40px;
  height: 40px;
  right: -10px;
  background: url(../img/tre-right-arrow.png) no-repeat center center;
}

.shopby-sec17 .hm-cate-treatment .owl-nav .owl-next:hover {
  background: url(../img/tre-right-arrow.png) no-repeat center center;
  opacity: 0.6;
}

.shopby-sec17 .hm-cate-treatment .owl-nav .owl-prev span,
.shopby-sec17 .hm-cate-treatment .owl-nav .owl-next span {
  opacity: 0;
}

.blog-sec-20 .blog-sec-20-5 {
  display: flex;
  align-items: flex-end;
}

.blog-sec-20 .blog-sec-20-5 .blog-sec-20-7 {
  color: #C06700;
  margin-left: auto;
  transition: all 0.2s ease-in-out;
}

.blog-sec-20 .blog-sec-20-5 .blog-sec-20-7:hover {
  color: rgb(219, 176, 128);
}

.blog-sec-20 .blog-sec-20-1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 36px;
  grid-row-gap: 43px;
  flex-wrap: wrap;
}

.blog-sec-20 .blog-sec-20-1 .blog-sec-20-2 {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  overflow: hidden;
}

.blog-sec-20 .blog-sec-20-1 .blog-sec-20-2 .blog-sec-20-3 img {
  max-width: 100%;
  width: 100%;
}

.blog-sec-20 .blog-sec-20-1 .blog-sec-20-2 .blog-sec-20-4 h3 {
  line-height: 35px;
}

.blog-sec-20 .blog-sec-20-1 .blog-sec-20-2 .blog-sec-20-4 p {
  color: rgb(112, 112, 112);
  margin: 30px 0 30px;
}

.blog-sec-20 .blog-sec-20-1 .blog-sec-20-2 .blog-sec-20-4 a:hover {
  color: #C05F30;
  text-decoration: underline !important;
}

.blog-sec-20 .blog-sec-20-1 .blog-sec-20-2 .blog-sec-20-4 {
  padding: 21px 24px 39px;
}

.foot-sec21 {
  background: #F8F8F8;
  padding-bottom: 70px;
}

.foot-sec21 .foot-sec21-1 h3,
.foot-sec21 .foot-sec21-5 h3,
.foot-sec21 .foot-sec21-15 h3 {
  margin: 0 0 20px 0;
}

.foot-sec21 .foot-sec21-8 h3 {
  margin: 0 0 15px 0;
}

.foot-sec21 .foot-sec21-15 .foot-sec21-16 iframe {
  max-width: 250px;
  width: 100%;
}

.foot-sec21 .foot-sec21-1 .foot-sec21-2 {
  display: flex;
  flex-wrap: wrap;
}

.foot-sec21 .foot-sec21-1 .foot-sec21-2 .foot-sec21-3 {
  margin: 0 60px 0 0;
}

.foot-sec21 .foot-sec21-1 .foot-sec21-2 .foot-sec21-3 li a,
.foot-sec21 .foot-sec21-1 .foot-sec21-2 .foot-sec21-4 li a,
.foot-sec21 .foot-sec21-5 .foot-sec21-6 .foot-sec21-7 li a {
  text-decoration: none;
  color: #5C5C5C;
  transition: all 0.2s ease-in-out;
}

.foot-sec21 .foot-sec21-1 .foot-sec21-2 .foot-sec21-3 li a:hover,
.foot-sec21 .foot-sec21-1 .foot-sec21-2 .foot-sec21-4 li a:hover,
.foot-sec21 .foot-sec21-5 .foot-sec21-6 .foot-sec21-7 li a:hover {
  color: #212121;
}

.foot-sec21 .foot-sec21-1 .foot-sec21-2 .foot-sec21-3 li,
.foot-sec21 .foot-sec21-1 .foot-sec21-2 .foot-sec21-4 li,
.foot-sec21 .foot-sec21-5 .foot-sec21-6 .foot-sec21-7 li {
  margin: 0 0 17px 0;
}

.foot-sec21 .foot-sec21-8 .foot-sec21-9 .foot-sec21-11 {
  color: #5C5C5C;
  line-height: 30px;
}

.foot-sec21 .foot-sec21-8 .foot-sec21-9 .foot-sec21-10 li a {
  display: flex;
  align-items: center;
}

.foot-sec21 .foot-sec21-8 .foot-sec21-9 .foot-sec21-10 li a {
  color: #5C5C5C;
}

.foot-sec21 .foot-sec21-8 .foot-sec21-9 .foot-sec21-10 li a .foot-sec21-14 {
  display: flex;
  align-items: center;
  margin: 0 5px 0 0;
}

.foot-sec21 .foot-sec21-8 .foot-sec21-9 .foot-sec21-11 {
  margin-bottom: 16px;
}

.foot-sec21 .foot-sec21-8 .foot-sec21-9 .foot-sec21-10 li {
  margin-bottom: 12px;
}

.foot-sec21 .foot-sec21-15 {
  float: right;
}

.foot-sec21 .foot-sec21-17 {
  text-align: center;
  margin: 60px 0 40px;
}

.foot-sec21 .foot-sec21-17 h6 {
  margin: 0 0 20px 0;
}

.foot-sec21 .foot-sec21-17 .foot-sec21-18 {
  display: flex;
  justify-content: center;
}

.foot-sec21 .foot-sec21-17 .foot-sec21-18 li {
  margin: 0 8px;
}

.foot-sec21 .foot-sec21-19 {
  color: #7C7C7C;
  margin-bottom: 25px;
}

.foot-sec21 .foot-sec21-20, .foot-sec21 .foot-sec21-20 a {
  color: #7C7C7C;
}

.ayurtre-sec23 {
  background: #FFF8F0;
  padding-top: 60px;
  padding-bottom: 60px;
}

.ayurtre-sec23 .ayurtre-sec23-1 img {
  max-width: 100%;
}

.ayurtre-sec23 .ayurtre-sec23-2 {
  padding-left: 100px;
}

.ayurtre-sec23 .ayurtre-sec23-2 .ayurtre-sec23-3 {
  color: #B86F1E;
}

.ayurtre-sec23 .ayurtre-sec23-2 .ayurtre-sec23-4 {
  width: 80%;
  color: #BD8D55;
}

.unikrish-k12 {
  background: #f7e5d1;
  padding-top: 60px;
  padding-bottom: 60px;
}

.unikrish-k12 .unikrish-k12-2 img {
  max-width: 100%;
}

.unikrish-k12 .unikrish-k12-1 {
  padding-left: 100px;
}

.unikrish-k12 .unikrish-k12-1 .ayurtre-sec23-3 {
  color: #B86F1E;
}

.unikrish-k12 .unikrish-k12-1 .unikrish-k12-1-2 {
  width: 80%;
  color: #a9753a;
}

.world-sec124 .world-sec124-1 .world-sec124-3 {
  color: #7D7D7D;
}

.world-sec124 .world-sec124-4 {
  display: flex;
  justify-content: center;
}

.world-sec124 .world-sec124-4 img {
  max-width: 100%;
}

@media screen and (max-width: 1199px) {
  .hmhd12-16 nav ul {
    justify-content: flex-start;
  }
  .ayurtre-sec23 .ayurtre-sec23-2 {
    padding-left: 30px;
  }
}
@media screen and (max-width: 1024px) {
  .shopby-sec17 .shopby-sec17-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .shopby-sec17 .shopby-sec17-3 .shopby-sec17-4 .shopby-sec17-6 {
    font-size: 20px;
  }
}
@media screen and (max-width: 991px) {
  .btn-footer {
    font-size: 14px !important;
  }
  .ayurtre-sec23 .ayurtre-sec23-2 .ayurtre-sec23-4 {
    width: 100%;
  }
  .blog-sec-20 .blog-sec-20-1 {
    grid-template-columns: repeat(2, 1fr);
  }
  .shopby-sec17 .shopby-sec17-3 {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}
@media screen and (max-width: 768px) {
  .unikrish-k12 .unikrish-k12-1 {
    padding-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .hmhd12 .hmhd12-2 .hmhd12-3 {
    display: none;
  }
  .lang-box {
    gap: 5px;
    margin: 5px 0px;
  }
  .header-dropdown {
    position: absolute;
    top: 25px;
    right: 60px;
    z-index: 101;
  }
  .hmhd12 .hmhd12-2 {
    flex: 0;
  }
  .hmhd12 .hmhd12-2 .hmhd12-6 .hmhd12-7 img {
    width: 170px;
  }
  .ayurtre-sec23 .ayurtre-sec23-1 img {
    max-width: 50%;
  }
  .ayurtre-sec23 .ayurtre-sec23-1 {
    margin-bottom: 25px;
  }
  .ayurtre-sec23 .ayurtre-sec23-2 {
    padding-left: 0;
  }
  .ayurtre-sec23 .ayurtre-sec23-2 .ayurtre-sec23-3,
  .unikrish-k12 .unikrish-k12-1 .ayurtre-sec23-3 {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 5px;
  }
  .ayurtre-sec23 {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 25px;
    padding-top: 25px;
  }
  .unikrish-k12 .unikrish-k12-2 img {
    max-width: 50%;
  }
  .ayurtre-sec23-2 .ayurtre-posi-4,
  .unikrish-k12 .unikrish-k12-1 .ayurtre-posi-4 {
    margin-bottom: 15px;
  }
  .unikrish-k12 {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 25px;
    padding-top: 25px;
  }
  .ayrvd-sec-12 {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 25px;
    margin-bottom: 25px;
  }
  .world-sec124 {
    padding-bottom: 25px;
  }
  .world-sec124 .world-sec124-1 .world-sec124-2 {
    font-size: 30px;
    margin-bottom: 25px;
  }
  .world-sec124 .world-sec124-1 .world-sec124-3 {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0px;
  }
  .ayurtre-sec23 .ayurtre-sec23-2 .ayurtre-sec23-4,
  .unikrish-k12 .unikrish-k12-1 .unikrish-k12-1-2 {
    width: auto;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 25px;
  }
  .blog-sec-20 .blog-sec-20-5 .blog-sec-20-7,
  .sli-sec15 .sli-sec15-3,
  .shopby-sec17 .shopby-sec17-1 .treatment-sec26-3 {
    font-size: 14px;
  }
  .foot-sec21 .foot-sec21-17 {
    text-align: left;
    margin: 36px 0 30px;
  }
  .foot-sec21 .foot-sec21-17 .foot-sec21-18 {
    justify-content: flex-start;
  }
  .foot-sec21 .foot-sec21-19, .foot-sec21 .foot-sec21-20 {
    text-align: left !important;
  }
  .foot-sec21 .foot-sec21-1 {
    margin-bottom: 25px;
  }
  .foot-sec21 .foot-sec21-15 {
    float: left;
  }
  .foot-sec21 .foot-sec21-19 {
    margin-bottom: 20px;
  }
  .blog-sec-20 {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 25px;
  }
  .foot-sec21 {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .blog-sec-20 .blog-sec-20-1 {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 25px;
  }
  .shopby-sec17 {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 25px;
    padding-top: 25px;
  }
  .faqfr12 .faqfr13 .faqfr13-1 {
    font-size: 30px;
  }
  .faqfr12 .faqfr13 .faqfr13-2 {
    font-size: 18px;
    margin: 5px 0 0 0;
  }
  .sli-sec15 {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 25px;
    padding-top: 25px;
  }
  .faqfr12 {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 25px;
    padding-top: 25px;
    margin-bottom: 25px;
  }
  .ad-sec-19a {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 25px;
    margin-bottom: 0;
  }
  .sli-sec15 .sli-sec15-2,
  .shopby-sec17 .shopby-sec17-2,
  .world-sec124 .world-sec124-1 .world-sec124-2,
  .blog-sec-20 .blog-sec-20-5 .blog-sec-20-6 {
    font-size: 26px;
  }
  .hmhd12-16,
  .hmhd12 .hmhd12-2 .hmhd12-8 .hmhd12-9 .hmhd12-10 {
    display: none;
  }
  .hmhd12 .hmhd12-15 {
    padding-left: 5px;
    padding-right: 15px;
  }
  .foot-sec21 .foot-sec21-17 .foot-sec21-18 li {
    margin: 0 16px 0 0;
  }
  .ban-sec14 .hm-banner .owl-nav {
    display: none;
  }
  .shopby-sec17 .shopby-sec17-1,
  .main-sec13 .sli-sec15 .sli-sec15-1,
  .world-sec124 .world-sec124-1 .world-sec124-3,
  .blog-sec-20 .blog-sec-20-5 {
    padding-bottom: 25px;
  }
  .shopby-sec17 .shopby-sec17-4 .shopby-sec17-5 {
    width: 70px;
    height: 70px;
  }
  .caro-sec22 .caro-sec22-2 .caro-sec22-6 {
    font-size: 18px;
    line-height: 20px;
  }
  .caro-sec22 .caro-sec22-2,
  .blog-sec-20 .blog-sec-20-1 .blog-sec-20-2 .blog-sec-20-4 {
    padding: 15px 7px 15px;
  }
  .blog-sec-20 .blog-sec-20-1 .blog-sec-20-2 .blog-sec-20-4 h3 {
    line-height: 30px;
    font-size: 22px;
  }
  .blog-sec-20 .blog-sec-20-1 .blog-sec-20-2 .blog-sec-20-4 p {
    font-size: 14px;
    margin: 10px 0 10px;
  }
  .blog-sec-20 .blog-sec-20-1 .blog-sec-20-2 .blog-sec-20-4 a {
    font-size: 14px;
  }
  .shopby-sec17 .shopby-sec17-3 .shopby-sec17-4 .shopby-sec17-6 {
    font-size: 18px;
  }
  .ayrvd-sec-12 .ayrvd-sec-12-2 {
    font-size: 30px;
    margin-bottom: 15px;
    margin-top: 25px;
  }
  .spaf-sec-12 .spaf-sec-12-2 {
    font-size: 30px;
    margin-bottom: 15px;
    margin-top: 25px;
  }
  .spaf-sec-12 .spaf-sec-12-3 {
    font-size: 14px;
    line-height: 24px;
  }
  .spaf-sec-12 {
    padding-top: 25px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .hmhd12 .hmhd12-1 {
    padding-right: 10px;
    padding-left: 10px;
    display: grid;
    gap: 5px 0;
    justify-content: center;
  }
  .add-fp1 {
    margin-left: inherit;
  }
  .ph-12d1 .ph-12d1-1 {
    justify-content: center;
    margin-left: 10px;
    padding: 6px 14px 5px 14px !important;
  }
  .add-fp1 .add-fp1-1 {
    margin-left: 10px;
    margin-right: auto;
    justify-content: center;
  }
  .hmhd12 .hmhd12-1-wel {
    margin-left: 0;
    margin-bottom: 8px;
  }
  .add-fp1 .add-fp1-1 .add-fp1-3 {
    font-size: 12px;
  }
  .hmhd12 .hmhd12-1-wel {
    font-size: 11px;
    text-align: center;
  }
  .hmhd12 .hmhd12-1-wel span {
    font-size: 12px;
  }
  .ph-12d1 .ph-12d1-1 .ph-12d1-3 {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 600px) {
  .sli-sec15 .sli-sec15-2,
  .shopby-sec17 .shopby-sec17-2,
  .world-sec124 .world-sec124-1 .world-sec124-2,
  .blog-sec-20 .blog-sec-20-5 .blog-sec-20-6,
  .faqfr12 .faqfr13 .faqfr13-1,
  .ayrvd-sec-12 .ayrvd-sec-12-2,
  .spaf-sec-12 .spaf-sec-12-2 {
    font-size: 22px;
  }
  .foot-sec21 .foot-sec21-1 h3,
  .foot-sec21 .foot-sec21-5 h3, .foot-sec21 .foot-sec21-15 h3,
  .foot-sec21 .foot-sec21-8 h3,
  .foot-sec21 .foot-sec21-17 h6 {
    font-size: 22px;
  }
  .blog-sec-20 .blog-sec-20-5 .blog-sec-20-7,
  .sli-sec15 .sli-sec15-3,
  .shopby-sec17 .shopby-sec17-1 .treatment-sec26-3 {
    font-size: 12px;
  }
  .ayurtre-sec23 .ayurtre-sec23-2 .ayurtre-sec23-3,
  .unikrish-k12 .unikrish-k12-1 .ayurtre-sec23-3 {
    font-size: 24px;
    line-height: 30px;
  }
  .faqfr12 .faqfr12-1 {
    border-end-start-radius: 60px;
  }
  .faqfr12 .faqfr12-2 {
    border-start-end-radius: 60px;
  }
  .hmhd12 .hmhd12-2 .hmhd12-6 .hmhd12-7 img {
    width: 150px;
  }
  .faqfr12 .faqfr14 {
    margin: 30px 0 0 0;
  }
  .owl-prev {
    opacity: 0 !important;
  }
  .owl-next {
    opacity: 0 !important;
  }
  .lang-box {
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 5px 0px;
  }
  .rus {
    font-size: 12px;
    padding: 4px;
  }
  .eng {
    font-size: 12px;
    padding: 4px;
  }
}
.morecontent span {
  display: none;
}

.morelink {
  display: block;
  text-decoration: none;
  color: #9d640e;
  font-weight: 500;
  margin-top: 20px;
}

.morelink:hover {
  color: #9d640e;
  text-decoration: underline;
}

.banner {
  background-size: cover !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  margin: 0px;
}

.abt-ban85 {
  position: relative;
  min-height: 250px !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
}

.abt-ban85 {
  position: relative;
}

.abt-ban85 .abt-ban86 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b6835b;
}

.abt-ban85 .abt-ban86 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.com-prov94 {
  background: #fbf2eb;
}

.com-prov94 .acci-f1 .accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #c18659;
}

.com-prov94 .acci-f1 .accordion-button:focus {
  outline: 0;
  box-shadow: none;
}

.com-prov94 .acci-f1 .accordion-button:hover {
  color: #fff;
  background-color: #c18659;
}

.com-prov94 .acci-f1 .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.com-prov94 .com-prov94-1 {
  margin-right: 50px;
}

.com-prov94 .com-prov94-1 img {
  max-width: 100%;
}

@media screen and (max-width: 767px) {
  .abt-ban88 .abt-ban89 {
    text-align: left !important;
  }
  .com-prov94 .com-prov94-1 {
    text-align: left;
    margin-top: 30px;
  }
  .com-prov94 {
    margin: 0;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .com-prov94 .com-prov94-1 {
    margin-right: 0;
  }
  .com-prov94 .com-prov94-1 img {
    max-width: 50%;
  }
  .com-prov94 .acci-f1 {
    margin: 50px 0 0 0;
  }
  .com-prov94 {
    margin-top: 30px !important;
    padding-top: 25px !important;
    padding-bottom: 30px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
  .abt-ban88 .abt-ban89 h2 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 15px;
  }
  .abt-ban88 .abt-ban89 p {
    line-height: 24px;
  }
  .com-prov94 .com-prov95 .com-prov96 {
    font-size: 24px;
    margin-bottom: 15px;
    line-height: 30px;
  }
  .com-prov94 .com-prov95 .com-prov98 {
    line-height: 24px;
  }
}
.abt-ban85 {
  position: relative;
  min-height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
}

.abt-ban85 .abt-ban86 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-catlt-1 {
  display: block;
}

.cat-treng-sec1 .cat-treng-sec1-1 img {
  max-width: 100%;
}

.cat-treng-sec1 .cat-treng-sec1-2 .cat-treng-sec1-3 {
  color: #C06700;
}

.cat-treng-sec1 .cat-treng-sec1-2 .cat-treng-sec1-5 {
  color: #959191;
}

.cat-treng-sec2 .cat-treng-sec2-2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 60px;
}

.cat-treng-sec2-3 .cat-treng-sec2-4 .cat-treng-sec2-7 .cat-treng-sec2-5 {
  width: 65px;
  height: 65px;
  margin: 0 auto;
  border-radius: 100px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: all 0.6s ease-in-out;
}

.cat-treng-sec2-3 .cat-treng-sec2-4 .cat-treng-sec2-7 {
  margin: -50px 0 0 0;
}

.cat-treng-sec2-3 .cat-treng-sec2-4 {
  display: block;
  background-color: #b76e34;
  border-bottom-right-radius: 40px;
  padding: 20px 10px 20px;
  transition: all 0.2s ease-in-out;
}

.cat-treng-sec2-3 .cat-treng-sec2-4:hover {
  background: #d58341;
}

.cat-treng-sec2-3 .cat-treng-sec2-4:hover .cat-treng-sec2-7 .cat-treng-sec2-5 {
  transform: rotate(90deg);
}

.tre-pro-fk {
  list-style: disc;
  padding: 25px 0 25px 25px;
}

.tre-pro-fk li {
  margin-bottom: 5px;
}

@media screen and (max-width: 1024px) {
  .cat-treng-sec2 .cat-treng-sec2-2 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .cat-treng-sec2 .cat-treng-sec2-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .cat-treng-sec1 .cat-treng-sec1-1 {
    width: 50%;
    margin-bottom: 40px;
  }
  .cat-treng-sec1 {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .cat-treng-sec1 .cat-treng-sec1-2 .cat-treng-sec1-4 {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 15px;
  }
  .cat-treng-sec2 {
    padding-top: 0;
    padding-bottom: 25px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 3px;
  }
  .cat-treng-sec2 .cat-treng-sec2-1 {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 55px;
    text-align: left !important;
  }
  .cat-treng-sec2-3 .cat-treng-sec2-4 .cat-treng-sec2-7 .cat-treng-sec2-5 {
    width: 55px;
    height: 55px;
  }
  .cat-treng-sec2-3 .cat-treng-sec2-4 .cat-treng-sec2-6 {
    font-size: 15px;
    line-height: 22px;
  }
  .cat-treng-sec1 .cat-treng-sec1-2 .cat-treng-sec1-3 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .cat-treng-sec1 .cat-treng-sec1-2 .cat-treng-sec1-5 {
    font-size: 14px;
    line-height: 24px;
  }
}
@media screen and (max-width: 600px) {
  .cat-treng-sec1 .cat-treng-sec1-2 .cat-treng-sec1-4 {
    font-size: 24px;
    line-height: 30px;
  }
  .cat-treng-sec2 .cat-treng-sec2-1 {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 45px;
  }
  .cat-treng-sec2-3 .cat-treng-sec2-4 .cat-treng-sec2-6 {
    font-size: 14px;
  }
}
.abt-ban85 {
  position: relative;
  min-height: 150px;
}

.abt-ban85 .abt-ban86 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b6835b;
}

.abt-ban85 .abt-ban87-1:hover {
  color: #fff;
  opacity: 0.6;
}

.main-serdlts-1 {
  display: block;
}

.serdetg-sec2 .serdetg-sec2-1 img {
  max-width: 100%;
  margin-bottom: 40px;
}

.serdetg-sec2 .serdetg-sec2-1 {
  padding-right: 30px;
}

.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-1 {
  padding: 14px 20px 15px;
  background: #c73f29;
}

.serdetg-sec2 .serdert-sec .serdert-sec1 {
  background: #ffeade;
}

.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 {
  padding: 20px 10px 20px;
}

.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-3,
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-4,
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-13 {
  width: 100%;
  padding: 10px 10px 10px;
  border: 1px #edcfbe solid;
  margin-bottom: 11px;
}

.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-5 label,
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-6 h6,
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-11 label,
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-12 label {
  width: 100%;
  margin-bottom: 10px;
}

.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-5 input,
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-11 input,
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-10 input {
  width: 100%;
  padding: 10px 10px 10px;
  border: 1px #edcfbe solid;
  margin-bottom: 12px;
}

.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-6 {
  margin-bottom: 10px;
}

.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-6 .serdert-sec1-out {
  display: flex;
}

.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-6 .serdert-sec1-out .serdert-sec1-7,
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-6 .serdert-sec1-out .serdert-sec1-8 {
  margin-right: 10px;
}

.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-12 select {
  width: 100%;
  padding: 8px 10px 8px;
  border: 1px #edcfbe solid;
  margin-bottom: 10px;
}

.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-14 {
  padding: 11px 15px;
  margin-top: 5px;
  background: #C06700;
  border: 1px #C06700 solid;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-14:hover {
  border: 1px #df8011 solid;
  background: #df8011;
}

.serdert-sec1-2 input:focus,
.serdert-sec1-2 select:focus {
  outline: 0;
  border: 1px #edb595 solid !important;
}

.serdert-sec .vid-to1 {
  margin-bottom: 30px;
}

.serdert-sec .vid-to1 iframe {
  width: 100% !important;
  height: 240px !important;
}

.serdetg-sec2 .tre-re-out12 {
  margin-bottom: 80px;
}

@media screen and (max-width: 991px) {
  .serdetg-sec2 .serdetg-sec2-1 {
    padding-right: 0px;
  }
}
@media screen and (max-width: 767px) {
  .serdetg-sec1 {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .serdetg-sec2 .serdetg-sec2-1 img {
    margin-bottom: 25px;
  }
  .serdetg-sec1 .serdetg-sec1-2 {
    font-size: 26px;
    line-height: 30px;
  }
  .serdetg-sec2 .serdetg-sec2-1 .serdetg-sec2-2 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px;
  }
  .serdetg-sec2 .serdetg-sec2-1 .serdetg-sec2-3 {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 25px;
  }
  .serdetg-sec2 .serdetg-sec2-1 {
    padding-bottom: 10px;
  }
  .serdetg-sec2 {
    padding-bottom: 25px;
  }
  .abt-ban85 .abt-ban86 .abt-ban87 {
    font-size: 16px;
    line-height: 22px;
  }
}
@media screen and (max-width: 600px) {
  .serdetg-sec1 .serdetg-sec1-2 {
    font-size: 24px;
  }
  .serdetg-sec1 {
    padding-bottom: 15px;
  }
  .serdetg-sec2 .serdetg-sec2-1 .serdetg-sec2-2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
.price-list {
  padding: 0px 0px 30px 0px;
  margin-bottom: 0;
}
.price-list__accorioon-btn {
  font-size: 16px !important;
}

.price {
  padding: 0px 15px !important;
}

table { /* border: 1px solid #fff; */
  width: 100%;
  margin-top: 10px;
}

table tr { /* border: none; */
  border-radius: 10px;
}

table td {
  border: 1px solid #000;
  padding: 8px;
  width: 100px !important;
}
table td a {
  color: #20c997;
  text-decoration: underline;
  text-transform: uppercase;
}

tbody tr:nth-child(2n) {
  background: #f1f1f1;
}

.button {
  background-color: #1c87c9;
  -webkit-border-radius: 60px;
  border-radius: 60px;
  border: none;
  color: #eeeeee;
  cursor: pointer;
  display: inline-block;
  font-family: sans-serif;
  font-size: 20px;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
}

@keyframes glowing {
  0% {
    background-color: #2ba805;
    box-shadow: 0 0 3px #2ba805;
  }
  50% {
    background-color: #49e819;
    box-shadow: 0 0 10px #49e819;
  }
  100% {
    background-color: #2ba805;
    box-shadow: 0 0 3px #2ba805;
  }
}
.button {
  animation: glowing 1300ms infinite;
}

@media (max-width: 768px) {
  .price-list__accorioon-btn {
    font-size: 14px !important;
  }
  table {
    font-size: 12px !important;
  }
}
@font-face {
  font-family: "lg";
  src: url("../gallery/lg.ttf") format("truetype"), url("../gallery/lg.woff") format("woff"), url("../gallery/lg.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.lg-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "lg" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lg-actions .lg-next, .lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
  outline: none;
  border: none;
  background-color: transparent;
}

.lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
  color: #FFF;
}

.lg-actions .lg-next {
  right: 20px;
}

.lg-actions .lg-next:before {
  content: "\e095";
}

.lg-actions .lg-prev {
  left: 20px;
}

.lg-actions .lg-prev:after {
  content: "\e094";
}

@-webkit-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative;
}

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

#lg-download {
  display: none;
}

.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: medium none;
  background: none;
  border: none;
  box-shadow: none;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.lg-toolbar .lg-icon:hover {
  color: #FFF;
}

.lg-toolbar .lg-close:after {
  content: "\e070";
}

.lg-toolbar .lg-download:after {
  content: "\e0f2";
}

.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #EEE;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080;
}

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
}

.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle;
}

.lg-toolbar, .lg-prev, .lg-next {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
}

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
}

.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
}

.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-outer .lg-thumb-outer {
  background-color: #0D0A0A;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}

.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0);
}

.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px;
}

.lg-outer .lg-thumb-item {
  border-radius: 5px;
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-bottom: 5px;
}

@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    -webkit-transition: border-color 0.25s ease;
    -o-transition: border-color 0.25s ease;
    transition: border-color 0.25s ease;
  }
}
.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #a90707;
}

.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px;
}

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}

.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom 0.25s ease;
  -o-transition: bottom 0.25s ease;
  transition: bottom 0.25s ease;
}

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px;
}

.lg-outer .lg-toggle-thumb {
  background-color: #0D0A0A;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px;
  outline: medium none;
  border: none;
}

.lg-outer .lg-toggle-thumb:after {
  content: "\e1ff";
}

.lg-outer .lg-toggle-thumb:hover {
  color: #FFF;
}

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px;
}

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
}

.lg-outer .lg-video .lg-object {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.lg-outer .lg-video .lg-video-play {
  width: 84px;
  height: 59px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -42px;
  margin-top: -30px;
  z-index: 1080;
  cursor: pointer;
}

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../img/vimeo-play.png") no-repeat scroll 0 0 transparent;
}

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../img/vimeo-play.png") no-repeat scroll 0 -58px transparent;
}

.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../img/video-play.png") no-repeat scroll 0 0;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8;
}

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1;
}

.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../img/youtube-play.png") no-repeat scroll 0 0 transparent;
}

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../img/youtube-play.png") no-repeat scroll 0 -60px transparent;
}

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden;
}

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none;
}

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible;
}

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 0.08s ease 0s;
  -moz-transition: opacity 0.08s ease 0s;
  -o-transition: opacity 0.08s ease 0s;
  transition: opacity 0.08s ease 0s;
}

.lg-progress-bar .lg-progress {
  background-color: #a90707;
  height: 5px;
  width: 0;
}

.lg-progress-bar.lg-start .lg-progress {
  width: 100%;
}

.lg-show-autoplay .lg-progress-bar {
  opacity: 1;
}

.lg-autoplay-button:after {
  content: "\e01d";
}

.lg-show-autoplay .lg-autoplay-button:after {
  content: "\e01a";
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

#lg-zoom-in:after {
  content: "\e311";
}

#lg-actual-size {
  font-size: 20px;
  display: none;
}

#lg-actual-size:after {
  content: "\e033";
}

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none;
}

#lg-zoom-out:after {
  content: "\e312";
}

.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px;
}

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible;
}

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px;
}

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: 0 0 0 2px white inset;
}

.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #FFF;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  -moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
}

.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%;
}

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
  width: 12px;
}

.lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus {
  box-shadow: 0 0 0 8px white inset;
}

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0;
}

.lg-fullscreen:after {
  content: "\e20c";
}

.lg-fullscreen-on .lg-fullscreen:after {
  content: "\e20d";
}

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
}

.lg-outer.lg-dropdown-active .lg-dropdown, .lg-outer.lg-dropdown-active #lg-dropdown-overlay {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  opacity: 1;
  visibility: visible;
}

.lg-outer.lg-dropdown-active #lg-share {
  color: #FFF;
}

.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
}

.lg-outer .lg-dropdown:after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  border: 8px solid transparent;
  border-bottom-color: #FFF;
  right: 16px;
  top: -16px;
}

.lg-outer .lg-dropdown > li:last-child {
  margin-bottom: 0px;
}

.lg-outer .lg-dropdown > li:hover a, .lg-outer .lg-dropdown > li:hover .lg-icon {
  color: #333;
}

.lg-outer .lg-dropdown a {
  color: #333;
  display: block;
  white-space: pre;
  padding: 4px 12px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.lg-outer .lg-dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.lg-outer .lg-dropdown .lg-dropdown-text {
  display: inline-block;
  line-height: 1;
  margin-top: -3px;
  vertical-align: middle;
}

.lg-outer .lg-dropdown .lg-icon {
  color: #333;
  display: inline-block;
  float: none;
  font-size: 20px;
  height: auto;
  line-height: 1;
  margin-right: 8px;
  padding: 0;
  vertical-align: middle;
  width: auto;
}

.lg-outer #lg-share {
  position: relative;
  display: none;
}

.lg-outer #lg-share:after {
  content: "\e80d";
}

.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998;
}

.lg-outer #lg-share-facebook .lg-icon:after {
  content: "\e904";
}

.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced;
}

.lg-outer #lg-share-twitter .lg-icon:after {
  content: "\e907";
}

.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39;
}

.lg-outer #lg-share-googleplus .lg-icon:after {
  content: "\e905";
}

.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027;
}

.lg-outer #lg-share-pinterest .lg-icon:after {
  content: "\e906";
}

.lg-outer .lg-img-rotate {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  transition: transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
}

.lg-rotate-left:after {
  content: "\e900";
}

.lg-rotate-right:after {
  content: "\e901";
}

.lg-icon.lg-flip-hor, .lg-icon.lg-flip-ver {
  font-size: 26px;
}

.lg-flip-hor:after {
  content: "\e902";
}

.lg-flip-ver:after {
  content: "\e903";
}

.lg-group {
  *zoom: 1;
}

.lg-group:before, .lg-group:after {
  display: table;
  content: "";
  line-height: 0;
}

.lg-group:after {
  clear: both;
}

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999;
  opacity: 0;
  outline: none;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}

.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.lg-outer.lg-visible {
  opacity: 1;
}

.lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}

.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%;
}

.lg-outer .lg-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
}

.lg-outer .lg-item {
  background: url("../img/loading.gif") no-repeat scroll center center transparent;
  display: none !important;
}

.lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide {
  display: inline-block !important;
}

.lg-outer.lg-css .lg-current {
  display: inline-block !important;
}

.lg-outer .lg-item, .lg-outer .lg-img-wrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}

.lg-outer .lg-img-wrap {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.lg-outer .lg-item.lg-complete {
  background-image: none;
}

.lg-outer .lg-item.lg-current {
  z-index: 1060;
}

.lg-outer .lg-image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto !important;
}

.lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1;
}

.lg-outer .lg-empty-html {
  display: none;
}

.lg-outer.lg-hide-download #lg-download {
  display: none;
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999999;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}

.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important;
}

.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-css3.lg-fade .lg-item {
  opacity: 0;
}

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}

.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
  -webkit-transition: opacity 0.1s ease 0s;
  -moz-transition: opacity 0.1s ease 0s;
  -o-transition: opacity 0.1s ease 0s;
  transition: opacity 0.1s ease 0s;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  left: 0;
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.small {
  font-size: 11px;
  color: #999;
  display: block;
  margin-top: -10px;
}

.cont {
  text-align: center;
}

.page-head {
  padding: 60px 0;
  text-align: center;
}

.page-head .lead {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 50px;
  margin-top: 0;
}

.btn {
  -moz-user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857;
  margin-bottom: 0;
  padding: 6px 12px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
}

.btn-lg {
  border-radius: 2px;
  font-size: 18px;
  line-height: 1.33333;
  padding: 10px 16px;
}

.btn-primary:hover {
  background-color: #fff;
  color: #152836;
}

.btn-primary {
  background-color: #152836;
  border-color: #0e1a24;
  color: #ffffff;
}

.btn-primary {
  border-color: #eeeeee;
  color: #eeeeee;
  transition: color 0.1s ease 0s, background-color 0.15s ease 0s;
}

.page-head h1 {
  font-size: 42px;
  margin: 0 0 20px;
  color: #FFF;
  position: relative;
  display: inline-block;
}

.page-head h1 .version {
  bottom: 0;
  color: #ddd;
  font-size: 11px;
  font-style: italic;
  position: absolute;
  width: 58px;
  right: -58px;
}

.demo-gallery > ul {
  margin-bottom: 0;
}

.demo-gallery > ul > li {
  margin-bottom: 15px;
  display: inline-block;
  list-style: outside none none;
}

.demo-gallery > ul > li a {
  border: 3px solid #FFF;
  border-radius: 3px;
  display: block;
  overflow: hidden;
  position: relative;
  float: left;
}

.demo-gallery > ul > li a > img {
  -webkit-transition: -webkit-transform 0.15s ease 0s;
  -moz-transition: -moz-transform 0.15s ease 0s;
  -o-transition: -o-transform 0.15s ease 0s;
  transition: transform 0.15s ease 0s;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  height: 100%;
  width: 100%;
}

.demo-gallery > ul > li a:hover > img {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
}

.demo-gallery > ul > li a:hover .demo-gallery-poster > img {
  opacity: 1;
}

.demo-gallery > ul > li a .demo-gallery-poster {
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: background-color 0.15s ease 0s;
  -o-transition: background-color 0.15s ease 0s;
  transition: background-color 0.15s ease 0s;
}

.demo-gallery > ul > li a .demo-gallery-poster > img {
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
  opacity: 0;
  position: absolute;
  top: 50%;
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
}

.demo-gallery > ul > li a:hover .demo-gallery-poster {
  background-color: rgba(0, 0, 0, 0.5);
}

.demo-gallery .justified-gallery > a > img {
  -webkit-transition: -webkit-transform 0.15s ease 0s;
  -moz-transition: -moz-transform 0.15s ease 0s;
  -o-transition: -o-transform 0.15s ease 0s;
  transition: transform 0.15s ease 0s;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  height: 100%;
  width: 100%;
}

.demo-gallery .justified-gallery > a:hover > img {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
}

.demo-gallery .justified-gallery > a:hover .demo-gallery-poster > img {
  opacity: 1;
}

.demo-gallery .justified-gallery > a .demo-gallery-poster {
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: background-color 0.15s ease 0s;
  -o-transition: background-color 0.15s ease 0s;
  transition: background-color 0.15s ease 0s;
}

.demo-gallery .justified-gallery > a .demo-gallery-poster > img {
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
  opacity: 0;
  position: absolute;
  top: 50%;
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
}

.demo-gallery .justified-gallery > a:hover .demo-gallery-poster {
  background-color: rgba(0, 0, 0, 0.5);
}

.demo-gallery .video .demo-gallery-poster img {
  height: 48px;
  margin-left: -24px;
  margin-top: -24px;
  opacity: 0.8;
  width: 48px;
}

.demo-gallery.dark > ul > li a {
  border: 3px solid #04070a;
}

.abt-ban85 {
  position: relative;
  min-height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
}

.abt-ban85 .abt-ban86 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bl-sec116 .bl-sec117 {
  display: grid;
  background: #fff;
}

.bl-sec116 .bl-sec117 .bl-sec123 {
  padding: 25px 0 29px;
}

.bl-sec116 .bl-sec117 .bl-sec123 .bl-sec118 {
  margin-bottom: 5px;
}

.bl-sec116 .bl-sec117 .bl-sec121 {
  display: flex;
  margin-top: auto;
}

.bl-sec116 .bl-sec124 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-wrap: wrap;
}

.bl-sec116 .bl-sec124 .bl-sec117 .bl-sec123 .bl-sec122:hover {
  color: #1799e8;
}

/* Blog details Start */
.blg-de-fj125 {
  padding-top: 70px;
}

.blg-de-fj125 .blg-de-fj125-1 .blg-de-fj125-2 {
  display: flex;
  justify-content: center;
}

.blg-de-fj125 .blg-de-fj125-1 .blg-de-fj125-2 .blg-de-fj125-3 {
  margin-right: 5px;
}

.blg-de-fj125 .blg-de-fj125-6 .blg-de-fj125-7 {
  color: #C06700;
}

.blg-de-fj125 .blg-de-fj125-1 {
  display: grid;
  height: 100%;
}

.blg-de-fj125 .blg-de-fj125-1 .blg-de-fj125-8 {
  margin-top: auto;
}

.blg-degh126 .blg-degh126-1 .blg-degh126-3 .blg-degh126-4 {
  display: flex;
  padding-bottom: 24px;
  border-bottom: 1px #ddd solid;
  margin-bottom: 20px;
}

.blg-degh126 .blg-degh126-1 .blg-degh126-3 .blg-degh126-4 .blg-degh126-5 {
  width: 128px;
  max-width: 128px;
  min-width: 120px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.blg-degh126 .blg-degh126-1 .blg-degh126-3 .blg-degh126-4 .blg-degh126-6 {
  padding: 0 10px 0px 20px;
}

.blg-degh126 .blg-degh126-1 .blg-degh126-3 .blg-degh126-4 .blg-degh126-6:hover .blg-degh126-7 {
  transition: all 0.2s ease-in-out;
}

.blg-degh126 .blg-degh126-1 .blg-degh126-3 .blg-degh126-4 .blg-degh126-6:hover .blg-degh126-7 {
  color: #919191;
}

.blg-degh126 .blg-degh126-1 {
  display: flex;
  justify-content: center;
}

/* Blog details End */
@media screen and (max-width: 1199px) {
  .bl-sec116 .bl-sec124 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 991px) {
  .bl-sec116 .bl-sec124 {
    grid-template-columns: repeat(2, 1fr);
  }
  /* Blog details Start */
  .blg-degh126 .blg-degh126-1 .blg-degh126-3 .blg-degh126-4 .blg-degh126-5 {
    width: 65px;
    max-width: 65px;
    min-width: 65px;
    height: 107px;
  }
  .blg-degh126 .blg-degh126-1 .blg-degh126-3 .blg-degh126-4 .blg-degh126-6 .blg-degh126-7 {
    font-size: 14px;
    line-height: 18px;
  }
  /* Blog details End */
}
@media screen and (max-width: 767px) {
  .bl-sec116 .bl-sec124 {
    grid-template-columns: repeat(1, 1fr);
  }
  /* Blog details Start */
  .blg-degh126 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .blg-degh126 .blg-degh126-9 p {
    font-size: 14px;
    text-align: left;
    line-height: 24px;
  }
  .blg-degh126 .blg-degh126-9 .blg-degh126-10 {
    margin-bottom: 20px;
  }
  .blg-de-fj125 {
    padding-bottom: 0 !important;
    padding-top: 25px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .blg-degh126 .blg-degh126-2 {
    font-size: 22px;
    margin-bottom: 35px;
    margin-top: 45px;
    text-align: left !important;
  }
  .blg-degh126 .blg-degh126-9 {
    padding-left: 0;
  }
  .blg-de-fj125 {
    padding-top: 97px;
    padding-bottom: 50px;
  }
  .blg-de-fj125 .blg-de-fj125-6 {
    padding-left: 0;
  }
  .blg-de-fj125 .blg-de-fj125-7 {
    font-size: 24px;
    line-height: 30px;
    margin-top: 0px;
  }
  .blg-de-fj125 .blg-de-fj125-1 .blg-de-fj125-2 {
    margin-bottom: 5px;
  }
  .blg-degh126 .blg-degh126-1 .blg-degh126-3 .blg-degh126-4 .blg-degh126-5 {
    width: 128px;
    max-width: 128px;
    min-width: 120px;
    height: 100px;
  }
  .blg-degh126 .blg-degh126-1 .blg-degh126-3 .blg-degh126-4 .blg-degh126-6 .blg-degh126-7 {
    font-size: 16px;
    line-height: 20px;
  }
  .blg-degh126 .blg-degh126-1 .blg-degh126-3 .blg-degh126-4 .blg-degh126-6 .blg-degh126-8 {
    font-size: 12px;
  }
  .blg-degh126 .blg-degh126-1 .blg-degh126-3 .blg-degh126-4 .blg-degh126-6 {
    padding: 0 10px 0px 10px;
  }
  /* Blog details End */
}
.consult-main {
  display: block;
}

.gradient-custom {
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  background-attachment: fixed;
  padding: 50px 30px 50px;
}

.card-registration .select-input.form-control[readonly]:not([disabled]) {
  font-size: 1rem;
  line-height: 2.15;
  padding-left: 0.75em;
  padding-right: 0.75em;
}

.card-registration .select-arrow {
  top: 13px;
}

.red-star {
  color: red;
}

.conultuj12 {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.conultuj12 .form-outline {
  margin-bottom: 10px;
}

.conultuj12 .form-outline label.form-label,
.conultuj12 .form-outline h6.genfk1 {
  color: #7a7a7a;
}

.conultuj12 .form-outline input.form-control {
  border-bottom: 1px #ab9a9a solid;
  border-radius: 0;
  border-left: none;
  border-right: none;
  border-top: none;
  padding: 5px 0 5px;
}

.conultuj12 .form-outline select.select {
  width: 100%;
  border-bottom: 1px #ab9a9a solid;
  border-radius: 0;
  border-left: none;
  border-right: none;
  border-top: none;
  padding: 5px 0 5px;
}

.conultuj12 .const-hfs1 {
  color: #C06700;
}

.conultuj12 .form-outline input.form-control:focus,
.conultuj12 .form-outline select.select:focus {
  outline: 0;
  border-left: none;
  border-right: none;
  border-top: none;
  color: #C06700;
  border-bottom: 1px #e97408 solid;
  box-shadow: none;
}

.conultuj12 .form-check-inline .form-check-label {
  margin: 5px 0 0;
}

.conultuj12 .genfk1 {
  margin-bottom: 17px;
}

.conultuj12 .consul-dg-bt12 {
  padding: 15px 30px;
  border-radius: 30px;
  background-color: #62a55e;
  border: 1px solid #62a55e;
  text-decoration: none;
  display: inline-block;
  margin-top: 5px;
  color: #fff;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.conultuj12 .consul-dg-bt12:hover {
  text-decoration: none;
  border: 1px solid #62a55e;
  background-color: transparent;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  color: #000;
}

.conultuj12 .consul-dg-bt12:focus {
  outline: 0;
  border: 1px solid #62a55e;
}

.abt-ban85 {
  position: relative;
  min-height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
}

.abt-ban85 .abt-ban86 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cone-sec127 .cone-sec127-2 .cone-sec127-3 img {
  width: 32px;
}

.cone-sec127 .cone-sec127-10 {
  height: 100%;
}

.cone-sec127 .cone-sec127-10 .cone-sec127-2 {
  border-left: 1px #ddd solid;
  border-right: 1px #ddd solid;
  height: 100%;
}

.cone-sec127 .cone-sec127-2 {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 25px 50px;
}

.cone-sec127 .cone-sec127-2 .cone-sec127-3 {
  margin-right: 15px;
}

.cone-sec127 .cone-sec127-2 .cone-sec127-6 .cone-sec127-10 .cone-sec127-8 {
  display: inline-block;
}

.cone-sec127 .cone-sec127-10 .cone-sec127-2 .cone-sec127-4 .cone-sec127-6 .cone-sec127-7:hover,
.cone-sec127 .cone-sec127-2 .cone-sec127-6 .cone-sec127-10 .cone-sec127-8:hover,
.cone-sec127 .cone-sec127-2 .cone-sec127-6 .cone-sec127-10 .cone-sec127-9:hover {
  color: #484848;
}

.cone-sec128 .cone-sec128-2 .cone-sec128-3 .cone-sec128-4 input {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #707070;
  padding: 10px 0 10px 0;
  color: #C06700;
  border-radius: 0;
}

.cone-sec128 .cone-sec128-2 .cone-sec128-3 .cone-sec128-4 input:focus {
  outline: 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #C06700;
  color: #C06700;
  box-shadow: none;
}

.cone-sec128 .cone-sec128-2 .cone-sec128-3 .cone-sec128-4 input::placeholder {
  color: #212121;
}

.cone-sec128 .cone-sec128-2 .cone-sec128-3 .cone-sec128-5 textarea {
  background-color: #f5e7d7;
  border: 2px solid #f5e7d7;
  padding: 10px;
  color: #212121;
  min-height: 80px;
}

.cone-sec128 .cone-sec128-2 .cone-sec128-3 .cone-sec128-5 textarea:focus {
  background-color: #fff;
  border: 2px solid #484848;
  color: #484848;
  font-weight: 600;
  box-shadow: none;
}

.cone-sec128 .cone-sec128-2 .cone-sec128-3 .cone-sec128-5 textarea::placeholder {
  color: #212121;
}

.cone-sec128 .cone-sec128-2 .cone-sec128-3 .cone-sec128-6 button {
  padding: 12px 20px;
  background: #C06700;
  border: 1px #C06700 solid;
  border-radius: 4px;
  color: #fff;
}

.cone-sec128 .cone-sec128-2 .cone-sec128-3 .cone-sec128-6 button:hover {
  border: 1px #df8011 solid;
  background: #df8011;
}

@media screen and (max-width: 991px) {
  .cone-sec127 .cone-sec127-2 {
    padding: 25px 0;
  }
  .cone-sec127 .cone-sec127-10 .cone-sec127-2 {
    border-left: none;
    border-right: none;
  }
}
@media screen and (max-width: 767px) {
  .cone-sec127 .cone-sec127-10 .cone-sec127-2 {
    border-left: none;
    border-right: none;
    padding: 0 0 25px;
  }
  .cone-sec127 {
    padding-top: 25px !important;
    padding-bottom: 0 !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .cone-sec128 .cone-sec128-2 {
    padding-right: 0;
  }
  .cone-sec127 .cone-sec127-2 {
    padding: 0 0 25px;
  }
  .cone-sec128 {
    padding-bottom: 25px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
.abt-ban85 {
  position: relative;
  min-height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
}

.abt-ban85 .abt-ban86 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-serlt-1 {
  display: block;
}

.trdv-ser-sec1 .trdv-ser-sec1-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
}

.trdv-ser-sec1 .trdv-ser-sec1-3 img {
  max-width: 100%;
}

.trdv-ser-sec1 .trdv-ser-sec1-3 .trdv-ser-sec1-5 {
  margin: 80px 0 0 0;
}

.trdv-ser-sec1 .trdv-ser-sec1-3 .trdv-ser-sec1-4 img {
  border-bottom-left-radius: 150px;
  overflow: hidden;
}

.trdv-ser-sec1 .trdv-ser-sec1-3 .trdv-ser-sec1-5 img {
  border-bottom-right-radius: 150px;
  border-bottom-left-radius: 150px;
  overflow: hidden;
}

.trdv-ser-sec1 .trdv-ser-sec1-3 .trdv-ser-sec1-6 img {
  border-bottom-right-radius: 150px;
  overflow: hidden;
}

.trdv-ser-sec2 .trdv-ser-sec2-5 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  padding: 80px 0 70px 50px;
}

.trdv-ser-sec2 .trdv-ser-sec2-5 .trdv-ser-sec2-10 {
  display: flex;
  justify-content: flex-start;
}

.trdv-ser-sec2 .trdv-ser-sec2-5 .trdv-ser-sec2-10 .trdv-ser-sec2-6:hover .trdv-ser-sec2-8 {
  text-decoration: overline;
}

.trdv-ser-sec2-8 {
  max-width: 180px;
}

.trdv-ser-sec2 .trdv-ser-sec2-5 .trdv-ser-sec2-6 .trdv-ser-sec2-7 {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  transition: all 0.6s ease-in-out;
}

.trdv-ser-sec2 .trdv-ser-sec2-5 .trdv-ser-sec2-6:hover .trdv-ser-sec2-7 {
  transform: rotate(90deg);
}

.trdv-ser-sec2 .trdv-ser-sec2-5 .trdv-ser-sec2-6 .trdv-ser-sec2-7 img {
  max-width: 100%;
}

.trdv-ser-sec2 .trdv-ser-sec2-11 {
  background: #ffeee1;
  border-bottom-left-radius: 125px;
  border-top-right-radius: 125px;
  margin: 20px 0px 0px 0px;
}

.trdv-ser-sec2 .trdv-ser-sec2-1 .trdv-ser-sec2-4 {
  display: inline-block;
  padding: 12px 12px;
  border: 1px #999 solid;
  margin: 14px 0 0;
}

.trdv-ser-sec2 .trdv-ser-sec2-1 .trdv-ser-sec2-4:hover {
  color: #C05F30;
  border: 1px #C05F30 solid;
}

.trdv-ser-sec2 .trdv-ser-sec2-1 .trdv-ser-sec2-2a1 {
  color: rgb(135, 135, 135);
}

@media screen and (max-width: 1024px) {
  .trdv-ser-sec2 .trdv-ser-sec2-5 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .trdv-ser-sec2 .trdv-ser-sec2-5 {
    grid-template-columns: repeat(2, 1fr);
  }
  .trdv-ser-sec1 .trdv-ser-sec1-2 {
    font-size: 30px;
    line-height: 40px;
  }
  .trdv-ser-sec1 {
    padding-top: 50px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .trdv-ser-sec2 {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .trdv-ser-sec2 .trdv-ser-sec2-1 {
    padding-right: 0;
  }
  .trdv-ser-sec2-3 .trdv-ser-sec2-1 .trdv-ser-sec2-3 {
    font-size: 14px;
    line-height: 26px;
  }
  .trdv-ser-sec2 .trdv-ser-sec2-5 {
    padding: 24px 10px 20px;
  }
  .trdv-ser-sec2 .trdv-ser-sec2-5 .trdv-ser-sec2-10 {
    text-align: center;
    justify-content: center;
  }
  .trdv-ser-sec2 .trdv-ser-sec2-5 .trdv-ser-sec2-6 .trdv-ser-sec2-7 {
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }
  .trdv-ser-sec2 .trdv-ser-sec2-11 {
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
  }
  .trdv-ser-sec1 .trdv-ser-sec1-3 {
    grid-column-gap: 10px;
  }
  .trdv-ser-sec1 .trdv-ser-sec1-3 .trdv-ser-sec1-5 {
    margin: 0;
  }
}
:root {
  --gutter: 20px;
}

.nav {
  position: relative;
}

.container {
  width: 1244px;
  padding: 0 var(--gutter);
  margin: 0 auto;
}

.grid-container {
  width: 100%;
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: repeat(4, 1fr);
}

.products-grid {
  grid-column: 4 span;
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: repeat(4, 1fr);
}

.product {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 20px;
  padding-top: 9px;
  font-size: 14px;
  background-color: #fff;
}

.product__image {
  position: relative;
  overflow: hidden;
  display: block;
  height: 162px;
}

.image-switch {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  display: flex;
}

.image-switch__item {
  flex-grow: 1;
  cursor: pointer;
}

.image-switch__img {
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);
  pointer-events: none;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.image-switch__img img {
  display: block;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-switch__item:first-child .image-switch__img {
  opacity: 1;
  z-index: -1;
}

.image-switch__item:hover .image-switch__img {
  opacity: 1;
  z-index: -1;
}

.image-pagination {
  position: absolute;
  z-index: 30;
  left: 0;
  bottom: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.image-pagination__item {
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  margin: 0 3px;
  background-color: #c4c4c4;
}

.image-pagination__item--active {
  background-color: #C06700;
}

.product__title {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000;
}

.product__props {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.product__rating {
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  color: var(--color-accent);
  font-weight: 600;
}

.product__testimonials {
  color: #C06700;
}

.product__info {
  margin-bottom: 17px;
  font-size: 12px;
  color: #C06700;
}

.product__term {
  display: block;
  margin-bottom: 7px;
}

.product__available {
  display: block;
}

.product-price {
  margin-bottom: 17px;
  display: flex;
  align-items: center;
}

.product-price__current {
  margin-right: 12px;
  font-size: 24px;
  color: #000;
}

.product-price__old {
  font-size: 12px;
  color: #808080;
  text-decoration: line-through;
}

.product__btn {
  background-color: #C06700;
}

.cart {
  position: relative;
  padding: 40px 19px;
  transition: background-color 0.3s;
}

.cart.active:hover {
  cursor: pointer;
  background: rgba(93, 113, 221, 0.1);
  transition: background-color 0.3s;
}

.header__cart {
  margin-left: 40px;
}

.cart__quantity {
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: -10px;
  background-color: #de707b;
  border-radius: 100%;
  padding: 2px;
  color: #fff;
  font-size: 12px;
  min-height: 16px;
  min-width: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.cart-content {
  cursor: default;
  width: 490px;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(135, 135, 135, 0.25);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 150;
}

.cart.active:hover .cart-content {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.content {
  padding-top: 90px;
}

.cart-content__list {
  max-height: 330px;
}

.cart-product {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  padding-right: 13px;
  transition: background-color 0.3s;
}

.cart-product:hover {
  background: rgba(93, 113, 221, 0.1);
  transition: background-color 0.3s;
}

.cart-product__img {
  margin-right: 20px;
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.cart-product__title {
  max-width: 300px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  margin-bottom: 18px;
}

.cart-product__price {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #000;
}

.cart-content__bottom {
  padding: 20px;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-content__fullprice {
  font-size: 14px;
  line-height: 20px;
  color: #000;
  display: flex;
  flex-direction: column;
}

.fullprice {
  margin-top: 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #000;
}

.cart-content__btn {
  display: inline-block;
  width: 246px;
  text-align: center;
  background: #C06700;
  color: #fff;
}

.show-more {
  margin-top: 50px;
  margin-left: 15px;
  width: 260px;
  height: 40px;
  border: 2px solid #C06700;
  color: #C06700;
  background-color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  text-align: center;
}

@media (max-width: 1250px) {
  .container {
    width: 990px;
    padding: 0 var(--gutter);
    margin: 0 auto;
  }
  .products-grid {
    grid-column: 4 span;
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 992px) {
  .container {
    width: 700px;
    padding: 0 var(--gutter);
    margin: 0 auto;
  }
  .products-grid {
    grid-column: 4 span;
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .container {
    padding: 0 var(--gutter);
    margin: 0 auto;
  }
  .products-grid {
    grid-column: 4 span;
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 576px) {
  .container {
    width: 400px;
  }
}
@media (max-width: 400px) {
  .container {
    width: 320px;
  }
}
.promotion {
  padding: 10px 0px 50px 0px;
}
.promotion__subitem {
  list-style-type: none;
  display: block;
  color: #222222;
  text-decoration: none;
  padding: 13px 12px 13px;
  border-bottom: 1px solid #e1ceb8;
  cursor: pointer;
}
.promotion__top {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding: 40px;
  max-width: 1400px;
}
.promotion__top-right {
  max-width: 556px;
  height: 437px;
}
.promotion__img-mobile {
  display: none;
}
.promotion__title {
  margin: 0;
  font-family: EB Garamond;
  font-size: 52px;
  font-weight: 600;
  line-height: 67.86px;
  text-align: center;
  color: #414141;
}
.promotion__date {
  margin: 0;
  font-family: EB Garamond;
  font-size: 24px;
  font-weight: 600;
  line-height: 31.32px;
  text-align: center;
  color: #414141;
}
.promotion__date a {
  color: #AF8150;
  text-decoration: underline;
}
.promotion__card {
  padding: 30px;
  background: #FFF8F0;
  border-radius: 10px;
  box-shadow: 10px 10px 5px 0px rgba(90, 90, 90, 0.27);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(90, 90, 90, 0.27);
  -moz-box-shadow: 10px 10px 5px 0px rgba(90, 90, 90, 0.27);
  margin: 0 auto;
  max-width: 1150px;
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;
}
.promotion__card-left {
  position: relative;
  min-height: 322px;
}
.promotion__link-hidden {
  display: none;
}
.promotion__card-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.promotion__card-decor {
  position: absolute;
  right: 10px;
  bottom: 0;
}
.promotion__name {
  margin: 0;
  color: #AF8150;
  font-size: 26px;
  font-weight: 600;
  line-height: 33.93px;
  text-align: left;
}
.promotion__name-hidden {
  display: none;
  margin: 0;
  color: #AF8150;
  font-size: 26px;
  font-weight: 600;
  line-height: 33.93px;
  text-align: center;
  margin-bottom: 20px;
}
.promotion__during {
  margin: 0;
  color: #AF8150;
  font-size: 28px;
  font-weight: 600;
  line-height: 38.13px;
  text-align: left;
}
.promotion__price-box {
  position: relative;
  min-width: 138px;
}
.promotion__price {
  margin: 0;
  color: #AF8150;
  font-size: 28px;
  font-weight: 600;
  line-height: 38.13px;
  text-align: left;
}
.promotion__price-old {
  position: absolute;
  top: 30px;
  left: 0;
  color: #414141;
  font-size: 10px;
  font-weight: 600;
  line-height: 13.62px;
  text-align: left;
}
.promotion__item {
  color: #414141;
  font-size: 18px;
  font-weight: 600;
  line-height: 24.51px;
  text-align: left;
}
.promotion__item:not(:last-child) {
  margin-bottom: 10px;
}
.promotion__card-img {
  border-top-left-radius: 170px;
  border-top-right-radius: 170px;
  width: 360px;
  height: 271px;
  margin-bottom: 10px;
}
.promotion__link {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 360px;
  height: 36px;
  border-radius: 10px;
  background: #85B998;
  color: #fff;
}
.promotion__list-bottom {
  margin: 0 auto;
  max-width: 1140px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}
.promotion__card-bottom {
  position: relative;
  padding: 60px 40px 20px 40px;
  border-top-right-radius: 170px;
  border-top-left-radius: 170px;
  width: 360px;
  height: 531px;
  background: #FFF8F0;
  margin-bottom: 10px;
}
.promotion__item-decor {
  position: absolute;
  bottom: 0;
  right: -20px;
}
.promotion__item-plant {
  position: absolute;
  top: 100px;
  right: 0;
}
.promotion__subtitle {
  padding-top: 20px;
  max-width: 668px;
  color: #414141;
  margin: 0 auto;
  font-size: 24px;
  font-weight: 600;
  line-height: 31.32px;
  text-align: center;
  margin-bottom: 30px;
}
.promotion__item-title {
  margin: 0;
  color: #AF8150;
  font-size: 18px;
  font-weight: 600;
  line-height: 23.49px;
  text-align: center;
}
.promotion__item-subtitle {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 18.27px;
  text-align: center;
  color: #AF8150;
  margin-bottom: 30px;
}
.promotion__item-sub {
  margin: 0;
  color: #414141;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: center;
  padding-top: 40px;
  margin-bottom: 20px;
}
.promotion__item-text {
  margin: 0;
  color: #414141;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: center;
  margin-bottom: 10px;
}
.promotion__item-text span {
  color: #AF8150;
}
.promotion__old-price {
  text-decoration: line-through;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
}
.promotion__item-percent {
  margin: 0;
  color: #AF8150;
  font-size: 28px;
  font-weight: 600;
  line-height: 38.13px;
  text-align: center;
  margin-bottom: 10px;
}
.promotion__sertificate-box {
  padding-top: 50px;
  margin: 0 auto;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  gap: 50px;
}
.promotion__sertificate-left {
  position: relative;
  min-height: 300px;
}
.promotion__sertificate-right {
  position: relative;
  min-height: 300px;
  width: 660px;
}
.promotion__sertificate-decor-2 {
  position: absolute;
  width: 427px;
  height: 340px;
  top: -60px;
  right: -120px;
}
.promotion__sertificate-decor {
  position: absolute;
  left: -70px;
  bottom: -50px;
}
.promotion__sertificate-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 660px;
  height: 249px;
  border-radius: 10px;
  margin-bottom: 10px;
  z-index: 2;
}
.promotion__sertificate-mobile {
  display: none;
  width: 255px;
  height: 296px;
  border-radius: 10px;
}
.promotion__sertificate-text {
  position: absolute;
  left: 0;
  bottom: 0;
}
.promotion__descr {
  margin: 0;
  max-width: 465px;
  color: #414141;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  margin-bottom: 10px;
}
.promotion__descr-sertificate {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.promotion__descr-bold {
  font-weight: 600;
  margin-bottom: 10px;
}
.promotion__sertificate-text {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #414141;
}
.promotion__link-sertificate {
  position: absolute;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #85B998;
  color: #fff;
  width: 260px;
  height: 36px;
  left: 0px;
  bottom: 10px;
  z-index: 2;
}

@media (max-width: 992px) {
  .promotion {
    padding-top: -10px;
  }
  .promotion__top {
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 0;
  }
  .promotion__card {
    flex-direction: column;
    align-items: center;
  }
  .promotion__sertificate-box {
    padding-top: 10px;
  }
  .promotion__sertificate-left {
    margin-bottom: 40px;
  }
  .promotion__bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .promotion__sertificate-text {
    left: 0;
    bottom: -10px;
  }
}
@media (max-width: 768px) {
  .promotion {
    padding-top: 10px;
  }
  .promotion__card-left {
    max-width: 560px;
  }
  .promotion__during {
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
  }
  .promotion__price {
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
  }
  .promotion__list {
    margin-bottom: 10px;
  }
  .promotion__sertificate-img {
    display: none;
  }
  .promotion__sertificate-mobile {
    display: block;
    margin: 0 auto;
    margin-top: -30px;
  }
  .promotion__sertificate-decor {
    display: none;
  }
}
@media (max-width: 576px) {
  .promotion {
    padding-top: -30px;
  }
  .promotion__card {
    box-shadow: none;
    padding: 10px;
  }
  .promotion__title {
    font-size: 18px;
    line-height: 25px;
  }
  .promotion__descr {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
  }
  .promotion__descr-bold {
    font-size: 12px;
    margin-bottom: 7px;
  }
  .promotion__text {
    font-size: 14px;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
  }
  .promotion__name {
    font-size: 18px;
    line-height: 20px;
  }
  .promotion__date {
    font-size: 18px;
    line-height: 22px;
  }
  .promotion__during {
    line-height: 20px;
    font-size: 16px;
    color: #000;
  }
  .promotion__price {
    font-size: 16px;
  }
  .promotion__card-top {
    display: block;
  }
  .promotion__name {
    padding-right: 20px;
  }
  .promotion__link-bottom {
    margin: 0 auto;
    display: flex !important;
    width: 300px !important;
  }
  .promotion__link-top {
    width: 360px !important;
  }
  .promotion__link {
    width: 300px;
  }
  .promotion__during {
    margin: 0px 10px 0px 0px;
  }
  .promotion__card-decor {
    display: none;
  }
  .promotion__link-hidden {
    display: flex;
  }
  .promotion__sertificate-box {
    gap: 0;
  }
  .promotion__sertificate-left {
    max-width: 300px;
  }
  .promotion__sertificate-decor-2 {
    position: absolute;
    top: -350px;
    right: 100px;
    width: 216px;
    height: 172px;
  }
  .promotion__sertificate-text {
    margin: 0 auto;
    position: static;
    max-width: 300px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .promotion__card-bottom {
    position: relative;
    padding: 60px 10px 10px 10px;
    border-top-right-radius: 170px;
    border-top-left-radius: 170px;
    width: 300px;
    height: 531px;
    background: #FFF8F0;
    margin-bottom: 10px;
  }
  .promotion__title-bottom {
    font-size: 34px;
    margin: 20px 0px 0px 0px;
  }
  .promotion__subtitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.88px;
    text-align: center;
  }
  .promotion__item-decor {
    position: absolute;
    bottom: -10px;
    right: -30px;
  }
}
@media (max-width: 431px) {
  .promotion__top {
    max-height: 470px;
  }
}
@media (max-width: 400px) {
  .promotion__top {
    max-height: 440px;
  }
  .promotion__card-img {
    max-width: 320px;
  }
  .promotion__link-top {
    width: 320px !important;
  }
}