:root {
	--gutter: 20px;
}

.nav {
  position: relative;
}




.container {
	width: 1244px;
	padding: 0 var(--gutter);
	margin: 0 auto;
}

.grid-container {
	width: 100%;
	display: grid;
	grid-gap: var(--gutter);
	grid-template-columns: repeat(4, 1fr);
}

.products-grid {
	grid-column: 4 span;
	display: grid;
	grid-gap: var(--gutter);
	grid-template-columns: repeat(4, 1fr);
}

.product {
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	padding: 20px;
	padding-top: 9px;
	font-size: 14px;
	background-color: #fff;
}

.product__image {
	position: relative;
	overflow: hidden;
	display: block;
	height: 162px;
}

.image-switch {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 20;
	width: 100%;
	height: 100%;
	display: flex;
}

.image-switch__item {
	flex-grow: 1;
	cursor: pointer;
}

.image-switch__img {
	position: absolute;
	left: 50%;
	top: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
	transform: translateX(-50%);
	pointer-events: none;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
}

.image-switch__img img {
	display: block;
	max-width: 100%;
	height: 100%;
	object-fit: cover;
}

.image-switch__item:first-child .image-switch__img {
	opacity: 1;
	z-index: -1;
}

.image-switch__item:hover .image-switch__img {
	opacity: 1;
	z-index: -1;
}

.image-pagination {
	position: absolute;
	z-index: 30;
	left: 0;
	bottom: 15px;
	width: 100%;
	display: flex;
	justify-content: center;
}

.image-pagination__item {
	display: block;
	width: 4px;
	height: 4px;
	border-radius: 100%;
	margin: 0 3px;
	background-color: #c4c4c4;
}

.image-pagination__item--active {
	background-color: #C06700;
}

.product__title {
	margin-bottom: 8px;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #000;
}

.product__props {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

.product__rating {
	margin-right: 10px;
	display: inline-flex;
	align-items: center;
	color: var(--color-accent);
	font-weight: 600;
}


.product__testimonials {
	color:  #C06700;
}

.product__info {
	margin-bottom: 17px;
	font-size: 12px;
	color:  #C06700;
}

.product__term {
	display: block;
	margin-bottom: 7px;
}

.product__available {
	display: block;
}

.product-price {
	margin-bottom: 17px;
	display: flex;
	align-items: center;
}

.product-price__current {
	margin-right: 12px;
	font-size: 24px;
	color: #000;
}

.product-price__old {
	font-size: 12px;
	color: #808080;
	text-decoration: line-through;
}
.product__btn{
  background-color:  #C06700;
}
.cart {
	position: relative;
	padding: 40px 19px;
	transition: background-color 0.3s;
}

.cart.active:hover {
	cursor: pointer;
	background: rgba(93, 113, 221, 0.1);
	transition: background-color 0.3s;
}

.header__cart {
	margin-left: 40px;
}

.cart__quantity {
	position: absolute;
	right: 2px;
	top: 50%;
	transform: translateY(-50%);
	margin-top: -10px;
	background-color: #de707b;
	border-radius: 100%;
	padding: 2px;
	color: #fff;
	font-size: 12px;
	min-height: 16px;
	min-width: 16px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.cart-content {
	cursor: default;
	width: 490px;
	position: absolute;
	top: 100%;
	right: 0;
	background-color: #fff;
	box-shadow: 0px 4px 4px rgba(135, 135, 135, 0.25);
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 150;
}

.cart.active:hover .cart-content {
	opacity: 1;
	visibility: visible;
	transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.content {
	padding-top: 90px;
}

.cart-content__list {
	max-height: 330px;
}

.cart-product {
	display: flex;
	align-items: center;
	padding: 12px 20px;
	padding-right: 13px;
	transition: background-color 0.3s;
}

.cart-product:hover {
	background: rgba(93, 113, 221, 0.1);
	transition: background-color 0.3s;
}

.cart-product__img {
	margin-right: 20px;
	max-width: 100px;
	max-height: 100px;
	object-fit: cover;
}

.cart-product__title {
	max-width: 300px;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #000;
	margin-bottom: 18px;
}

.cart-product__price {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 23px;
	color: #000;
}


.cart-content__bottom {
	padding: 20px;
	border-top: 1px solid #eaeaea;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.cart-content__fullprice {
	font-size: 14px;
	line-height: 20px;
	color: #000;
	display: flex;
	flex-direction: column;
}

.fullprice {
	margin-top: 10px;
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 23px;
	color: #000;
}

.cart-content__btn {
	display: inline-block;
	width: 246px;
	text-align: center;
  background:#C06700;
  color: #fff;
}
.show-more{
  margin-top: 50px;
  margin-left: 15px;
  width: 260px;
  height: 40px;
  border: 2px solid #C06700;
  color: #C06700;
  background-color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  text-align: center;
}


@media (max-width: (1250px)){

.container {
	width: 990px;
	padding: 0 var(--gutter);
	margin: 0 auto;
}

  .products-grid {
    grid-column: 4 span;
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: repeat(3, 1fr);
  }

}
@media (max-width: (992px)){

.container {
	width: 700px;
	padding: 0 var(--gutter);
	margin: 0 auto;
}

  .products-grid {
    grid-column: 4 span;
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: repeat(2, 1fr);
  }

}
@media (max-width: (768px)){

.container {
	// width: 400px;
	padding: 0 var(--gutter);
	margin: 0 auto;
}

  .products-grid {
    grid-column: 4 span;
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: repeat(1, 1fr);
  }

}
@media (max-width: (576px)) {
 .container{
  width: 400px;
 }
}
@media (max-width: (400px)){

.container {
	width: 320px;
}
}
