.popup-staf {
  background: #fff !important;
  padding: 30px;
  width: 100%;
  border-radius: 10px;

    @include small-tablet {
          padding-top: 50px;
        }
    @include  mobile{
      padding: 40px 10px 10px 10px;
    }
 &__close{
  right: 30px;
  top: 30px;
  color: #000;

    @include small-tablet {
      font-size: 12px;
    }
 }


&__top {
display: flex;
gap: 20px;
margin-bottom: 10px;

  @include small-tablet {
    gap: 5px;
  }
}

&__image {
border-radius: 10px;
min-width: 120px;
height: 180px;
  @include small-tablet {
    min-width: 80px;
    height: 120px;
  }
}
&__about{
  margin-left: 10px;
  background-image: url('../img/card-decor-2.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
}
&__name {
font-weight: 600;
font-size: 22px;
line-height: 26px;

@include small-tablet {
  font-size: 16px;
  line-height: 18px;
}
}
&__link{
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 35px;
  background-color: #C06700;
  color: #fff;
  border-radius: 4px;
  @include small-tablet {
    font-size: 14px;
    width: 80px;
    height: 30px;

  }
  @include hover {

      &:hover {
       background-color: #fff;
       color: #C06700;
       transition: background-color 0.4s ease-in;
      }

  }

}

&__post {
font-weight: 400;
font-size: 16px;
line-height: 20px;
color: rgba(20, 16, 36, 0.7);
margin-bottom: 20px;

@include small-tablet {
  font-size: 14px;
  line-height: 16px;
}
}


&__button-content{
padding: 20px 20px 0px 0px;
max-height: 375px;
}

&__descr {
margin-bottom: 40px;
}

&__text {
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
@include mobile{
  font-size: 16px;
  line-height: 130%;
}
}

&__subtitle {
font-weight: 600;
font-size: 24px;
line-height: 160%;
@include mobile{
  font-size: 20px;
}
}

.simplebar-track.simplebar-vertical{
margin: 20px 0px;
border-radius: 40px;
background:#dedce2;
width: 5px;
}
.simplebar-scrollbar::before{
left: 0;
right: 0;
opacity: 1;
width: 5;
border-radius: 40px;
background:#C06700 ;
}

}


