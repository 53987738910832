body {
  overflow-x: hidden;
}
body.open, body:after {
overflow: hidden
}

.b-nav, body:after {
position: absolute;
right: 0;
}
.b-brand, .b-link {
font-size: 18px;
font-weight: 700;
margin-left: 0;
text-decoration: none;
font-family: "Roboto Slab", serif;
text-transform: uppercase;
;
}
@-webkit-keyframes slideInLeft {
0% {
  -webkit-transform: translate3d(345px, 0, 0);
  transform: translate3d(345px, 0, 0);
  visibility: visible;
}
100% {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  visibility: visible;
}
}
@keyframes slideInLeft {
0% {
  -webkit-transform: translate3d(345px, 0, 0);
  transform: translate3d(345px, 0, 0);
  visibility: visible;
}
100% {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  visibility: visible;
}
}
* {
box-sizing: border-box
}
body:after {
background: #000;
content: '';
height: 100%;
left: 0;
opacity: 0;
padding: 0;
top: 0;
visibility: hidden;
-webkit-transition: all .6s ease;
transition: all .6s ease;
width: 100%;
}
body.open:after {
z-index: 999;
opacity: 0.65;
height: 100000%;
visibility: visible
}
.b-nav {
background:#fff none repeat scroll 0 0;
position: fixed;
top: 0;
width: 320px;
z-index: 9999999;
height: 100%;
overflow: auto;
}
.b-nav:not(.open) {
animation-duration: 0.4s;
animation-fill-mode: both;
-webkit-animation-name: slideOutLeft;
animation-name: slideOutLeft
}
.b-nav {
animation-duration: .4s;
animation-fill-mode: both;
-webkit-animation-name: slideInLeft;
animation-name: slideInLeft
}
.b-nav ul {
padding-left: 0px;
}





.b-nav:not(.open) {
visibility: hidden;
animation-duration: 0.4s;
animation-fill-mode: backwards;
-webkit-animation-name: slideOutLeft;
animation-name: slideOutLeft
}

.b-link {
background: 0 0;
border-left: rgba(255, 255, 255, 0)solid 2px;
color: #212121;
transition: all .4s ease;
width: auto
}
.b-link, .b-menu {
-webkit-transition: all .4s ease;
}


/*.b-link--active,
.b-link:hover {
  border-left: #e00a12 solid 5px;
  padding-left: 30px
}*/

#menuheader .b-menu {
  cursor: pointer;
  display: block;
  height: 27px;
  position: absolute;
  top: 20px;
  transition: all 0.4s ease 0s;
  width: 27px;
  z-index: 999999999;
  right: 46px;
}
.b-bun--bottom, .b-bun--mid, .b-bun--top {
height: 2px;
width: 25px
}
.b-container.open .b-main, .b-menu:hover {}

.b-bun {
background: #7D7D7D;
transition: all .4s ease
}

#menuheader.menuactive .b-menu .b-bun{
background: #1f8ecf;
}

#menuheader.menuactive .b-menu {
top: 21px;
}

.b-container.open .b-menu {
  top: 4px !important;
  right:25px !important;
}


.b-brand, .b-bun {
position: relative;
-webkit-transition: all .4s ease
}
.b-bun--top {
top: 0
}
.b-bun--mid {
top: 8px
}
.b-bun--bottom {
top: 16px
}
.b-brand {
color: #2196f3;
top: -21.43px;
transition: all .4s ease;
z-index: 13
}
.b-container {
position: absolute;
right: 0;
top: 20px;
}
.b-container:hover:not(.open) .bun-bottom, .b-container:hover:not(.open) .bun-mid, .b-container:hover:not(.open) .bun-top {
background: #2196f3
}
.b-container.open .b-bun--top {
background: #414141;
top: 9px;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg)
}
.b-container.open .b-bun--mid {
opacity: 0
}
.b-container.open .b-bun--bottom {
background: #414141;
top: 5px;
-webkit-transform: rotate(-45deg);
-ms-transform: rotate(-45deg);
transform: rotate(-45deg)
}
.b-container.open .b-brand {
color: #fff
}
.mobile-search-btn {
   background-color: #414141;
  border-radius: 4px;
  color: #fff;
  margin-bottom: 25px;
  margin-left: 30px;
  padding: 10px 20px;
  text-transform: uppercase;
border:none;
}
.mobile-search {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #fff;
  color: #949494;
  font-size: 14px;
  margin-bottom: 20px;
  margin-left: 30px;
  padding: 10px;
  width: 80%;
}
.sign-fj85{
display: flex;
flex-wrap: wrap;
justify-content: space-between;
margin: 10px 0 0 0;
}
.sign-fj85 li{
line-height: 22px;
width: 50%;
}
.sign-fj85 li a{
display:block;
line-height: 22px;
color: #1f8ecf;
padding: 15px 0 15px 15px;
background: #f2faff;
}
.sign-fj85 li a:hover{
background: #e5f3fb;
}

@media screen and (max-width: 767px) {

  #menuheader .b-menu {
    top: 10px;
    right: 15px;
  }
  .b-nav {
    padding-top: 35px;
    }

}



