.spa{

  &__container{
    margin: 0 auto;
    max-width: 1400px;
    padding: 10px;
  }


  &__list{
    margin: 0 auto;
    max-width: 1000px;
    padding: 0px 15px;
    margin-top: 40px;
  }
  &__link{
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 220px;
    height: 40px;
    border: 2px solid #9d640e;
    font-size: 16px;
    color: #9d640e;
    font-weight: 700;

  }
  &__right{
    max-width: 600px;
  }
  &__img{
    border-radius: 10px;
    object-fit: cover;
    width: 220px;
    height: 170px;

    &-staff{
      width: 250px;
      height: 300px;
    }
  }
  &__item{
    border-radius: 10px;
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

  }
}

@media (max-width: (768px)) {

  .spa__item{
    margin: 0 auto;
    flex-direction: column-reverse;
    align-items: center;
    max-width: 400px;
    gap: 0;
    margin-bottom: 20px;
  }
  .spa__link{
    margin-bottom: 20px;
    width: 400px;

  }
  .spa__img{
    width: 400px;
    height: 300px;
  }
}
@media (max-width: (400px)){
  .spa__item{
    max-width: 350px;
  }
  .spa__link{
    margin-bottom: 20px;
    width: 350px;

  }
  .spa__img{
    width: 350px;
    height: 230px;
  }
}
@media (max-width: (360px)){
  .spa__item{
    max-width: 320px;
  }
  .spa__link{
    margin-bottom: 20px;
    width: 320px;

  }
  .spa__img{
    width: 320px;
    height: 230px;
  }
}

