.abt-ban85 {
  position: relative;
  min-height: 150px;
}
.abt-ban85 .abt-ban86 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b6835b;
}
.abt-ban85 .abt-ban87-1:hover{
  color:#fff;
  opacity:0.6;
}
.main-serdlts-1{
  display:block;
}

.serdetg-sec2 .serdetg-sec2-1 img{
  max-width: 100%;
  margin-bottom: 40px;
}
.serdetg-sec2 .serdetg-sec2-1{
  padding-right: 30px;
}
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-1{
  padding: 14px 20px 15px;
  background: #c73f29;
}

.serdetg-sec2 .serdert-sec .serdert-sec1{
  background: #ffeade;
}
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2{
  padding: 20px 10px 20px;
}
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-3,
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-4,
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-13{
  width: 100%;
  padding: 10px 10px 10px;
  border: 1px #edcfbe solid;
  margin-bottom: 11px;
}
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-5 label,
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-6 h6,
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-11 label,
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-12 label{
  width: 100%;
  margin-bottom: 10px;
}
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-5 input,
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-11 input,
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-10 input{
  width: 100%;
  padding: 10px 10px 10px;
  border: 1px #edcfbe solid;
  margin-bottom: 12px;
}
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-6{
  margin-bottom: 10px;
}
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-6 .serdert-sec1-out{
  display:flex;
}
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-6 .serdert-sec1-out .serdert-sec1-7,
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-6 .serdert-sec1-out .serdert-sec1-8{
  margin-right: 10px;
}
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-12 select{
  width: 100%;
  padding: 8px 10px 8px;
  border: 1px #edcfbe solid;
  margin-bottom: 10px;
}
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-14{
  padding: 11px 15px;
  margin-top: 5px;
  background: #C06700;
  border: 1px #C06700 solid;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}
.serdetg-sec2 .serdert-sec .serdert-sec1 .serdert-sec1-2 .serdert-sec1-14:hover {
  border: 1px #df8011 solid;
  background: #df8011;
}
.serdert-sec1-2 input:focus,
.serdert-sec1-2 select:focus{
  outline: 0;
  border: 1px #edb595 solid !important;
}
.serdert-sec .vid-to1{
  margin-bottom: 30px;
}
.serdert-sec .vid-to1 iframe{
  width: 100% !important;
  height: 240px !important;
}


.serdetg-sec2 .tre-re-out12{
    margin-bottom:80px;
}


@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 991px) {
  .serdetg-sec2 .serdetg-sec2-1 {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) {

  .serdetg-sec1{
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .serdetg-sec2 .serdetg-sec2-1 img {
    margin-bottom: 25px;
  }
  .serdetg-sec1 .serdetg-sec1-2{
    font-size: 26px;
    line-height: 30px;
  }
  .serdetg-sec2 .serdetg-sec2-1 .serdetg-sec2-2{
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px;
  }
  .serdetg-sec2 .serdetg-sec2-1 .serdetg-sec2-3{
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 25px;
  }
  .serdetg-sec2 .serdetg-sec2-1 {
    padding-bottom: 10px;
  }
  .serdetg-sec2{
    padding-bottom: 25px;
  }
  .abt-ban85 .abt-ban86 .abt-ban87{
    font-size: 16px;
    line-height: 22px;
  }

}

@media screen and (max-width: 600px) {

    .serdetg-sec1 .serdetg-sec1-2 {
        font-size: 24px;
    }
    .serdetg-sec1 {
        padding-bottom: 15px;
    }
    .serdetg-sec2 .serdetg-sec2-1 .serdetg-sec2-2 {
        font-size: 18px;
        margin-bottom: 10px;
    }
  .serdetg-sec2 {
    // padding-left: 10px;
    // padding-right: 10px;
  }

}
