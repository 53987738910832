
.menu {
  height: auto;
  margin: 67px 0 0 0;
}
.menu__list {
  list-style-type: none;
  width: 100%;
  display: inline-block;
  background-color: #fff;
  margin: 0;
  padding: 13px 0 0;
}
.menu__item {
  color: #515151;
  display: block;
  width: 100%;
  height: 100%;
  padding: 13px 15px 13px;
  text-decoration: none;
  border-bottom: 1px solid #eee8e0;
  cursor: pointer;
}
.menu__item:hover{
  color:#c06700;
}
.menu__item--active {
  position: relative;
}
.menu__item--active:before {
  content: "";
  display: block;
  width: 1px;
  height: 1px;
  color: #000;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -7px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #c06700;
}
.menu__item--open:before {
  margin-top: -3px;
  border-top: 5px solid #c06700;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.menu__sublist {
  display: none;
  list-style-type: none;
  background-color: #f9efe3;
  margin: 0;
  padding: 0;
}
.menu__sublist .menu__sublist {
  background-color: #888;
}
.menu__sublist .menu__sublist .menu__sublist {
  background-color: #111;
}
.menu__subitem {
  list-style-type: none;
  display: block;
  color: #222222;
  text-decoration: none;
  padding: 13px 12px 13px;
  border-bottom:1px solid #e1ceb8;
  cursor: pointer;
}
.sign-fj85{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 0 0 0;
}
.sign-fj85 li{
  line-height: 22px;
  width: 100%;
  text-align: center;
}
.sign-fj85 li a{
  display: block;
  line-height: 22px;
  color: #ffffff;
  padding: 15px 0 15px 15px;
  background: rgb(192, 103, 0);
  margin: 0 10px;
  border-radius: 6px;
}
.sign-fj85 li a:hover{
  background: rgb(163 93 12);
}
