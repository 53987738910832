
.abt-ban85 {
  position: relative;
  min-height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
}
.abt-ban85 .abt-ban86 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.test-sec130{
  background: #f5e8d9;
}
.test-sec130 .test-sec130-2{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-column-gap: 40px;
  grid-row-gap: 75px;
  flex-wrap: wrap;
}
.test-sec130 .test-sec130-2 .test-sec130-3{
  background: #fff;
  padding: 40px 31px 25px;
  box-shadow: 3px 4px 10px #00000021;
}
.test-sec130 .test-sec130-2 .test-sec130-3 .test-sec130-5 .test-sec130-7{
  position: relative;
}
.test-sec130 .test-sec130-2 .test-sec130-3 .test-sec130-4{
  display:flex;
  align-items:center;
}
.test-sec130 .test-sec130-2 .test-sec130-3 .test-sec130-4 .test-sec130-5{
  width: 55px;
  min-width: 55px;
  height: 55px;
  border-radius: 100%;
  border: 1px #ddd solid;
  overflow: hidden;
  margin-right: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.test-sec130 .test-sec130-2 .test-sec130-3 .test-sec130-5 .test-sec130-7 .test-sec130-10{
  margin: -60px 0 0 -13px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
}
.test-sec130 .test-sec130-2 .test-sec130-3 .test-sec130-5 .test-sec130-7 .test-sec130-8{
  margin-bottom: 25px;
}
.test-sec130 .test-sec130-2 .test-sec130-3 .test-sec130-5 .test-sec130-7 .test-sec130-10 img{
  width: 65px;
}


@media screen and (max-width: 991px) {

  .test-sec130 .test-sec130-2{
      grid-template-columns: repeat(2,1fr);
  }
}

@media screen and (max-width: 767px) {
  .test-sec130 .test-sec130-2 {
      grid-template-columns: repeat(1,1fr);
      grid-row-gap: 50px
  }
  // .test-sec130{
  //     padding-top: 40px !important;
  //     padding-bottom: 40px !important;
  //     padding-left: 10px !important;
  //     padding-right: 10px !important;
  // }
  .test-sec130 .test-sec130-1{
      margin-bottom: 60px;
  }
}
