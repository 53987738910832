.price-list{
  padding: 0px 0px 30px 0px;
  margin-bottom: 0;

  &__accorioon-btn{
    font-size: 16px !important;
  }
}
.price{
  padding: 0px 15px !important;
}

table{/* border: 1px solid #fff; */width:100%;margin-top: 10px;}
table tr{/* border: none; */border-radius: 10px;}
table td{
  border: 1px solid #000;
  padding: 8px;
  width: 100px !important;

  a{
    color: #20c997;
    text-decoration: underline;
    text-transform: uppercase;
  }
}
tbody tr:nth-child(2n) {
  background: #f1f1f1;
}
.button{
  background-color: #1c87c9;
  -webkit-border-radius: 60px;
  border-radius: 60px;
  border: none;
  color: #eeeeee;
  cursor: pointer;
  display: inline-block;
  font-family: sans-serif;
  font-size: 20px;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  }
  @keyframes glowing {
    0% { background-color: #2ba805; box-shadow: 0 0 3px #2ba805; }
    50% { background-color: #49e819; box-shadow: 0 0 10px #49e819; }
    100% { background-color: #2ba805; box-shadow: 0 0 3px #2ba805; }
    }
    .button {
      animation: glowing 1300ms infinite;
  }

  @media (max-width: (768px)) {
    .price-list__accorioon-btn{
      font-size: 14px !important;
    }
    table{
      font-size: 12px !important;
    }
  }
