.banner{
  background-size: cover !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box{
  margin: 0px;
}
.abt-ban85 {
  position: relative;
  min-height: 250px !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
}

.abt-ban85 {
  position: relative;
  // min-height: 200px;
}
.abt-ban85 .abt-ban86 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b6835b;
}
.abt-ban85 .abt-ban86 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.com-prov94{
  background: #fbf2eb;
}
.com-prov94 .acci-f1 .accordion-button:not(.collapsed){
  color: #fff;
  background-color: #c18659;
}
.com-prov94 .acci-f1 .accordion-button:focus{
  outline:0;
  box-shadow: none;
}
.com-prov94 .acci-f1 .accordion-button:hover{
  color: #fff;
  background-color: #c18659;
}
.com-prov94 .acci-f1 .accordion-button:after {
background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
.com-prov94 .com-prov94-1{
  margin-right: 50px;
}
.com-prov94 .com-prov94-1 img{
  max-width:100%;
}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 767px) {
  .abt-ban88 .abt-ban89{
      text-align: left !important;
  }
  .com-prov94 .com-prov94-1{
      text-align: left;
      margin-top: 30px;
  }
  .abt-ban88{

      // padding-top: 25px !important;
      // padding-left: 10px !important;
      // padding-right: 10px !important;
  }
  .com-prov94 {
      margin: 0;
      padding-top: 50px;
      padding-bottom: 50px;
      padding-left: 20px;
      padding-right: 20px;
  }
  .com-prov94 .com-prov94-1 {
      margin-right: 0;
  }
  .com-prov94 .com-prov94-1 img {
      max-width: 50%;
  }
  .com-prov94 .acci-f1{
      margin: 50px 0 0 0;
  }
  .com-prov94{
      margin-top: 30px !important;
      padding-top: 25px !important;
      padding-bottom: 30px !important;
      padding-left: 10px;
      padding-right: 10px;
  }
  .abt-ban88 .abt-ban89 h2{
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 15px;
  }
  .abt-ban88 .abt-ban89 p{
      line-height: 24px;
  }
  .com-prov94 .com-prov95 .com-prov96{
      font-size:24px;
      margin-bottom:15px;
      line-height: 30px;
  }
  .com-prov94 .com-prov95 .com-prov98{
      line-height: 24px;
  }




}
