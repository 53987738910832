
.galry-sec131 .gelar-outdn{
  display: flex;
  background: rgb(192,103,0);
  background: linear-gradient(326deg, rgba(192,103,0,1) 0%, rgba(255,239,223,1) 100%);
}
.galry-sec131 .gelar-outdn:hover{
  background: rgb(192,103,0);
  background: linear-gradient(266deg, rgba(192,103,0,1) 0%, rgba(255,239,223,1) 100%);
}
.galry-sec131 .gelar-outdn .galry-sec131-2{
  width:50%;
  display: flex;
}
.galry-sec131 .galry-sec131-2 img{
  max-width:100%;
}
.galry-sec131 .galry-sec131-1{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}
.galry-sec131 .galry-sec131-1 .galry-sec131-5 .galry-sec131-3{
  color: #ffffff;
  text-shadow: 2px 2px 3px #7949136e;
  margin-bottom:0;
}

.abt-ban85 {
  position: relative;
  min-height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
}
.abt-ban85 .abt-ban86 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.galry-sec132 .galry-sec131{
  margin-bottom:50px;
}


.gary-dets133 .demo-gallery #lightgallery{
  display: grid;
  grid-template-columns: repeat(5,1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-wrap: wrap;
}


@media screen and (max-width: 991px) {

  .galry-sec131 .galry-sec131-1 .galry-sec131-5 .galry-sec131-3 {
      font-size: 24px;
      line-height: 30px;
  }


      .gary-dets133 .demo-gallery #lightgallery{
          grid-template-columns: repeat(4,1fr);
      }

}

@media screen and (max-width: 767px) {

  .galry-sec132 .galry-sec131 {
      margin-bottom: 20px;
  }
  .galry-sec132{
      padding-top: 25px !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
      padding-bottom: 10px !important;
  }
  .galry-sec131 .galry-sec131-1 .galry-sec131-5 .galry-sec131-3 {
      font-size: 16px;
      line-height: 22px;
  }
  .galry-sec132 .galry-sec132-1{
      font-size: 22px;
      margin-bottom: 25px;
      line-height: 26px;
  }

  .gary-dets133{
      padding-bottom: 25px !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
  }
  .gary-dets134{
      padding-top: 25px !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
  }

  .gary-dets133 .demo-gallery #lightgallery{
      grid-template-columns: repeat(2,1fr);
      grid-column-gap: 5px;
      grid-row-gap: 0px;
  }
  .demo-gallery > ul > li {
      margin-bottom: 3px !important;
  }
  .gary-dets134 .gary-dets134-1{
      font-size: 22px;
      margin-bottom: 25px;
      line-height: 26px;
  }

}
