.slide-box{
  margin: 40px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.slide-section{
  background-color: #fff !important;
}
.slide-text{
  color: #B68E62;
  font-size: 18px;
}

.slide-title{
  font-size: 38px;
  text-align: center;
  color: #B68E62;
}
.treatement-container{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 0px 20px;
}

@include small-tablet {
  .slide-box{
    margin-bottom: 20px;
  }
  .slide-title{
    font-size: 22px !important;
  }
  .slide-text{
    font-size: 12px;
  }
  .slide-section{
    padding-bottom: 10px !important;
    background-color: #fff !important;
  }
}
@media (max-width: (1200px)) {
 .treatement-container{
  grid-template-columns: repeat(3, 1fr);
 }
}
@media (max-width: (991px)) {
  .treatement-container{
   grid-template-columns: repeat(2, 1fr);
  }
 }
 @media (max-width: (768px)) {
  .treatement-container{
   grid-template-columns: repeat(1, 1fr);
  }
 }

