.abt-ban85 {
  position: relative;
  min-height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
}
.abt-ban85 .abt-ban86 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}


.bl-sec116 .bl-sec117{
  display: grid;
  background: #fff;
}

.bl-sec116 .bl-sec117 .bl-sec123{
  padding: 25px 0 29px;
}
.bl-sec116 .bl-sec117 .bl-sec123 .bl-sec118{
  margin-bottom:5px;
}
.bl-sec116 .bl-sec117 .bl-sec121{
  display: flex;
  margin-top: auto;
}
.bl-sec116 .bl-sec124{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-wrap: wrap;
}
.bl-sec116 .bl-sec124 .bl-sec117 .bl-sec123 .bl-sec122:hover{
  color: #1799e8;
}

/* Blog details Start */

.blg-de-fj125{
  padding-top: 70px;
}
.blg-degh126 .blg-degh126-9 .blg-degh126-10 img{}

.blg-de-fj125 .blg-de-fj125-1 .blg-de-fj125-2{
  display:flex;
  justify-content: center;
}
.blg-de-fj125 .blg-de-fj125-1 .blg-de-fj125-2 .blg-de-fj125-3{
  margin-right:5px;
}
.blg-de-fj125 .blg-de-fj125-6 .blg-de-fj125-7{
  color:#C06700;
}

.blg-de-fj125 .blg-de-fj125-1{
  display: grid;
  height: 100%;
}
.blg-de-fj125 .blg-de-fj125-1 .blg-de-fj125-8{
  margin-top: auto;
}

.blg-degh126 .blg-degh126-1 .blg-degh126-3 .blg-degh126-4{
  display: flex;
  padding-bottom: 24px;
  border-bottom: 1px #ddd solid;
  margin-bottom: 20px;
}
.blg-degh126 .blg-degh126-1 .blg-degh126-3 .blg-degh126-4 .blg-degh126-5{
  width: 128px;
  max-width: 128px;
  min-width: 120px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.blg-degh126 .blg-degh126-1 .blg-degh126-3 .blg-degh126-4 .blg-degh126-6{
  padding: 0 10px 0px 20px;
}
.blg-degh126 .blg-degh126-1 .blg-degh126-3 .blg-degh126-4 .blg-degh126-6:hover .blg-degh126-7{
  transition: all 0.2s ease-in-out;
}
.blg-degh126 .blg-degh126-1 .blg-degh126-3 .blg-degh126-4 .blg-degh126-6:hover .blg-degh126-7{
  color:#919191;
}
.blg-degh126 .blg-degh126-1 {
  display: flex;
  justify-content: center;
}

/* Blog details End */


@media screen and (max-width: 1199px) {
  .bl-sec116 .bl-sec124 {
      grid-template-columns: repeat(3,1fr);
  }
}

@media screen and (max-width: 991px) {
  .bl-sec116 .bl-sec124 {
      grid-template-columns: repeat(2,1fr);
  }

/* Blog details Start */
  .blg-degh126 .blg-degh126-1 .blg-degh126-3 .blg-degh126-4 .blg-degh126-5 {
      width: 65px;
      max-width: 65px;
      min-width: 65px;
      height: 107px;
  }
  .blg-degh126 .blg-degh126-1 .blg-degh126-3 .blg-degh126-4 .blg-degh126-6 .blg-degh126-7{
      font-size: 14px;
      line-height: 18px;
  }
/* Blog details End */

}

@media screen and (max-width: 767px) {
  .bl-sec116 .bl-sec124 {
      grid-template-columns: repeat(1,1fr);
  }


  /* Blog details Start */
  .blg-degh126{
      padding-top: 25px !important;
      padding-bottom: 25px !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
  }
  .blg-degh126 .blg-degh126-9 p{
      font-size: 14px;
      text-align: left;
      line-height: 24px;
  }
  .blg-degh126 .blg-degh126-9 .blg-degh126-10{
      margin-bottom:20px;
  }
  .blg-de-fj125{
      padding-bottom: 0 !important;
      padding-top: 25px !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
  }
  .blg-degh126 .blg-degh126-2{
      font-size: 22px;
      margin-bottom: 35px;
      margin-top: 45px;
      text-align: left !important;
  }
  .blg-degh126 .blg-degh126-9{
      padding-left: 0;
  }
  .blg-de-fj125 {
      padding-top: 97px;
      padding-bottom: 50px;
  }
  .blg-de-fj125 .blg-de-fj125-6{
      padding-left: 0;
  }
  .blg-de-fj125 .blg-de-fj125-7{
      font-size: 24px;
      line-height: 30px;
      margin-top: 0px;
  }
  .blg-de-fj125 .blg-de-fj125-1 .blg-de-fj125-2 {
      margin-bottom: 5px;
  }
  .blg-degh126 .blg-degh126-1 .blg-degh126-3 .blg-degh126-4 .blg-degh126-5 {
      width: 128px;
      max-width: 128px;
      min-width: 120px;
      height: 100px;
  }
  .blg-degh126 .blg-degh126-1 .blg-degh126-3 .blg-degh126-4 .blg-degh126-6 .blg-degh126-7{
      font-size: 16px;
      line-height: 20px;
  }
  .blg-degh126 .blg-degh126-1 .blg-degh126-3 .blg-degh126-4 .blg-degh126-6 .blg-degh126-8{
      font-size: 12px;
  }
  .blg-degh126 .blg-degh126-1 .blg-degh126-3 .blg-degh126-4 .blg-degh126-6 {
   padding: 0 10px 0px 10px;
  }
  /* Blog details End */


}
