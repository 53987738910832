.trainers{
  position: relative;
  padding-top: 130px;
  &__content{
    margin: 0 auto;
    padding: 15px 0px;
    max-width: 1100px;
    position: relative;
  }
  &__title{
    font-size: 38px;
    position: absolute;
    top: 30px;
    left: 50px;
    padding: 30px 0px;

    @include small-tablet {
      top: 0px;
      left: 20px;
      font-size: 22px;
    }
  }
  &__link{
    position: absolute;
    right: 50px;
    top: 30px;
    padding: 40px 0px 30px 0px;

    @include small-tablet {
      top: 0px;
      right:20px;

    }
  }
  &__image{
    width: 250px;
    height: 300px;
    border-radius: 20px;
    margin-bottom: 20px;
  }
  &__name{
    margin: 0;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 20px;

     }
  &__post{
    margin: 0;
    max-width: 300px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-color);
  }
  &__btn{
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #C05F30;
    margin-bottom: 55px;


  @include small-tablet {
    margin-bottom: 30px;
  }
  }
  &__scroll{
    width: 360px;
    height: 5px;
    background: rgba(20, 16, 36, 0.08);
    border-radius: 40px;
    margin-bottom: 58px;


  @include small-tablet {
    margin-bottom: 30px;
  }
  }
  &__nav{
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    max-width: 1100px;
  }
  &__btns{
   display: flex;
   gap: 5px;
  }
  .swiper-scrollbar-drag{
    background: #6c757d;
  }
  .swiper-button-prev{
    top: 37%;
    border: none;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    background-image: url("../img/tre-left-arrow.png");
    background-size: contain;

  &::after{
    display: none;
  }
  }
  .swiper-button-next{
    border: none;
    top: 37%;
    right: 30px;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    background-image: url('../img/tre-right-arrow.png');
    background-size: contain;

  &::after{
    display: none;
  }
  }
  &__staff-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    cursor: pointer;
  }
  &__staff-img{
    width: 100px;
    height: 100px;
    position: absolute;
    top: 10px;
    left: 10px;
  }

}
@include small-tablet {
  .swiper-wrapper{
    margin: 0 auto;
    max-width: 650px;
  }
  .trainers{
    padding-top: 60px;
  }
}

@include mobile{
  .swiper-wrapper{
    margin: 0 auto;
    max-width: 450px;
  }
 .trainers__image{
    width: 180px;
    height: 210px;
 }
 .trainers__name{
  font-size: 14px;
  line-height: 18px;
  max-width: 180px;
  margin-bottom: 5px;
 }
 .trainers__post{
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 5px;
 }
.swiper-button-prev{
  top: 37%;
  border: none;
  width: 25px !important;
  height: 25px !important;
  border-radius: 100%;
  background-image: url("../img/tre-left-arrow.png");
  background-size: contain;

&::after{
  display: none;
}
}
.swiper-button-next{
  border: none;
  top: 37%;
  right: 5px !important;
  width: 25px !important;
  height: 25px !important;
  border-radius: 100%;
  background-image: url('../img/tre-right-arrow.png');
  background-size: contain;

    &::after{
      display: none;
    }
  }
}
