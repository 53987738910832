.abt-ban85 {
  position: relative;
  min-height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
}
.abt-ban85 .abt-ban86 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-catlt-1{
  display:block;
}
.cat-treng-sec1 .cat-treng-sec1-1 img{
  max-width: 100%;
}
.cat-treng-sec1 .cat-treng-sec1-2 .cat-treng-sec1-3{
  color: #C06700;
}
.cat-treng-sec1 .cat-treng-sec1-2 .cat-treng-sec1-5{
  color: #959191;
}
.cat-treng-sec2 .cat-treng-sec2-2{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-column-gap: 20px;
  grid-row-gap: 60px;
}

.cat-treng-sec2-3 .cat-treng-sec2-4 .cat-treng-sec2-7 .cat-treng-sec2-5{
  width:65px;
  height: 65px;
  margin:  0 auto;
  border-radius: 100px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: all 0.6s ease-in-out;
}
.cat-treng-sec2-3 .cat-treng-sec2-4 .cat-treng-sec2-7{
  margin: -50px 0 0 0;
}
.cat-treng-sec2-3 .cat-treng-sec2-4{
  display: block;
  background-color: #b76e34;
  border-bottom-right-radius: 40px;
  padding: 20px 10px 20px;
  transition: all 0.2s ease-in-out;
}
.cat-treng-sec2-3 .cat-treng-sec2-4:hover{
  background: #d58341;
}
.cat-treng-sec2-3 .cat-treng-sec2-4:hover .cat-treng-sec2-7 .cat-treng-sec2-5{
  transform: rotate(90deg);
}
.tre-pro-fk{
    list-style: disc;
    padding: 25px 0 25px 25px;
}
.tre-pro-fk li{
    margin-bottom:5px;
}


@media screen and (max-width: 1024px) {

  .cat-treng-sec2 .cat-treng-sec2-2{
    grid-template-columns: repeat(3,1fr);
  }

}

@media screen and (max-width: 767px) {

  .cat-treng-sec2 .cat-treng-sec2-2{
    grid-template-columns: repeat(2,1fr);
  }
  .cat-treng-sec1 .cat-treng-sec1-1{
    width: 50%;
    margin-bottom: 40px;
  }
  .cat-treng-sec1{
    padding-top: 25px;
    padding-bottom: 25px;
    // padding-left: 10px;
    // padding-right: 10px;
  }
  .cat-treng-sec1 .cat-treng-sec1-2 .cat-treng-sec1-4{
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 15px;
  }
  .cat-treng-sec2{
    padding-top: 0;
    padding-bottom: 25px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 3px;
  }
  .cat-treng-sec2 .cat-treng-sec2-1{
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 55px;
    text-align: left !important;
  }
  .cat-treng-sec2-3 .cat-treng-sec2-4 .cat-treng-sec2-7 .cat-treng-sec2-5 {
    width: 55px;
    height: 55px;
  }
  .cat-treng-sec2-3 .cat-treng-sec2-4 .cat-treng-sec2-6 {
    font-size: 15px;
    line-height: 22px;
  }
  .cat-treng-sec1 .cat-treng-sec1-2 .cat-treng-sec1-3 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .cat-treng-sec1 .cat-treng-sec1-2 .cat-treng-sec1-5 {
    font-size: 14px;
    line-height: 24px;
  }

}

@media screen and (max-width: 600px) {

    .cat-treng-sec1 .cat-treng-sec1-2 .cat-treng-sec1-4 {
        font-size: 24px;
        line-height: 30px;
    }
    .cat-treng-sec2 .cat-treng-sec2-1 {
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 45px;
    }
    .cat-treng-sec2-3 .cat-treng-sec2-4 .cat-treng-sec2-6 {
        font-size: 14px;
    }
}
