
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600&display=swap');

.font-opensans{
  font-family: 'Open Sans', sans-serif;
}
.font-ebgaramond{
  font-family: 'EB Garamond', serif;
}


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section{
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a:hover{
  transition:all 0.2s ease-in-out;
}

.color-white{
  color:#fff;
}

.color-black{
  color:#212121;
}
.color-black2{
  color:#414141;
}
.color-brown{
  color:#C05F30;
}
.color-blue{
  color: #1799E8;
}
.color-gray-p{
  color: #828282;
}

.font-size-12{
  font-size: 12px;
}
.font-size-13{
  font-size: 13px;
}
.font-size-14{
  font-size: 14px;
}
.font-size-15{
  font-size: 15px;
}
.font-size-16{
  font-size: 16px;
}
.font-size-17{
  font-size: 17px;
}
.font-size-18{
  font-size: 18px;
}
.font-size-19{
  font-size: 19px;
}
.font-size-20{
  font-size: 20px;
}
.font-size-21{
  font-size: 21px;
}
.font-size-22{
  font-size: 22px;
}
.font-size-24{
  font-size: 24px;
}
.font-size-25{
  font-size: 25px;
}
.font-size-26{
  font-size: 26px;
}
.font-size-28{
  font-size: 28px;
}
.font-size-30{
  font-size: 30px;
}
.font-size-32{
  font-size: 32px;
}
.font-size-34{
  font-size: 34px;
}
.font-size-36{
  font-size: 36px;
}
.font-size-38{
  font-size: 38px;
}
.font-size-42{
  font-size: 42px;
}

.line-height-18{
  line-height: 18px;
}
.line-height-24{
  line-height: 24px;
}
.line-height-26{
  line-height: 26px;
}
.line-height-28{
  line-height: 28px;
}
.line-height-30{
  line-height: 30px;
}
.line-height-33{
  line-height: 33px;
}
.line-height-40{
  line-height: 30px;
}
.line-height-50{
  line-height: 50px;
}


.font-weight-300{
  font-weight: 300;
}
.font-weight-400{
  font-weight: 400;
}
.font-weight-500{
  font-weight: 500;
}
.font-weight-600{
  font-weight: 600;
}


.padding-right-0{
  padding-right: 0px;
}
.padding-right-40{
  padding-right: 40px;
}
.padding-right-50{
  padding-right: 50px;
}

.padding-left-0{
  padding-left: 0px;
}
.padding-left-20{
  padding-left: 20px;
}
.padding-left-40{
  padding-left: 40px;
}

.padding-top-100{
  padding-top: 100px;
}
.padding-top-70{
  padding-top: 70px;
}
.padding-top-60{
  padding-top: 60px;
}
.padding-top-50{
  padding-top: 50px;
}

.padding-bottom-100{
  padding-bottom: 100px;
}
.padding-bottom-50{
  padding-bottom: 50px;
}
.padding-bottom-60{
  padding-bottom: 60px;
}
.padding-bottom-70{
  padding-bottom: 70px;
}


.margin-top-100{
  margin-top: 100px;
}
.margin-top-70{
  margin-top: 70px;
}
.margin-top-60{
  margin-top: 60px;
}
.margin-top-50{
  margin-top: 50px;
}
.margin-top-35{
  margin-top: 35px;
}
.margin-top-25{
  margin-top: 25px;
}
.margin-top-24{
  margin-top: 24px;
}
.margin-top-15{
  margin-top: 15px;
}

.margin-bottom-100{
  margin-bottom: 100px;
}
.margin-bottom-80{
  margin-bottom: 80px;
}
.margin-bottom-70{
  margin-bottom: 70px;
}
.margin-bottom-50{
  margin-bottom: 50px;
}
.margin-bottom-40{
  margin-bottom: 40px;
}
.margin-bottom-35{
  margin-bottom: 35px;
}
.margin-bottom-24{
  margin-bottom: 24px;
}
.margin-bottom-20{
  margin-bottom: 20px;
}
.margin-bottom-15{
  margin-bottom: 15px;
}
.margin-bottom-5{
  margin-bottom: 5px;
}

.text-decoration-none{
  text-decoration: none;
}

.border-bottom-none{
  border-bottom: none !important;
}

.border-bt-lt-radius-50{
  border-bottom-left-radius: 50px;
}
.border-bt-rt-radius-50{
  border-bottom-right-radius: 50px;
}


body {
  font-family: 'Open Sans', sans-serif;
  color: #5a5a5a;
}
.lang-box{
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 5px 0px;
}
.rus{
  display: block;
  padding: 5px;
  background-color: #80ba28;
  color: #fff;
  border-bottom-left-radius: 10px;
}
.eng{
  display: block;
  font-weight: 600;
  padding: 5px;
  background-color: #fff ;
  color:#80ba28;
  border-top-right-radius: 10px;
}
.top-img-box{
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 130px;
  height: 130px;
  float: left;
  margin: 5px 5px 0px 0px;
}
.top-img{
  border-radius: 3px;

  width: 110px;
  height: 110px;
}
.top-text{
  font-size: 12px;
  font-weight: 700;
  color: #000;
}

/*--- head start ---*/
.hmhd12 .hmhd12-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F9F2EC;
    padding-right: 48px;
}
.hmhd12 .hmhd12-1-wel{
  color: #9F5C00;
}
.ph-12d1 .ph-12d1-1{
    display: block;
    display: flex;
    min-width: 150px;
    padding: 8px 14px 7px 14px;
    background: #80ba28;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 13px;
    border-bottom-left-radius: 13px;
    margin-left: 40px;
    margin-right: 20px;
}
.add-fp1{
    margin-left: auto;
}
.add-fp1 .add-fp1-1{
    display:flex;
    margin-right: 20px;
}
.add-fp1 .add-fp1-1 .add-fp1-2{
    width: 14px;
    height: 20px;
}
.add-fp1 .add-fp1-1 .add-fp1-3{
    margin-left:5px;
}
.ph-12d1 .ph-12d1-1 .ph-12d1-3{
    margin-left:5px;
}
.ph-12d1 .ph-12d1-1 .ph-12d1-2{
    width: 14px;
    animation-name: trin;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}


@keyframes trin {

    from {
        transform:rotate3d(0, 0, 1, 0deg);
    }
    20%, 32%, 44%, 56%, 68% {
        transform: rotate3d(0, 0, 1, 0deg);
    }
    23%, 35%, 47%, 59%, 71% {
        transform: rotate3d(0,0,1,15deg);
    }
    26%, 38%, 50%, 62%, 74% {
        transform: rotate3d(0,0,1,0deg);
    }
    29%, 41%, 53%, 65%, 77% {
        transform: rotate3d(0,0,1,-15deg);
    }
    80% {
        transform:rotate3d(0, 0, 1, 0deg);
    }

}

.faqfr12{
    background: #f1fdf5;
}
.faqfr12 .faqfr12-1{
    overflow: hidden;
    border-end-start-radius: 100px;
}
.faqfr12 .faqfr12-1 img,
.faqfr12 .faqfr12-2 img{
    width:100%;
}
.faqfr12 .faqfr12-2 {
    overflow: hidden;
    border-start-end-radius: 100px;
}
.faqfr12 .faqfr13{
    text-align: center;
    margin: 30px 0 0 0;
    position: relative;
}
.faqfr12 .faqfr13 .faqfr13-1{
    color: #012207;
}
.faqfr12 .faqfr13 .faqfr13-2{
    margin: 12px 0 0 0;
    color: #64a77c;
}
.faqfr12 .faqfr14{
    margin: 50px 0 0 0;
}

.ayrvd-sec-12{
    background:#f5f1f0;
}
.ayrvd-sec-12 .ayrvd-sec-12-1 img{
    max-width:100%;
}
.spaf-sec-12{
    border-bottom: 1px #e5c8a7 dashed;
}
.spaf-sec-12 .spaf-sec-12-1 img{
    max-width:100%;
}
.spaf-sec-12 .spaf-sec-12-1{
    height: 100%;
    display: flex;
    align-items: center;
}



.faqfr14 .accordion-button:not(.collapsed) {
    font-weight: 600;
    color: #206e3a !important;
    background-color: #d4ebdc !important;
}
// .faqfr14 .accordion-button:not(.collapsed)::after {
//     background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e) !important;
//     transform: rotate(-180deg);
// }
.faqfr14 .accordion-body{
    font-size: 15px;
    line-height: 20px;
}
.faqfr14 .accordion-button:focus {
    z-index: 3 !important;
    border-color: initial !important;
    outline: 0 !important;
    box-shadow: initial !important;
}

.hmhd12 .hmhd12-2{
  display: flex;
}
.hmhd12 .hmhd12-15{
  padding-top: 27px;
  padding-bottom: 27px;
}
.hmhd12 .hmhd12-2 .hmhd12-3{
  display:flex;
  flex: 1;
  align-items: center;
}
.dropdown{
  position: static;
}
.header-link{
  display: flex;
  gap: 5px;
}
.header-phone{
  margin-right: 15px;
}

.header-dropdown{
  max-width:263px;
  position: absolute !important;
  top: 35px;
  right: 100px;
  z-index: 101;
}
.location{
  width: 18px;
  height: 25px;


}
.tube{
  width: 20px;
  height: 20px;
}
.btn-header{
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  font-size: 16px !important;
  color: #000;

  &-phone{
    color: #fff;
  }
  &-footer{
    color: #000;
  }
}
.flex{
  display: flex;
  align-items: center;
  justify-content: start;
}

.dropdown-menu li{
  padding: 5px !important;
  display: flex;
}
.footer-dropdown{
  margin-bottom: 10px;
  font-size: 16px;
}
@keyframes glowing {
  0% { background-color: #2ba805; box-shadow: 0 0 3px #2ba805; }
  50% { background-color: #49e819; box-shadow: 0 0 10px #49e819; }
  100% { background-color: #2ba805; box-shadow: 0 0 3px #2ba805; }
  }
  .drop{
    z-index: 9999999 !important;
  }
.dropwown-btn {
animation: glowing 1300ms infinite;
border-radius: 60px !important;
border: none !important;
font-family: sans-serif;
font-size: 16px;
padding: 7px 10px;
color: #fff;
}
.dropdown-header-item{
color: #828282 !important;
font-size: 14px;
}
.dropdown-item{
font-size: 12px;
line-height: 14px;
font-weight: 500;
margin-right: 15px;
}
.program{
background-color: #FFF8F0;
}


.hmhd12 .hmhd12-2 .hmhd12-3 .hmhd12-5{
    margin-left: 32px;
    color: #fff;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: "Josefin Sans", sans-serif;
    background: linear-gradient(to right, #ad562b 10%, #80ba28 50%, #a3f357 60%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 1.4s linear infinite;
    display: inline-block;
}
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.hmhd12 .hmhd12-2 .hmhd12-3 .hmhd12-5:hover{
  color: rgb(219 176 128);
}
.hmhd12 .hmhd12-2 .hmhd12-6{
  flex: 1;
}
.hmhd12 .hmhd12-2 .hmhd12-8{
  flex: 1;
  display: flex;
  justify-content: end;
  align-items: center;
}
.hmhd12 .hmhd12-2 .hmhd12-8 .hmhd12-9{
  display:flex;
  align-items: center;
}
.hmhd12 .hmhd12-2 .hmhd12-8 .hmhd12-9 .hmhd12-11{
  position: relative;
  margin: 0 25px 0 23px;
}
.hmhd12 .hmhd12-2 .hmhd12-8 .hmhd12-9 .hmhd12-11 .hmhd12-12{
  position: absolute;
  top: -6px;
  right: -10px;
  background: #C06700;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.hmhd12 .hmhd12-2 .hmhd12-8 .hmhd12-9 .hmhd12-13{
  margin: 0 42px 0 0;
}
.hmhd12 .hmhd12-2 .hmhd12-8 .hmhd12-9 .hmhd12-10{
  margin:1px 0 0 0;
}


/* menu start */
.hmhd12 .hmhd12-16 {
  border-top:1px #ECD5BA solid;
}

.hmhd12-16 nav {
  position: relative;
  z-index: 99;
}

.hmhd12-16 nav ul {
  display: flex;
  padding: 0px;
  margin: 0px;
  list-style: none;
  position: relative;
  justify-content: space-between;
  flex-wrap: wrap;
	}

.hmhd12-16 nav ul li {
	display:inline-block;
	}

.hmhd12-16 nav a {
  display: block;
  padding: 0px 10px;
  color: #C05F30;
  font-size: 14px;
  line-height: 60px;
  text-decoration: none;
}

.hmhd12-16 nav a:hover {
  background-color: rgb(252 245 238);
}

/* Hide Dropdowns by Default */
.hmhd12-16 nav ul ul {
	display: none;
	position: absolute;
	top: 60px; /* the height of the main nav */
}

/* Display Dropdowns on Hover */
.hmhd12-16 nav ul li:hover > ul {
	display:inherit;
}

/* Fisrt Tier Dropdown */
.hmhd12-16 nav ul ul li {
  width: 170px;
  float: none;
  display: list-item;
  position: relative;
  background: #c05f30;
}
.hmhd12-16 nav ul ul li a{
  line-height: 20px;
  color: #fff;
  padding: 10px 10px 10px;
  border-bottom: 1px #c3a887 solid;
}
.hmhd12-16 nav ul ul li a:hover{
  background: rgb(213 120 76);
}

/* Second, Third and more Tiers	*/
.hmhd12-16 nav ul ul ul li {
	position: relative;
	top:-60px;
	left:170px;
}


/* Change this in order to change the Dropdown symbol */
.hmhd12-16 li > a:after {
  content:  ' +';
  display: none;
}
.hmhd12-16 li > a:only-child:after {
  content: '';
  display: none;
}

/* menu end */


/*--- head end ---*/




.main-sec13 .ban-sec14{
  position: relative;
}
.ban-sec14 .hm-banner .owl-nav{
  position: absolute;
  top: 43%;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  margin: 0px 40px;
}
.ban-sec14 .hm-banner .owl-nav .owl-prev{
  width:40px;
  height: 40px;
  background: url(../img/ban-arrow-left.svg) no-repeat center center;
}
.ban-sec14 .hm-banner .owl-nav .owl-prev:hover{
  background: url(../img/ban-arrow-left.svg) no-repeat center center;
  opacity:0.6;
}
.ban-sec14 .hm-banner .owl-nav .owl-next{
  width:40px;
  height: 40px;
  background: url(../img/ban-arrow-right.svg) no-repeat center center;
}
.ban-sec14 .hm-banner .owl-nav .owl-next:hover{
  background: url(../img/ban-arrow-right.svg) no-repeat center center;
  opacity:0.6;
}
.ban-sec14 .hm-banner .owl-nav .owl-prev span,
.ban-sec14 .hm-banner .owl-nav .owl-next span{
  opacity: 0;
}


.main-sec13 .sli-sec15 .sli-sec15-1{
  display: flex;
  align-items: flex-end;
}
.main-sec13 .sli-sec15 .sli-sec15-1 .sli-sec15-3{
  color: #C06700;
  margin-left: auto;
  transition: all 0.2s ease-in-out;
}
.sli-sec17{
  color: #C06700;
  font-size: 12px;
  text-align: right;
}
.main-sec13 .sli-sec15 .sli-sec15-1 .sli-sec15-3:hover{
  color: rgb(219 176 128);
}
.sli-sec15 .hm-best .owl-stage{
  display:flex;
}
.sli-sec15 .hm-best .owl-stage .owl-item{
  margin-bottom: 15px;
}
.sli-sec15 .hm-best .owl-stage .item{
  height: 100%;
}
.sli-sec15 .hm-best .owl-stage .item .caro-sec22{
  height: 100%;
  box-shadow: 0px 0px 7px #00000029;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 4px 0 4px;
  transition: all 0.2s ease-in-out;
}
.caro-sec22:hover{
  background: #FCF8F3;
}
.caro-sec22 .caro-sec22-2{
  padding: 21px 24px;
  margin-bottom: auto;
}
.caro-sec22 .caro-sec22-2 .caro-sec22-6{
  color: rgb(72, 72, 72);
  line-height: 30px;
}




.ad-sec-19a .ad-sec-19-4 img{
  width: 100%;
}

.shopby-sec17 .shopby-sec17-1 .shopby-sec17-2{
  color: #B68E62;
}

.shopby-sec17 .shopby-sec17-4{
  display: block;
  border: 1px #EBEBEB solid;
  margin: 1px;
  padding: 35px 10px 50px;
  text-decoration: none;
  border-radius: 12px;
  transition: all 0.2s ease-in-out;
}
.shopby-sec17 .shopby-sec17-4 .shopby-sec17-5{
  width: 150px;
  height: 150px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center center !important;
  background-size: cover !important;
  background: #f1ede4;
  border-radius: 100%;
  overflow: hidden;
  margin: 0px auto;
}

.shopby-sec17 .shopby-sec17-4:hover{
  background: #FCF8F3;
}
.shopby-sec17 .shopby-sec17-4:hover .shopby-sec17-6{
  color: #9F3817;
}
.shopby-sec17 .shopby-sec17-4 .shopby-sec17-5 img{
  max-width: 100%;
  width: 100%;
}
.shopby-sec17 .shopby-sec17-4 .shopby-sec17-6{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 0 0;
}


.shopby-sec17 .shopby-sec17-1{
  display: flex;
  align-items: flex-end;
}
.shopby-sec17 .shopby-sec17-1 .treatment-sec26-3 {
  color: #C06700;
  margin-left: auto;
  transition: all 0.2s ease-in-out;
}
.shopby-sec17 .shopby-sec17-1 .treatment-sec26-3:hover {
  color: rgb(219 176 128);
}
.shopby-sec17 .hm-cate-treatment{
  position: relative;
}
.shopby-sec17 .hm-cate-treatment .owl-stage{
    display:flex;
}
.shopby-sec17 .hm-cate-treatment .owl-stage .owl-item{
    margin-bottom:3px;
}
.shopby-sec17 .hm-cate-treatment .owl-stage .owl-item .item,
.shopby-sec17 .hm-cate-treatment .owl-stage .owl-item .item .shopby-sec17-4{
    height:100%;
}
.shopby-sec17 .hm-cate-treatment .owl-nav{
  position: absolute;
  top: 43%;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  margin: 0px 15px;
}
.shopby-sec17 .hm-cate-treatment .owl-nav .owl-prev{
  position: absolute;
  left: -10px;
  top: 43%;
  width:40px;
  height: 40px;
  background: url(../img/tre-left-arrow.png) no-repeat center center;
}
.shopby-sec17 .hm-cate-treatment .owl-nav .owl-prev:hover{
  background: url(../img/tre-left-arrow.png) no-repeat center center;
  opacity:0.6;
}
.shopby-sec17 .hm-cate-treatment .owl-nav .owl-next{
  position: absolute;
  top: 43%;
  width:40px;
  height: 40px;
  right: -10px;
  background: url(../img/tre-right-arrow.png) no-repeat center center;
}
.shopby-sec17 .hm-cate-treatment .owl-nav .owl-next:hover{
  background: url(../img/tre-right-arrow.png) no-repeat center center;
  opacity:0.6;
}
.shopby-sec17 .hm-cate-treatment .owl-nav .owl-prev span,
.shopby-sec17 .hm-cate-treatment .owl-nav .owl-next span{
  opacity: 0;
}



.blog-sec-20 .blog-sec-20-5{
  display: flex;
  align-items: flex-end;
}
.blog-sec-20 .blog-sec-20-5 .blog-sec-20-7 {
  color: #C06700;
  margin-left: auto;
  transition: all 0.2s ease-in-out;
}
.blog-sec-20 .blog-sec-20-5 .blog-sec-20-7:hover{
  color: rgb(219 176 128);
}
.blog-sec-20 .blog-sec-20-1{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-column-gap: 36px;
  grid-row-gap: 43px;
  flex-wrap: wrap;
}
.blog-sec-20 .blog-sec-20-1 .blog-sec-20-2{
  box-shadow: 0px 0px 7px #00000029;
  border-radius: 10px;
  overflow: hidden;
}
.blog-sec-20 .blog-sec-20-1 .blog-sec-20-2 .blog-sec-20-3 img{
  max-width: 100%;
  width: 100%;
}
.blog-sec-20 .blog-sec-20-1 .blog-sec-20-2 .blog-sec-20-4 h3{
  line-height: 35px;;
}
.blog-sec-20 .blog-sec-20-1 .blog-sec-20-2 .blog-sec-20-4 p{
  color: rgb(112, 112, 112);
  margin: 30px 0 30px;
}
.blog-sec-20 .blog-sec-20-1 .blog-sec-20-2 .blog-sec-20-4 a:hover{
  color: #C05F30;
  text-decoration: underline !important;
}
.blog-sec-20 .blog-sec-20-1 .blog-sec-20-2 .blog-sec-20-4{
  padding: 21px 24px 39px;
}


.foot-sec21{
  background: #F8F8F8;
  padding-bottom: 70px;
}
.foot-sec21 .foot-sec21-1 h3,
.foot-sec21 .foot-sec21-5 h3,
.foot-sec21 .foot-sec21-15 h3{
  margin: 0 0 20px 0;
}
.foot-sec21 .foot-sec21-8 h3{
  margin: 0 0 15px 0;
}

.foot-sec21 .foot-sec21-15 .foot-sec21-16 iframe{
  max-width: 250px;
  width: 100%;
}

.foot-sec21 .foot-sec21-1 .foot-sec21-2{
  display:flex;
  flex-wrap: wrap;
}
.foot-sec21 .foot-sec21-1 .foot-sec21-2 .foot-sec21-3{
  margin: 0 60px 0 0;
}
.foot-sec21 .foot-sec21-1 .foot-sec21-2 .foot-sec21-3 li a,
.foot-sec21 .foot-sec21-1 .foot-sec21-2 .foot-sec21-4 li a,
.foot-sec21 .foot-sec21-5 .foot-sec21-6 .foot-sec21-7 li a{
  text-decoration: none;
  color: #5C5C5C;
  transition:all 0.2s ease-in-out;
}
.foot-sec21 .foot-sec21-1 .foot-sec21-2 .foot-sec21-3 li a:hover,
.foot-sec21 .foot-sec21-1 .foot-sec21-2 .foot-sec21-4 li a:hover,
.foot-sec21 .foot-sec21-5 .foot-sec21-6 .foot-sec21-7 li a:hover{
  color: #212121;
}
.foot-sec21 .foot-sec21-1 .foot-sec21-2 .foot-sec21-3 li,
.foot-sec21 .foot-sec21-1 .foot-sec21-2 .foot-sec21-4 li,
.foot-sec21 .foot-sec21-5 .foot-sec21-6 .foot-sec21-7 li{
  margin: 0 0 17px 0;
}

.foot-sec21 .foot-sec21-8 .foot-sec21-9 .foot-sec21-11{
  color: #5C5C5C;
  line-height: 30px;
}
.foot-sec21 .foot-sec21-8 .foot-sec21-9 .foot-sec21-10 li a{
  display: flex;
  align-items: center;
}
.foot-sec21 .foot-sec21-8 .foot-sec21-9 .foot-sec21-10 li a{
  color: #5C5C5C;
}
.foot-sec21 .foot-sec21-8 .foot-sec21-9 .foot-sec21-10 li a .foot-sec21-14{
  display: flex;
  align-items: center;
  margin: 0 5px 0 0;
}
.foot-sec21 .foot-sec21-8 .foot-sec21-9 .foot-sec21-11{
  margin-bottom: 16px;
}
.foot-sec21 .foot-sec21-8 .foot-sec21-9 .foot-sec21-10 li{
  margin-bottom: 12px;
}
.foot-sec21 .foot-sec21-15{
  float:right;
}
.foot-sec21 .foot-sec21-17{
  text-align: center;
  margin: 60px 0 40px;
}
.foot-sec21 .foot-sec21-17 h6{
  margin: 0 0 20px 0;
}
.foot-sec21 .foot-sec21-17 .foot-sec21-18{
  display: flex;
  justify-content: center;
}
.foot-sec21 .foot-sec21-17 .foot-sec21-18 li{
  margin: 0 8px;
}

.foot-sec21 .foot-sec21-19{
  color: #7C7C7C;
  margin-bottom: 25px;
}
.foot-sec21 .foot-sec21-20, .foot-sec21 .foot-sec21-20 a{
  color: #7C7C7C;
}



.ayurtre-sec23{
  background: #FFF8F0;
  padding-top: 60px;
  padding-bottom: 60px;
}
.ayurtre-sec23 .ayurtre-sec23-1 img{
  max-width: 100%;
}
.ayurtre-sec23 .ayurtre-sec23-2{
  padding-left: 100px;
}
.ayurtre-sec23 .ayurtre-sec23-2 .ayurtre-sec23-3{
  color: #B86F1E;
}
.ayurtre-sec23 .ayurtre-sec23-2 .ayurtre-sec23-4{
  width: 80%;
  color: #BD8D55;
}




.unikrish-k12{
  background: #f7e5d1;
  padding-top: 60px;
  padding-bottom: 60px;
}
.unikrish-k12 .unikrish-k12-2 img{
  max-width: 100%;
}
.unikrish-k12 .unikrish-k12-1{
  padding-left: 100px;
}
.unikrish-k12 .unikrish-k12-1 .ayurtre-sec23-3{
  color: #B86F1E;
}
.unikrish-k12 .unikrish-k12-1 .unikrish-k12-1-2{
  width: 80%;
  color: #a9753a;
}




.world-sec124 .world-sec124-1 .world-sec124-3{
  color: #7D7D7D;
}
.world-sec124 .world-sec124-4{
  display: flex;
  justify-content: center;
}
.world-sec124 .world-sec124-4 img{
  max-width: 100%;
}




@media screen and (max-width: 1199px) {

  .hmhd12-16 nav ul {
    justify-content: flex-start;
  }
  .ayurtre-sec23 .ayurtre-sec23-2 {
    padding-left: 30px;
  }

}

@media screen and (max-width: 1024px) {
  .shopby-sec17 .shopby-sec17-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .shopby-sec17 .shopby-sec17-3 .shopby-sec17-4 .shopby-sec17-6 {
    font-size: 20px;
  }

}

@media screen and (max-width: 991px) {
  .btn-footer{
    font-size: 14px !important;
  }

  .ayurtre-sec23 .ayurtre-sec23-2 .ayurtre-sec23-4 {
    width: 100%;
  }

  .blog-sec-20 .blog-sec-20-1 {
    grid-template-columns: repeat(2,1fr);
  }
  .shopby-sec17 .shopby-sec17-3 {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

}


@media screen and (max-width: 768px) {

  .unikrish-k12 .unikrish-k12-1 {
    padding-left: 0;
  }

}


@media screen and (max-width: 767px) {
  .hmhd12 .hmhd12-2 .hmhd12-3{
    display:none;
  }
  .lang-box{
    gap: 5px;
    margin: 5px 0px;
  }

.header-dropdown {
  position: absolute;
  top: 25px;
  right: 60px;
  z-index: 101;
}




  .hmhd12 .hmhd12-2 {
    flex: 0;
  }
  .hmhd12 .hmhd12-2 .hmhd12-6 .hmhd12-7 img{
    width: 170px;
  }
  .ayurtre-sec23 .ayurtre-sec23-1 img {
    max-width: 50%;
  }
  .ayurtre-sec23 .ayurtre-sec23-1{
    margin-bottom: 25px;
  }
  .ayurtre-sec23 .ayurtre-sec23-2 {
    padding-left: 0;
  }
  .ayurtre-sec23 .ayurtre-sec23-2 .ayurtre-sec23-3,
  .unikrish-k12 .unikrish-k12-1 .ayurtre-sec23-3{
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 5px;
  }
  .ayurtre-sec23{
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 25px;
    padding-top: 25px;
  }
  .unikrish-k12 .unikrish-k12-2 img {
    max-width: 50%;
  }
  .ayurtre-sec23-2 .ayurtre-posi-4,
  .unikrish-k12 .unikrish-k12-1 .ayurtre-posi-4{
      margin-bottom:15px;
  }
  .unikrish-k12 {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 25px;
    padding-top: 25px
  }
  .ayrvd-sec-12{
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 25px;
    margin-bottom:25px;
  }
  .world-sec124{
    padding-bottom: 25px;
  }
  .world-sec124 .world-sec124-1 .world-sec124-2{
    font-size: 30px;
    margin-bottom: 25px;
  }
  .world-sec124 .world-sec124-1 .world-sec124-3 {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0px;
  }

  .ayurtre-sec23 .ayurtre-sec23-2 .ayurtre-sec23-4,
  .unikrish-k12 .unikrish-k12-1 .unikrish-k12-1-2{
      width:auto;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 25px;
  }

  .blog-sec-20 .blog-sec-20-5 .blog-sec-20-7,
  .sli-sec15 .sli-sec15-3,
  .shopby-sec17 .shopby-sec17-1 .treatment-sec26-3{
    font-size: 14px;
  }

  .foot-sec21 .foot-sec21-17 {
    text-align: left;
    margin: 36px 0 30px;
  }
  .foot-sec21 .foot-sec21-17 .foot-sec21-18 {
    justify-content: flex-start;
  }
  .foot-sec21 .foot-sec21-19, .foot-sec21 .foot-sec21-20 {
    text-align: left !important;
  }
  .foot-sec21 .foot-sec21-1{
    margin-bottom:25px;
  }
  .foot-sec21 .foot-sec21-15 {
    float: left;
  }
  .foot-sec21 .foot-sec21-19 {
    margin-bottom: 20px;
  }
  .blog-sec-20{
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 25px;
  }
  .foot-sec21 {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
    padding-top: 50px;
  }

  .blog-sec-20 .blog-sec-20-1 {
    grid-template-columns: repeat(1,1fr);
    grid-row-gap: 25px;
  }

  .shopby-sec17{
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 25px;
    padding-top: 25px;
  }
  .faqfr12 .faqfr13 .faqfr13-1 {
    font-size: 30px;
  }
  .faqfr12 .faqfr13 .faqfr13-2{
    font-size:18px;
    margin: 5px 0 0 0;
  }
  .sli-sec15{
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 25px;
    padding-top: 25px;
  }
  .faqfr12{
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 25px;
    padding-top: 25px;
    margin-bottom: 25px;
  }
  .ad-sec-19a{
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 25px;
    margin-bottom: 0;
  }
  .sli-sec15 .sli-sec15-2,
  .shopby-sec17 .shopby-sec17-2,
  .world-sec124 .world-sec124-1 .world-sec124-2,
  .blog-sec-20 .blog-sec-20-5 .blog-sec-20-6{
    font-size: 26px;
  }

  .hmhd12-16,
  .hmhd12 .hmhd12-2 .hmhd12-8 .hmhd12-9 .hmhd12-10{
    display:none;
  }
  .hmhd12 .hmhd12-15{
    padding-left: 5px;
    padding-right: 15px;
  }
  .foot-sec21 .foot-sec21-17 .foot-sec21-18 li {
    margin: 0 16px 0 0;
  }
  .ban-sec14 .hm-banner .owl-nav {
    display:none;
  }
  .shopby-sec17 .shopby-sec17-1,
  .main-sec13 .sli-sec15 .sli-sec15-1,
  .world-sec124 .world-sec124-1 .world-sec124-3,
  .blog-sec-20 .blog-sec-20-5{
    padding-bottom: 25px;
  }

  .shopby-sec17 .shopby-sec17-4 .shopby-sec17-5 {
    width: 70px;
    height: 70px;
  }
  .caro-sec22 .caro-sec22-2 .caro-sec22-6 {
    font-size: 18px;
    line-height: 20px;
  }
  .caro-sec22 .caro-sec22-2,
  .blog-sec-20 .blog-sec-20-1 .blog-sec-20-2 .blog-sec-20-4{
    padding: 15px 7px 15px;
  }
  .blog-sec-20 .blog-sec-20-1 .blog-sec-20-2 .blog-sec-20-4 h3 {
    line-height: 30px;
    font-size: 22px;
  }
  .blog-sec-20 .blog-sec-20-1 .blog-sec-20-2 .blog-sec-20-4 p {
    font-size: 14px;
    margin: 10px 0 10px;
  }
  .blog-sec-20 .blog-sec-20-1 .blog-sec-20-2 .blog-sec-20-4 a{
    font-size: 14px;
  }
  .shopby-sec17 .shopby-sec17-3 .shopby-sec17-4 .shopby-sec17-6 {
    font-size: 18px;
  }
  .ayrvd-sec-12 .ayrvd-sec-12-2{
    font-size: 30px;
    margin-bottom: 15px;
    margin-top: 25px;
  }
  .spaf-sec-12 .spaf-sec-12-2{
    font-size: 30px;
    margin-bottom: 15px;
    margin-top: 25px;
  }
  .spaf-sec-12 .spaf-sec-12-3{
    font-size: 14px;
    line-height: 24px;
  }
  .spaf-sec-12{
    padding-top: 25px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .hmhd12 .hmhd12-1 {
    padding-right: 10px;
    padding-left: 10px;
    display: grid;
    gap: 5px 0;
    justify-content: center;
  }
  .add-fp1 {
    margin-left: inherit;
  }
  .ph-12d1 .ph-12d1-1 {
    justify-content: center;
    margin-left: 10px;
    padding: 6px 14px 5px 14px !important;
  }
  .add-fp1 .add-fp1-1 {
    margin-left: 10px;
    margin-right: auto;
    justify-content: center;
  }
  .hmhd12 .hmhd12-1-wel {
    margin-left: 0;
    margin-bottom: 8px;
  }
  .add-fp1 .add-fp1-1 .add-fp1-3 {
    font-size: 12px;
  }
  .hmhd12 .hmhd12-1-wel{
    font-size: 11px;
    text-align: center;
  }
  .hmhd12 .hmhd12-1-wel span{
    font-size: 12px;
  }
  // .ph-12d1 .ph-12d1-1 .ph-12d1-2 {
  //   width: 12px;
  // }
  .ph-12d1 .ph-12d1-1 .ph-12d1-3 {
    font-size: 10px !important;
  }


}



@media screen and (max-width: 600px) {

  .sli-sec15 .sli-sec15-2,
  .shopby-sec17 .shopby-sec17-2,
  .world-sec124 .world-sec124-1 .world-sec124-2,
  .blog-sec-20 .blog-sec-20-5 .blog-sec-20-6,
  .faqfr12 .faqfr13 .faqfr13-1,
  .ayrvd-sec-12 .ayrvd-sec-12-2,
  .spaf-sec-12 .spaf-sec-12-2{
    font-size: 22px;
  }
    .foot-sec21 .foot-sec21-1 h3,
    .foot-sec21 .foot-sec21-5 h3, .foot-sec21 .foot-sec21-15 h3,
    .foot-sec21 .foot-sec21-8 h3,
    .foot-sec21 .foot-sec21-17 h6{
        font-size: 22px;
    }
  .blog-sec-20 .blog-sec-20-5 .blog-sec-20-7,
  .sli-sec15 .sli-sec15-3,
  .shopby-sec17 .shopby-sec17-1 .treatment-sec26-3{
    font-size: 12px;
  }
  .ayurtre-sec23 .ayurtre-sec23-2 .ayurtre-sec23-3,
  .unikrish-k12 .unikrish-k12-1 .ayurtre-sec23-3{
    font-size: 24px;
    line-height: 30px;
  }
  .faqfr12 .faqfr12-1{
        border-end-start-radius: 60px;
  }
  .faqfr12 .faqfr12-2{
        border-start-end-radius:60px;
  }
  .hmhd12 .hmhd12-2 .hmhd12-6 .hmhd12-7 img {
     width: 150px;
  }
  .faqfr12 .faqfr14 {
     margin: 30px 0 0 0;
  }

.owl-prev{
  opacity: 0!important;
}
.owl-next{
 opacity: 0!important;
}
.lang-box{
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 5px 0px;
}
.rus{
  font-size: 12px;
  padding: 4px;

}
.eng{
 font-size: 12px;
  padding: 4px;
}
}
