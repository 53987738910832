.header{
  position: absolute;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  color: #fff;

}

