.promotion{
  padding: 10px 0px 50px 0px;

  &__subitem {
    list-style-type: none;
    display: block;
    color: #222222;
    text-decoration: none;
    padding: 13px 12px 13px;
    border-bottom:1px solid #e1ceb8;
    cursor: pointer;
  }

  &__top{
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding: 40px;
    max-width: 1400px;
  }

  &__top-right{
    max-width:  556px;
    height: 437px;
  }
  &__img-mobile{
    display: none;
  }

  &__title{
    margin: 0;
    font-family: EB Garamond;
    font-size: 52px;
    font-weight: 600;
    line-height: 67.86px;
    text-align: center;
    color: #414141;
  }

  &__date{
    margin: 0;
    font-family: EB Garamond;
    font-size: 24px;
    font-weight: 600;
    line-height: 31.32px;
    text-align: center;
    color: #414141;

    a{
      color: #AF8150;
      text-decoration: underline;
    }
  }
  &__card{
    padding: 30px;
    background: #FFF8F0;
    border-radius: 10px;
    box-shadow: 10px 10px 5px 0px rgba(90,90,90,0.27);
    -webkit-box-shadow: 10px 10px 5px 0px rgba(90,90,90,0.27);
    -moz-box-shadow: 10px 10px 5px 0px rgba(90,90,90,0.27);
    margin: 0 auto;
    max-width: 1150px;
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 30px;
  }
  &__card-left{
    position: relative;
    min-height: 322px;
  }

  &__link-hidden{
    display: none;
  }
  &__card-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  &__card-decor{
    position: absolute;
    right: 10px;
    bottom: 0;
  }

  &__name{
    margin: 0;
    color: #AF8150;
    font-size: 26px;
    font-weight: 600;
    line-height: 33.93px;
    text-align: left;
  }
  &__name-hidden{
    display: none;
    margin: 0;
    color: #AF8150;
    font-size: 26px;
    font-weight: 600;
    line-height: 33.93px;
    text-align: center;
    margin-bottom: 20px;
  }

  &__during{
    margin: 0;
    color: #AF8150;
    font-size: 28px;
    font-weight: 600;
    line-height: 38.13px;
    text-align: left;
  }
  &__price-box{
    position: relative;
    min-width: 138px;
  }
  &__price{
    margin: 0;
    color: #AF8150;
    font-size: 28px;
    font-weight: 600;
    line-height: 38.13px;
    text-align: left;
  }
  &__price-old{
    position: absolute;
    top: 30px;
    left: 0;
    color: #414141;
    font-size: 10px;
    font-weight: 600;
    line-height: 13.62px;
    text-align: left;
  }
  &__item{
    color: #414141;
    font-size: 18px;
    font-weight: 600;
    line-height: 24.51px;
    text-align: left;
      &:not(:last-child){
          margin-bottom: 10px;
      }

  }
  &__card-img{
    border-top-left-radius: 170px;
    border-top-right-radius: 170px;
    width: 360px;
    height: 271px;
    margin-bottom: 10px;
   }
   &__link{
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 360px;
    height: 36px;
    border-radius: 10px;
    background: #85B998;
    color: #fff;
   }
   &__list-bottom{
      margin: 0 auto;
      max-width: 1140px;
      display: flex;
     justify-content: center;
      flex-wrap: wrap;
      gap: 30px;
   }
   &__card-bottom{
      position: relative;
      padding: 60px 40px 20px 40px;
      border-top-right-radius: 170px;
      border-top-left-radius: 170px;
      width: 360px;
      height: 531px;
      background: #FFF8F0;
      margin-bottom: 10px;
   }
   &__item-decor{
      position: absolute;
      bottom: 0;
      right: -20px;
   }
   &__item-plant{
      position: absolute;
      top: 100px;
      right: 0;
   }
   &__subtitle{
      padding-top: 20px;
      max-width: 668px;
      color: #414141;
      margin: 0 auto;
      font-size: 24px;
      font-weight: 600;
      line-height: 31.32px;
      text-align: center;
      margin-bottom: 30px;
    }
    &__item-title{
      margin: 0;
      color: #AF8150;
      font-size: 18px;
      font-weight: 600;
      line-height: 23.49px;
      text-align: center;
    }
    &__item-subtitle {
      margin: 0;
      font-size: 12px;
      font-weight: 600;
      line-height: 18.27px;
      text-align: center;
      color: #AF8150;
      margin-bottom: 30px;
    }
    &__item-sub{
      margin: 0;
      color: #414141;
      font-size: 16px;
      font-weight: 600;
      line-height: 21.79px;
      text-align: center;
      padding-top: 40px;
      margin-bottom: 20px;
    }
    &__item-text{
      margin: 0;
      color: #414141;
      font-size: 16px;
      font-weight: 600;
      line-height: 21.79px;
      text-align: center;
      margin-bottom: 10px;

      span{
        color: #AF8150;
      }
    }
    &__old-price{
      text-decoration: line-through;
      font-size: 16px;
      font-weight: 600;
      line-height: 21.79px;
    }
    &__item-percent{
      margin: 0;
      color: #AF8150;
      font-size: 28px;
      font-weight: 600;
      line-height: 38.13px;
      text-align: center;
      margin-bottom: 10px;
    }
    &__sertificate-box{
      padding-top: 50px;
      margin: 0 auto;
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
      gap: 50px;
    }
    &__sertificate-left{
      position: relative;
      min-height: 300px;
    }
    &__sertificate-right{
      position: relative;
      min-height: 300px;
      width: 660px;
    }
    &__sertificate-decor-2{
      position: absolute;
      width: 427px;
      height: 340px;
      top: -60px;
      right: -120px;
    }
    &__sertificate-decor{
      position: absolute;
      left: -70px;
      bottom: -50px;
    }
    &__sertificate-img{
      position: absolute;
      top: 0;
      left: 0;
      width: 660px;
      height: 249px;
      border-radius: 10px;
      margin-bottom: 10px;
      z-index: 2;
    }
    &__sertificate-mobile{
      display: none;
      width: 255px;
      height: 296px;
      border-radius: 10px;
    }
    &__sertificate-text{
      position: absolute;
      left: 0;
      bottom: 0;
    }
    &__descr{
      margin: 0;
      max-width: 465px;
      color: #414141;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
      margin-bottom: 10px;

      &-sertificate{
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
      &-bold{
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
    &__sertificate-text{
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #414141;
    }
    &__link-sertificate{
      position: absolute;
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background: #85B998;
      color: #fff;
      width: 260px;
      height: 36px;
      left: 0px;
      bottom: 10px;
      z-index: 2;
    }
}
@media (max-width: (992px)){
  .promotion{
    padding-top: -10px;
  }
  .promotion__top{
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 0;
  }
  .promotion__card{
    flex-direction: column;
    align-items: center;
  }
  .promotion__sertificate-box{
    padding-top: 10px;
  }
  .promotion__sertificate-left{
    margin-bottom: 40px;
  }
  .promotion__bottom{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .promotion__sertificate-text{
    left: 0;
    bottom: -10px;
  }
}
@media (max-width: (768px)){
  .promotion{
    padding-top: 10px;
  }

  .promotion__card-left{
    max-width:560px ;
  }
   .promotion__during{
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
   }
   .promotion__price{
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
   }
   .promotion__list{
    margin-bottom: 10px;
   }
   .promotion__sertificate-img{
    display: none;
   }
   .promotion__sertificate-mobile{
    display: block;
    margin: 0 auto;
    margin-top: -30px;
   }
  //  .promotion__link-sertificate{
  //   display: none;
  //  }
   .promotion__sertificate-decor{
    display: none;
   }

}
@media (max-width: (576px)){
  .promotion{
    padding-top: -30px;
  }
  .promotion__card{
    box-shadow: none;
    padding: 10px;
  }
  .promotion__title{
    font-size: 18px;
    line-height: 25px;
  }
  .promotion__descr{
    font-size: 14px;
    font-weight: 600;
    text-align: left;

    &-bold{
      font-size: 12px;
      margin-bottom: 7px;
    }
  }
  .promotion__text{
    font-size: 14px;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
  }
  .promotion__name{
    font-size: 18px;
    line-height: 20px;
  }
  .promotion__date{
    font-size: 18px;
    line-height: 22px;
  }
  .promotion__during{
    line-height: 20px;
    font-size: 16px;
    color: #000;
  }
  .promotion__price{
    font-size: 16px;
  }
.promotion__card-top{
  display: block;
}
.promotion__name{
  padding-right: 20px;
}

  .promotion__link-bottom{
    margin: 0 auto;
    display: flex !important;
    width: 300px !important;
  }
.promotion__link-top{
  width: 360px !important;
}
  .promotion__link{
    width: 300px;
  }

  .promotion__during{
   margin: 0px 10px 0px 0px;
   }
   .promotion__card-decor{
    display: none;
   }
   .promotion__link-hidden{
    display: flex;
   }
   .promotion__sertificate-box{
    gap: 0;
   }
   .promotion__sertificate-left{
    max-width: 300px;
   }
   .promotion__sertificate-decor-2{
      position: absolute;
      top: -350px;
      right: 100px;
      width: 216px;
      height: 172px;
   }
   .promotion__sertificate-text{
      margin: 0 auto;
      position: static;
      max-width: 300px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 20px;
   }
   .promotion__card-bottom{
      position: relative;
      padding: 60px 10px 10px 10px;
      border-top-right-radius: 170px;
      border-top-left-radius: 170px;
      width: 300px;
      height: 531px;
      background: #FFF8F0;
      margin-bottom: 10px;
   }
   .promotion__title-bottom {
    font-size: 34px;
    margin: 20px 0px 0px 0px;
   }
   .promotion__subtitle{
    font-size: 16px;
    font-weight: 600;
    line-height: 20.88px;
    text-align: center;
   }
   .promotion__item-decor{
    position: absolute;
    bottom: -10px;
    right: -30px;
 }
}
@media (max-width: (431px)){
  .promotion__top{
    max-height: 470px;
  }
}
@media (max-width: (400px)){
  .promotion__top{
    max-height: 440px;
  }
  .promotion__card-img{
    max-width: 320px;
  }
  .promotion__link-top{
    width: 320px !important;
  }
}

